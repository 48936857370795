import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  cursor: pointer;
  transform: ${p => (p.collapse ? `rotate(360deg)` : 'rotate(-180deg)')};
  position: relative;
  top: 4px;

  .st0 {
    fill: #ffffff;

    &:hover + .st2 {
      fill: var(--secondary-color);
    }
  }
  .st1 {
    fill: #ffffff;
    transition: all 0.2s ease-in-out;

    &:hover {
      fill: var(--secondary-color);

      + .st2 {
        fill: #ffffff;
      }
    }
  }
  .st2 {
    fill: var(--primary-color);
    padding: 3px;
    transition: all 0.2s ease-in-out;

    &:hover {
      fill: #ffffff;

      + .st1 {
        fill: var(--secondary-color);
      }
    }
  }

  @media (max-width: 575px) {
    width: 22px;
    height: 22px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 20px;
    height: 20px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 20px;
    height: 20px;
  }
`

export default function CollapseArrow({
  width = '30px',
  height = '30px',
  collapse,
}) {
  return (
    <SVG
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 41.6 41.6"
      xmlSpace="preserve"
      enableBackground="new 0 0 41.6 41.6"
      width={width}
      height={height}
      collapse={collapse}
    >
      <g id="arrow_1_">
        <path
          className="st0"
          d="M20.8,0C9.2,0,0,9.2,0,20.8s9.2,20.8,20.8,20.8c11.2,0,20.8-9.2,20.8-20.8S32,0,20.8,0z"
        />
        <path
          className="st1"
          d="M20.8,41.6C9.2,41.6,0,32.4,0,20.8S9.2,0,20.8,0C32,0,41.6,9.2,41.6,20.8S32,41.6,20.8,41.6z"
        />
        <path
          className="st2"
          d="M34.4,24.8l1.2-1.6L22,14c-0.4-0.4-1.2-0.4-1.6,0L6.8,23.2L8,24.8l12.8-8.4c0.4-0.4,0.8-0.4,1.2,0L34.4,24.8z"
        />
      </g>
    </SVG>
  )
}
