export const FLOOR_5_DATA = [
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A15_bal',
    sceneName: 'scene_valo_krs05_A15_bal',
    sceneName_future: 'scene_valo_krs05_A15_bal_future',
    name: 'A15_bal',
    label: 'A15',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_bal',
      4: 'A11_bal',
      6: 'A22_bal',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A15_int',
    sceneName: 'scene_valo_krs05_A15_int',
    sceneName_future: 'scene_valo_krs05_A15_int_future',
    name: 'A15_int',
    label: 'A15',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      6: 'A22_int',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A16_bal',
    sceneName: 'scene_valo_krs05_A16_bal',
    sceneName_future: 'scene_valo_krs05_A16_bal_future',
    name: 'A16_bal',
    label: 'A16',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_bal',
      4: 'A12_bal',
      6: 'A23_bal',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A16_int',
    sceneName: 'scene_valo_krs05_A16_int',
    sceneName_future: 'scene_valo_krs05_A16_int_future',
    name: 'A16_int',
    label: 'A16',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_int',
      4: 'A12_int',
      6: 'A23_int',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A17_bal',
    sceneName: 'scene_valo_krs05_A17_bal',
    sceneName_future: 'scene_valo_krs05_A17_bal_future',
    name: 'A17_bal',
    label: 'A17',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_bal',
      4: 'A13_bal',
      6: 'A24_bal',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A17_int',
    sceneName: 'scene_valo_krs05_A17_int',
    sceneName_future: 'scene_valo_krs05_A17_int_future',
    name: 'A17_int',
    label: 'A17',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      4: 'A13_int',
      6: 'A24_int',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A18_bal',
    sceneName: 'scene_valo_krs05_A18_bal',
    sceneName_future: 'scene_valo_krs05_A18_bal_future',
    name: 'A18_bal',
    label: 'A18',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_bal',
      4: 'A14_bal',
      6: 'A25_bal',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A18_int',
    sceneName: 'scene_valo_krs05_A18_int',
    sceneName_future: 'scene_valo_krs05_A18_int_future',
    name: 'A18_int',
    label: 'A18',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_int',
      4: 'A14_int',
      6: 'A25_int',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A19_bal',
    sceneName: 'scene_valo_krs05_A19_bal',
    sceneName_future: 'scene_valo_krs05_A19_bal_future',
    name: 'A19_bal',
    label: 'A19',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_bal',
      4: 'A08_bal',
      6: 'A19_bal',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A19_int',
    sceneName: 'scene_valo_krs05_A19_int',
    sceneName_future: 'scene_valo_krs05_A19_int_future',
    name: 'A19_int',
    label: 'A19',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_int',
      4: 'A08_int',
      6: 'A19_int',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A20_bal',
    sceneName: 'scene_valo_krs05_A20_bal',
    sceneName_future: 'scene_valo_krs05_A20_bal_future',
    name: 'A20_bal',
    label: 'A20',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A09_bal',
      4: 'A09_bal',
      6: 'A20_bal',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A20_int',
    sceneName: 'scene_valo_krs05_A20_int',
    sceneName_future: 'scene_valo_krs05_A20_int_future',
    name: 'A20_int',
    label: 'A20',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A09_int',
      4: 'A09_int',
      6: 'A20_int',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A21_bal',
    sceneName: 'scene_valo_krs05_A21_bal',
    sceneName_future: 'scene_valo_krs05_A21_bal_future',
    name: 'A21_bal',
    label: 'A21',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A10_bal',
      4: 'A10_bal',
      6: 'A21_bal',
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A21_int',
    sceneName: 'scene_valo_krs05_A21_int',
    sceneName_future: 'scene_valo_krs05_A21_int_future',
    name: 'A21_int',
    label: 'A21',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A10_int',
      4: 'A10_int',
      6: 'A21_int',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B62_bal',
    sceneName: 'scene_valo_krs05_B62_bal',
    sceneName_future: 'scene_valo_krs05_B62_bal_future',
    name: 'B62_bal',
    label: 'B62',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_bal',
      4: 'B53_bal',
      6: 'B62_bal',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B62_int',
    sceneName: 'scene_valo_krs05_B62_int',
    sceneName_future: 'scene_valo_krs05_B62_int_future',
    name: 'B62_int',
    label: 'B62',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_int',
      4: 'B53_int',
      6: 'B62_int',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B63_bal',
    sceneName: 'scene_valo_krs05_B63_bal',
    sceneName_future: 'scene_valo_krs05_B63_bal_future',
    name: 'B63_bal',
    label: 'B63',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B54_bal',
      4: 'B54_bal',
      6: 'B63_bal',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B63_int',
    sceneName: 'scene_valo_krs05_B63_int',
    sceneName_future: 'scene_valo_krs05_B63_int_future',
    name: 'B63_int',
    label: 'B63',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B54_int',
      4: 'B54_int',
      6: 'B63_int',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B64_bal',
    sceneName: 'scene_valo_krs05_B64_bal',
    sceneName_future: 'scene_valo_krs05_B64_bal_future',
    name: 'B64_bal',
    label: 'B64',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B55_bal',
      4: 'B58_bal',
      6: 'B68_bal',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B64_int',
    sceneName: 'scene_valo_krs05_B64_int',
    sceneName_future: 'scene_valo_krs05_B64_int_future',
    name: 'B64_int',
    label: 'B64',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B55_int',
      4: 'B58_int',
      6: 'B68_int',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B65_bal',
    sceneName: 'scene_valo_krs05_B65_bal',
    sceneName_future: 'scene_valo_krs05_B65_bal_future',
    name: 'B65_bal',
    label: 'B65',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      3: 'B56_bal',
      4: 'B59_bal',
      6: 'B69_bal',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B65_int',
    sceneName: 'scene_valo_krs05_B65_int',
    sceneName_future: 'scene_valo_krs05_B65_int_future',
    name: 'B65_int',
    label: 'B65',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      3: 'B56_int',
      4: 'B59_int',
      6: 'B69_int',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B66_bal',
    sceneName: 'scene_valo_krs05_B66_bal',
    sceneName_future: 'scene_valo_krs05_B66_bal_future',
    name: 'B66_bal',
    label: 'B66',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_int',
      3: 'B57_bal',
      4: 'B60_bal',
      6: 'B70_bal',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B66_int',
    sceneName: 'scene_valo_krs05_B66_int',
    sceneName_future: 'scene_valo_krs05_B66_int_future',
    name: 'B66_int',
    label: 'B66',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_int',
      3: 'B57_int',
      4: 'B60_int',
      6: 'B70_int',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B67_bal',
    sceneName: 'scene_valo_krs05_B67_bal',
    sceneName_future: 'scene_valo_krs05_B67_bal_future',
    name: 'B67_bal',
    label: 'B67',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B52_int',
      3: 'B52_bal',
      4: 'B61_bal',
      6: 'B71_bal',
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 'B67_int',
    sceneName: 'scene_valo_krs05_B67_int',
    sceneName_future: 'scene_valo_krs05_B67_int_future',
    name: 'B67_int',
    label: 'B67',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B52_int',
      3: 'B57_int',
      4: 'B61_int',
      6: 'B71_int',
    },
  },
]
