import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor12({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  apartments,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="rakennus_8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 540 238.96"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="259.11"
          y1="-448.32"
          x2="259.11"
          y2="-491.73"
          gradientTransform="translate(-164.9 -377.96) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".01" stopColor="#ebebeb" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="235.52"
          y1="-407.45"
          x2="235.52"
          y2="-450.87"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="256.69"
          y1="-502.11"
          x2="256.69"
          y2="-545.52"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <rect id="bg-2" className="cls-2" width="539.67" height="238.96" />
        <path
          id="UI_frame"
          className="cls-8"
          d="m0,0v238.96h539.99V0H0Zm464.11,133.75v-55.72h35.38v55.72h-35.38Zm35.38.89v31.33h-35.38v-31.33h35.38Zm-35.38-57.5v-31.33h35.38v31.33h-35.38Zm74.99,89.72v62.48h-74.99v-62.48h74.99Zm-38.71-.89v-74.83h0v-45.33h38.71v120.15h-38.71ZM463.22.81v228.52H.89V.81h462.33Z"
        />
        <path
          id="hartela_logo"
          className="cls-8"
          d="m510.68,184.48l-4.58,1.82v-3.24l-5.71-1.77-5.71,1.77v3.24l-4.58-1.82-6.5,2.01v20.44l5.55,4.21,4.62-2.67v6.19l6.63,5.04,6.63-5.04v-6.19l4.62,2.67,5.55-4.21v-20.44l-6.5-2.01Zm-10.3-2.34l4.02,1.25-4.02,1.42-4.02-1.42,4.02-1.25Zm-4.9,4.48v-2.69h0s0,0,0,0l4.5,1.59v3.22h0s-4.51,1.79-4.51,1.79v-3.9s0,0,0,0h0Zm-5.43-1.29l4.14,1.65-5,1.77-4.06-1.88,4.93-1.53Zm.02,11.24l1.83-.78,1.85-.79h0s0,0,0,0v3.66h0s0,0,0,0l-3.68-2.08h0s0,0,0,0Zm9.91-1.95v23.76l-5.41-4.12v-7.88l-5.81-3.82v7.28l-4.36-3.32h0v-19.1l4.36,2.01v7.32l5.81,3.28v-7.92h0s2.71,1.25,2.71,1.25l2.71,1.25h0Zm.4-.71l-5.2-2.4,5.2-2.07,5.2,2.07-5.2,2.4Zm10.81,10.15v5.9l-4.18-2.42h0v-.72h0s4.18-2.76,4.18-2.76h0Zm-.5-7.49l-3.68,2.08v-3.66h0s1.86.8,1.86.8l1.82.78h0s0,0,0,0Zm.5-7.11v6.44l-4.18-1.8h0v-2.9l-1.18-.47v-3.16h0s0,0,0,0l5.37,1.89h0Zm.38-.72l-5-1.77,4.13-1.65h0l4.93,1.53-4.06,1.88Z"
        />
        <polygon
          id="compass_arrow"
          points="148.46 147.45 130.65 160.28 135.19 163.89 117.68 185.88 118.26 186.34 135.76 164.35 146.49 150.88 139.94 167.67 140.43 168.07 148.46 147.45"
        />
        <g id="paaotsikko">
          <path
            className="cls-2"
            d="m476.03,13.22h-1.41v-2.54h-2.58v2.54h-1.41v-6.24h1.41v2.49h2.58v-2.49h1.41v6.24Z"
          />
          <path
            className="cls-2"
            d="m480.81,13.22h-1.3v-.64h-.02c-.3.5-.74.75-1.33.75-.43,0-.77-.12-1.02-.37s-.37-.57-.37-.98c0-.86.51-1.36,1.54-1.5l1.21-.16c0-.49-.26-.73-.79-.73s-1.04.16-1.51.47v-1.04c.19-.1.45-.19.79-.26s.63-.11.91-.11c1.27,0,1.91.63,1.91,1.9v2.66Zm-1.29-1.81v-.3l-.81.1c-.45.06-.67.26-.67.6,0,.16.05.29.16.39.11.1.26.15.44.15.26,0,.47-.09.63-.27.16-.18.24-.4.24-.68Z"
          />
          <path
            className="cls-2"
            d="m484.58,10.01c-.17-.09-.36-.13-.58-.13-.3,0-.53.11-.7.33-.17.22-.25.52-.25.89v2.13h-1.37v-4.46h1.37v.83h.02c.22-.6.61-.91,1.17-.91.15,0,.26.02.34.05v1.27Z"
          />
          <path
            className="cls-2"
            d="m487.69,13.17c-.2.11-.51.16-.92.16-.97,0-1.45-.5-1.45-1.51v-2.04h-.72v-1.01h.72v-.96l1.37-.39v1.35h1v1.01h-1v1.8c0,.46.18.7.55.7.14,0,.29-.04.45-.13v1.02Z"
          />
          <path
            className="cls-2"
            d="m492.25,11.39h-2.91c.05.65.45.97,1.22.97.49,0,.92-.12,1.29-.35v.99c-.41.22-.95.33-1.61.33-.72,0-1.28-.2-1.68-.6-.4-.4-.6-.96-.6-1.67s.21-1.33.64-1.76c.43-.43.96-.65,1.58-.65s1.15.19,1.51.58.53.91.53,1.57v.58Zm-1.28-.84c0-.64-.26-.96-.77-.96-.22,0-.41.09-.57.27s-.26.41-.29.68h1.64Z"
          />
          <path className="cls-2" d="m494.21,13.22h-1.37v-6.6h1.37v6.6Z" />
          <path
            className="cls-2"
            d="m498.86,13.22h-1.3v-.64h-.02c-.3.5-.74.75-1.33.75-.43,0-.77-.12-1.02-.37s-.37-.57-.37-.98c0-.86.51-1.36,1.54-1.5l1.21-.16c0-.49-.26-.73-.79-.73s-1.04.16-1.51.47v-1.04c.19-.1.45-.19.79-.26s.63-.11.91-.11c1.27,0,1.91.63,1.91,1.9v2.66Zm-1.29-1.81v-.3l-.81.1c-.45.06-.67.26-.67.6,0,.16.05.29.16.39.11.1.26.15.44.15.26,0,.47-.09.63-.27.16-.18.24-.4.24-.68Z"
          />
          <path
            className="cls-2"
            d="m473.74,16.34l-1.62,4.37h-.56l-1.58-4.37h.57l1.21,3.46c.04.11.07.24.09.39h.01c.02-.12.05-.25.1-.39l1.23-3.46h.55Z"
          />
          <path
            className="cls-2"
            d="m476.65,19.27h-2.2c0,.35.1.62.28.8.18.19.42.28.74.28.35,0,.67-.12.97-.35v.47c-.27.2-.64.3-1.09.3s-.79-.14-1.04-.42-.38-.68-.38-1.2c0-.49.14-.88.41-1.19s.62-.46,1.02-.46.72.13.95.4c.22.26.34.63.34,1.1v.26Zm-.51-.42c0-.29-.07-.51-.21-.67-.14-.16-.33-.24-.57-.24s-.44.08-.6.25c-.17.17-.27.39-.3.66h1.68Z"
          />
          <path
            className="cls-2"
            d="m478.87,18.09c-.09-.07-.21-.1-.38-.1-.21,0-.39.1-.53.3-.14.2-.21.47-.21.82v1.59h-.5v-3.12h.5v.64h.01c.07-.22.18-.39.33-.51.15-.12.31-.18.49-.18.13,0,.23.01.3.04v.52Z"
          />
          <path
            className="cls-2"
            d="m481.85,20.71h-.7l-1.38-1.5h-.01v1.5h-.5v-4.62h.5v2.93h.01l1.31-1.43h.65l-1.45,1.5,1.56,1.61Z"
          />
          <path
            className="cls-2"
            d="m484.79,20.71h-.7l-1.38-1.5h-.01v1.5h-.5v-4.62h.5v2.93h.01l1.31-1.43h.65l-1.45,1.5,1.56,1.61Z"
          />
          <path
            className="cls-2"
            d="m486.45,20.78c-.46,0-.83-.15-1.1-.44-.28-.29-.41-.68-.41-1.16,0-.52.14-.93.43-1.23.29-.29.67-.44,1.16-.44s.83.14,1.09.43c.26.29.39.68.39,1.19s-.14.9-.42,1.2-.66.45-1.13.45Zm.04-2.84c-.32,0-.57.11-.76.33-.19.22-.28.52-.28.9s.09.66.28.87c.19.21.44.32.76.32s.57-.1.75-.31c.17-.21.26-.51.26-.89s-.09-.69-.26-.9c-.17-.21-.42-.32-.75-.32Z"
          />
          <path
            className="cls-2"
            d="m488.46,20.59v-.54c.27.2.57.3.9.3.44,0,.66-.15.66-.44,0-.08-.02-.15-.06-.21-.04-.06-.09-.11-.15-.15-.06-.04-.14-.08-.22-.12-.09-.04-.18-.07-.28-.11-.14-.05-.26-.11-.36-.17-.11-.06-.19-.12-.26-.19-.07-.07-.12-.15-.16-.24-.04-.09-.05-.19-.05-.31,0-.15.03-.28.1-.39.07-.11.16-.21.27-.28s.24-.13.38-.17c.14-.04.29-.06.44-.06.27,0,.51.05.72.14v.51c-.23-.15-.49-.23-.79-.23-.09,0-.18.01-.25.03-.08.02-.14.05-.19.09-.05.04-.1.08-.12.14-.03.05-.04.11-.04.18,0,.08.01.15.04.2s.07.1.13.15c.06.04.13.08.21.12.08.04.17.07.28.11.14.05.26.11.37.16.11.06.2.12.28.19s.14.15.18.24c.04.09.06.2.06.33,0,.15-.03.29-.1.4-.07.11-.16.21-.27.28-.11.08-.24.13-.39.17-.15.04-.3.05-.47.05-.32,0-.6-.06-.83-.19Z"
          />
          <path
            className="cls-2"
            d="m493.37,20.71h-.5v-.49h-.01c-.22.37-.54.56-.96.56-.31,0-.55-.08-.73-.25-.17-.16-.26-.38-.26-.65,0-.58.34-.92,1.03-1.02l.93-.13c0-.53-.21-.79-.64-.79-.38,0-.71.13-1.02.38v-.51c.31-.2.66-.29,1.06-.29.73,0,1.1.39,1.1,1.16v2.03Zm-.5-1.58l-.75.1c-.23.03-.41.09-.52.17-.12.08-.18.23-.18.44,0,.15.05.28.16.37.11.1.25.14.43.14.25,0,.45-.09.61-.26.16-.17.24-.39.24-.66v-.31Z"
          />
          <path
            className="cls-2"
            d="m496.39,20.71h-.5v-.49h-.01c-.22.37-.54.56-.96.56-.31,0-.55-.08-.73-.25-.17-.16-.26-.38-.26-.65,0-.58.34-.92,1.03-1.02l.93-.13c0-.53-.21-.79-.64-.79-.38,0-.71.13-1.02.38v-.51c.31-.2.66-.29,1.06-.29.73,0,1.1.39,1.1,1.16v2.03Zm-.5-1.58l-.75.1c-.23.03-.41.09-.52.17-.12.08-.18.23-.18.44,0,.15.05.28.16.37.11.1.25.14.43.14.25,0,.45-.09.61-.26.16-.17.24-.39.24-.66v-.31Z"
          />
          <path
            className="cls-2"
            d="m498.8,18.09c-.09-.07-.21-.1-.38-.1-.21,0-.39.1-.53.3-.14.2-.21.47-.21.82v1.59h-.5v-3.12h.5v.64h.01c.07-.22.18-.39.33-.51.15-.12.31-.18.49-.18.13,0,.23.01.3.04v.52Z"
          />
          <path
            className="cls-2"
            d="m501.7,19.27h-2.2c0,.35.1.62.28.8.18.19.42.28.74.28.35,0,.67-.12.97-.35v.47c-.27.2-.64.3-1.09.3s-.79-.14-1.04-.42-.38-.68-.38-1.2c0-.49.14-.88.41-1.19s.62-.46,1.02-.46.72.13.95.4c.22.26.34.63.34,1.1v.26Zm-.51-.42c0-.29-.07-.51-.21-.67-.14-.16-.33-.24-.57-.24s-.44.08-.6.25c-.17.17-.27.39-.3.66h1.68Z"
          />
          <path
            className="cls-2"
            d="m504.88,20.71h-.5v-1.78c0-.66-.24-.99-.72-.99-.25,0-.46.09-.62.28-.16.19-.25.42-.25.71v1.78h-.5v-3.12h.5v.52h.01c.24-.39.58-.59,1.02-.59.34,0,.6.11.78.33s.27.54.27.96v1.91Z"
          />
          <path
            className="cls-2"
            d="m510.54,16.34l-1.62,4.37h-.56l-1.58-4.37h.57l1.21,3.46c.04.11.07.24.09.39h.01c.02-.12.05-.25.1-.39l1.23-3.46h.55Z"
          />
          <path
            className="cls-2"
            d="m513.17,20.71h-.5v-.49h-.01c-.22.37-.54.56-.96.56-.31,0-.55-.08-.73-.25-.17-.16-.26-.38-.26-.65,0-.58.34-.92,1.03-1.02l.93-.13c0-.53-.21-.79-.64-.79-.38,0-.71.13-1.02.38v-.51c.31-.2.66-.29,1.06-.29.73,0,1.1.39,1.1,1.16v2.03Zm-.5-1.58l-.75.1c-.23.03-.41.09-.52.17-.12.08-.18.23-.18.44,0,.15.05.28.16.37.11.1.25.14.43.14.25,0,.45-.09.61-.26.16-.17.24-.39.24-.66v-.31Z"
          />
          <path className="cls-2" d="m514.46,20.71h-.5v-4.62h.5v4.62Z" />
          <path
            className="cls-2"
            d="m516.62,20.78c-.46,0-.83-.15-1.1-.44-.28-.29-.41-.68-.41-1.16,0-.52.14-.93.43-1.23.29-.29.67-.44,1.16-.44s.83.14,1.09.43c.26.29.39.68.39,1.19s-.14.9-.42,1.2-.66.45-1.13.45Zm.04-2.84c-.32,0-.57.11-.76.33-.19.22-.28.52-.28.9s.09.66.28.87c.19.21.44.32.76.32s.57-.1.75-.31c.17-.21.26-.51.26-.89s-.09-.69-.26-.9c-.17-.21-.42-.32-.75-.32Z"
          />
        </g>
      </g>
      <g id="apartments">
        <polygon
          id="general_fill"
          className="cls-7"
          points="13.82 133.01 66.73 18.18 445.14 18.16 414.81 204.16 401.55 201.93 401.55 201.93 401.34 203.17 325.99 190.92 339.42 108.02 123.97 108.02 94.79 170.94 25.52 138.94 25.73 138.52 13.82 133.01"
        />
        <polygon
          id="common01"
          className="cls-7"
          points="128.5 104.76 151.83 104.76 151.66 92.25 139.53 92.25 139.53 94.88 128.59 94.89 128.5 104.76"
        />
        <g
          onClick={() => changeView(12, 'A49_int')}
          onMouseOver={() => setElementOnHover('A49_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A49"
            className={`cls-6 ${
              sceneName === 'scene_valo_krs12_A49_int'
                ? 'active-apt'
                : elementOnHover === 'A49_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A49_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="77.05 34.64 53.27 86.08 115.6 114.89 120.28 104.76 127.25 104.77 127.15 86.87 151.68 71.8 151.79 34.67 77.05 34.64"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polygon
          id="shape_72"
          className="cls-4"
          points="150.41 31.63 75.13 31.63 51.5 82.71 54.23 83.97 77.05 34.64 150.41 34.64 150.41 31.63"
        />
        <polygon
          id="shape_71"
          className="cls-4"
          points="151.63 104.74 121.87 104.74 116.92 115.49 78.63 97.8 77.25 100.78 118.52 119.85 123.97 108.02 155.11 108.02 155.1 92.25 151.63 92.25 151.63 104.74"
        />
        <rect
          id="shape_69"
          className="cls-3"
          x="155.11"
          y="62.22"
          width="31.25"
          height="43.74"
        />
        <polygon
          id="shape_68"
          className="cls-3"
          points="68.22 119.37 76.89 100.62 116.64 118.99 108.06 137.72 68.22 119.37"
        />
        <rect
          id="shape_67"
          className="cls-8"
          x="107.05"
          y="34.64"
          width="5.91"
          height=".62"
        />
        <polygon
          id="shape_66"
          className="cls-8"
          points="98.53 33.6 98.53 33.33 98.53 32.29 98.53 32.18 98.53 31.85 98.53 31.68 98.53 31.63 92.61 31.63 92.61 31.68 92.61 31.85 92.61 32.18 92.61 32.29 92.61 33.33 92.61 33.6 92.61 34.64 98.53 34.64 98.53 33.6"
        />
        <polygon
          id="shape_65"
          className="cls-8"
          points="112.98 33.6 112.98 33.33 112.98 32.29 112.98 32.18 112.98 31.85 112.98 31.68 112.98 21.34 109.68 21.34 107.07 21.34 107.07 31.68 107.07 31.85 107.07 32.18 107.07 32.29 107.07 33.33 107.07 33.6 107.07 34.64 112.98 34.64 112.98 33.6"
        />
        <polygon
          id="shape_64"
          className="cls-8"
          points="73.49 35.69 73.79 35.82 73.89 35.87 74.83 36.31 75.08 36.42 76.02 36.86 77.05 34.64 84.07 34.64 84.07 33.6 84.07 33.33 84.07 32.29 84.07 32.18 84.07 31.85 84.07 31.68 84.07 31.63 75.18 31.63 75.13 31.63 73.29 35.6 73.34 35.62 73.49 35.69"
        />
        <polygon
          id="shape_63"
          className="cls-8"
          points="141.88 33.6 141.88 33.33 141.88 32.29 141.88 32.18 141.88 31.85 141.88 31.68 141.88 31.63 135.97 31.63 135.97 31.68 135.97 31.85 135.97 32.18 135.97 32.29 135.97 33.33 135.97 33.6 135.97 34.64 141.88 34.64 141.88 33.6"
        />
        <polygon
          id="shape_62"
          className="cls-8"
          points="127.43 33.6 127.43 33.33 127.43 32.29 127.43 32.18 127.43 31.85 127.43 31.68 127.43 31.63 121.52 31.63 121.52 31.68 121.52 31.85 121.52 32.18 121.52 32.29 121.52 33.33 121.52 33.6 121.52 34.64 127.43 34.64 127.43 33.6"
        />
        <polygon
          id="shape_61"
          className="cls-8"
          points="55.6 75.18 55.7 75.23 56.64 75.67 56.89 75.78 57.83 76.22 60.31 70.85 59.37 70.41 59.12 70.3 58.18 69.86 58.08 69.82 57.78 69.68 57.63 69.61 57.58 69.59 55.1 74.95 55.15 74.98 55.3 75.05 55.6 75.18"
        />
        <polygon
          id="shape_60"
          className="cls-8"
          points="67.43 48.81 67.72 48.94 67.83 48.99 68.77 49.43 69.02 49.54 69.96 49.98 72.44 44.61 71.5 44.18 71.25 44.06 70.31 43.62 70.2 43.58 69.91 43.44 69.76 43.37 69.71 43.35 67.23 48.72 67.28 48.74 67.43 48.81"
        />
        <polygon
          id="shape_59"
          className="cls-8"
          points="51.77 89.34 49.04 88.07 51.52 82.71 54.25 83.97 53.27 86.08 78.63 97.8 77.25 100.78 51.77 89.34"
        />
        <rect
          id="shape_58"
          className="cls-8"
          x="149.42"
          y="31.63"
          width="5.68"
          height="3.04"
        />
        <polygon
          id="shape_57"
          className="cls-8"
          points="143.34 105.92 143.34 104.74 134.64 104.74 134.64 105.92 134.64 107.36 134.14 107.36 134.14 108.02 143.83 108.02 143.83 107.36 143.34 107.36 143.34 105.92"
        />
        <polygon
          id="shape_56"
          className="cls-8"
          points="151.68 92.25 151.8 71.8 139.53 71.8 139.53 70.88 109.29 70.88 109.29 86.87 126.72 86.87 126.72 93.57 125.93 93.57 125.93 94.88 139.53 94.88 139.53 92.25 151.68 92.25"
        />
        <polygon
          id="shape_55"
          className="cls-8"
          points="120.28 104.76 115.6 114.89 104.39 109.71 103.02 112.69 118.52 119.85 123.97 108.02 129.37 108.02 129.37 107.37 128.88 107.37 128.88 105.92 128.88 104.74 128.58 104.74 128.58 94.86 126.84 94.86 126.84 102.99 124.86 102.99 124.86 104.77 120.28 104.76"
        />
        <rect
          id="shape_54"
          className="cls-8"
          x="56.28"
          y="50.88"
          width="5.91"
          height="13.63"
          transform="translate(-17.98 87.26) rotate(-65.19)"
        />
        <polygon
          id="shape_53"
          className="cls-8"
          points="155.1 103.01 151.63 103.01 151.63 104.74 149.09 104.74 149.09 105.92 149.09 107.36 148.6 107.36 148.6 108.02 155.11 108.02 155.1 103.01"
        />
        <polygon
          id="shape_52"
          className="cls-8"
          points="151.66 95 155.1 95 155.1 34.67 151.8 34.67 151.66 95"
        />
        <rect
          id="shape_51"
          className="cls-8"
          x="135.97"
          y="34.64"
          width="5.91"
          height=".62"
        />
        <rect
          id="shape_50"
          className="cls-8"
          x="78.16"
          y="34.64"
          width="5.91"
          height=".62"
        />
        <rect
          id="shape_49"
          className="cls-12"
          x="340.93"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_48"
          className="cls-7"
          x="312.02"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_47"
          className="cls-7"
          x="283.11"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_46"
          className="cls-7"
          x="268.66"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_45"
          className="cls-12"
          x="326.47"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_44"
          className="cls-7"
          x="426.07"
          y="132.01"
          width=".66"
          height="1.64"
          transform="translate(226.87 532.39) rotate(-80.77)"
        />
        <rect
          id="shape_43"
          className="cls-7"
          x="428.39"
          y="117.75"
          width=".66"
          height="1.64"
          transform="translate(242.9 522.71) rotate(-80.77)"
        />
        <rect
          id="shape_42"
          className="cls-7"
          x="297.57"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_41"
          className="cls-7"
          x="442.31"
          y="32.15"
          width=".66"
          height="1.64"
          transform="translate(339.06 464.59) rotate(-80.77)"
        />
        <rect
          id="shape_40"
          className="cls-7"
          x="435.35"
          y="74.95"
          width=".66"
          height="1.64"
          transform="translate(290.98 493.65) rotate(-80.77)"
        />
        <rect
          id="shape_39"
          className="cls-7"
          x="437.67"
          y="60.68"
          width=".66"
          height="1.64"
          transform="translate(307.01 483.96) rotate(-80.77)"
        />
        <rect
          id="shape_38"
          className="cls-12"
          x="398.74"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_37"
          className="cls-7"
          x="423.76"
          y="146.28"
          width=".66"
          height="1.64"
          transform="translate(210.84 542.08) rotate(-80.77)"
        />
        <rect
          id="shape_36"
          className="cls-7"
          x="444.65"
          y="17.66"
          width=".99"
          height=".99"
          transform="translate(426.4 463.27) rotate(-89.92)"
        />
        <rect
          id="shape_35"
          className="cls-7"
          x="439.99"
          y="46.42"
          width=".66"
          height="1.64"
          transform="translate(323.03 474.27) rotate(-80.77)"
        />
        <rect
          id="shape_34"
          className="cls-7"
          x="433.03"
          y="89.21"
          width=".66"
          height="1.64"
          transform="translate(274.95 503.33) rotate(-80.77)"
        />
        <rect
          id="shape_33"
          className="cls-12"
          x="369.83"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_32"
          className="cls-12"
          x="355.38"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_31"
          className="cls-12"
          x="384.29"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_30"
          className="cls-7"
          x="427.64"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_29"
          className="cls-7"
          x="413.19"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_28"
          className="cls-7"
          x="430.71"
          y="103.48"
          width=".66"
          height="1.64"
          transform="translate(258.92 513.02) rotate(-80.77)"
        />
        <rect
          id="shape_27"
          className="cls-7"
          x="416.8"
          y="189.07"
          width=".66"
          height="1.64"
          transform="translate(162.76 571.14) rotate(-80.77)"
        />
        <rect
          id="shape_26"
          className="cls-8"
          x="49.87"
          y="52.69"
          width=".66"
          height="1.64"
          transform="translate(-19.43 76.63) rotate(-65.19)"
        />
        <rect
          id="shape_25"
          className="cls-8"
          x="62"
          y="26.45"
          width=".66"
          height="1.64"
          transform="translate(11.42 72.41) rotate(-65.19)"
        />
        <rect
          id="shape_24"
          className="cls-8"
          x="55.93"
          y="39.57"
          width=".66"
          height="1.64"
          transform="translate(-4.01 74.52) rotate(-65.19)"
        />
        <rect
          id="shape_23"
          className="cls-8"
          x="66.32"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_22"
          className="cls-8"
          x="80.77"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_21"
          className="cls-8"
          x="95.23"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_20"
          className="cls-8"
          x="43.81"
          y="65.81"
          width=".66"
          height="1.64"
          transform="translate(-34.86 78.74) rotate(-65.19)"
        />
        <rect
          id="shape_19"
          className="cls-7"
          x="19.55"
          y="118.29"
          width=".66"
          height="1.64"
          transform="translate(-96.58 87.18) rotate(-65.19)"
        />
        <rect
          id="shape_18"
          className="cls-7"
          x="13.49"
          y="131.55"
          width=".66"
          height="1.64"
          transform="translate(-112.13 89.37) rotate(-65.19)"
        />
        <rect
          id="shape_17"
          className="cls-7"
          x="25.62"
          y="105.17"
          width=".66"
          height="1.64"
          transform="translate(-81.15 85.07) rotate(-65.19)"
        />
        <rect
          id="shape_16"
          className="cls-8"
          x="37.74"
          y="78.93"
          width=".66"
          height="1.64"
          transform="translate(-50.29 80.85) rotate(-65.19)"
        />
        <rect
          id="shape_15"
          className="cls-8"
          x="109.68"
          y="17.36"
          width=".66"
          height="3.97"
        />
        <polygon
          id="shape_14"
          className="cls-7"
          points="32.88 92.92 31.4 92.22 31.12 92.82 32.62 93.51 32.88 92.92"
        />
        <rect
          id="shape_13"
          className="cls-7"
          x="153.04"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_12"
          className="cls-7"
          x="210.85"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_11"
          className="cls-7"
          x="421.44"
          y="160.54"
          width=".66"
          height="1.64"
          transform="translate(194.81 551.77) rotate(-80.77)"
        />
        <rect
          id="shape_10"
          className="cls-7"
          x="196.4"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_9"
          className="cls-8"
          x="124.13"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_8"
          className="cls-7"
          x="419.12"
          y="174.81"
          width=".66"
          height="1.64"
          transform="translate(178.77 561.45) rotate(-80.76)"
        />
        <rect
          id="shape_7"
          className="cls-7"
          x="225.3"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_6"
          className="cls-7"
          x="239.76"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_5"
          className="cls-8"
          x="138.58"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_4"
          className="cls-7"
          x="254.21"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_3"
          className="cls-7"
          x="181.94"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_2"
          className="cls-7"
          x="414.48"
          y="203.34"
          width=".66"
          height="1.64"
          transform="translate(146.73 580.83) rotate(-80.77)"
        />
        <rect
          id="shape_1"
          className="cls-7"
          x="167.49"
          y="17.36"
          width=".66"
          height="1.64"
        />
      </g>
      <g id="balcony">
        <g
          onClick={() => changeView(12, 'A49_bal')}
          onMouseOver={() => setElementOnHover('A49_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A49-bal"
            className={`cls-6 ${
              sceneName === 'scene_valo_krs12_A49_bal'
                ? 'active-apt'
                : elementOnHover === 'A49_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A49_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="66.65 18.18 38.09 79.77 50.18 85.65 75.13 31.63 138.9 31.63 138.92 18.18 66.65 18.18"
          />
        </g>

        <g
          onClick={() => changeView(12, 'YHT_ter')}
          onMouseOver={() => setElementOnHover('YHT_ter')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="YHT_t"
            className={`cls-6 ${
              sceneName === 'scene_valo_krs12_YHT_ter'
                ? 'active-apt'
                : elementOnHover === 'YHT_ter'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'YHT_ter') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            x="155.11"
            y="62.22"
            width="31.25"
            height="43.74"
          />
        </g>
        <g
          onClick={() => changeView(12, 'A49_ter')}
          onMouseOver={() => setElementOnHover('A49_ter')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A49-ter"
            className={`cls-6 ${
              sceneName === 'scene_valo_krs12_A49_ter'
                ? 'active-apt'
                : elementOnHover === 'A49_ter'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A49_ter') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="68.22 119.37 76.89 100.62 116.64 118.99 108.06 137.72 68.22 119.37"
          />
        </g>
      </g>
      <g id="elevator">
        <g id="down" onClick={() => changeFloor('down')}>
          <rect
            id="bg-3"
            className="cls-2"
            x="464.11"
            y="134.64"
            width="35.38"
            height="31.33"
          />
          <polygon
            id="down-2"
            points="472.06 145.31 491.54 145.31 481.8 155.29 472.06 145.31"
          />
        </g>
        <g id="up">
          <rect
            id="bg-4"
            className="cls-2"
            x="464.11"
            y="45.81"
            width="35.38"
            height="31.33"
          />
          <polygon
            id="up-2"
            points="491.54 65.51 472.06 65.51 481.8 55.54 491.54 65.51"
          />
        </g>
        <g id="_12-2">
          <path
            id="floor_no_1"
            d="m472.81,99.44h-.05l-3.03,1.63-.46-1.79,3.8-2.04h2.01v17.4h-2.28v-15.21Z"
          />
          <path
            id="floor_no_2"
            d="m481.44,114.65v-1.45l1.85-1.79c4.44-4.23,6.45-6.48,6.48-9.1,0-1.77-.86-3.4-3.45-3.4-1.58,0-2.89.8-3.69,1.47l-.75-1.66c1.21-1.02,2.92-1.77,4.93-1.77,3.75,0,5.33,2.57,5.33,5.06,0,3.21-2.33,5.81-6,9.34l-1.39,1.29v.05h7.82v1.96h-11.11Z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 'A49_int' && (
          <g id="A49_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone"
              className="cls-11"
              d="m97.47,70.37c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A49_bal' && (
          <g id="A49_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-2"
              className="cls-1"
              d="m73.88,29.51c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A49_ter' && (
          <g id="B49_t" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-3"
              className="cls-10"
              d="m95.05,124.16c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-3"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'YHT_ter' && (
          <g id="YHT_t-2" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-4"
              className="cls-10"
              d="m174,88.69c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
      </g>
      <g id="apartmentnr">
        <g
          id="A49"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'A49_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(12, 'A49_int')}
          onMouseOver={() => setElementOnHover('A49_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-9"
            d="m85.56,64.32h-1.66l-.48-1.51h-2.41l-.48,1.51h-1.65l2.46-6.77h1.81l2.41,6.77Zm-2.49-2.68l-.73-2.28c-.05-.17-.09-.37-.11-.61h-.04c-.02.2-.06.4-.12.59l-.74,2.29h1.73Z"
          />
          <path
            className="cls-9"
            d="m90.69,62.88h-.81v1.44h-1.36v-1.44h-2.95v-.95l2.84-4.38h1.47v4.27h.81v1.06Zm-2.15-3.84h-.03c-.04.11-.13.29-.25.53l-1.45,2.25h1.71v-2.13c0-.19,0-.4.02-.65Z"
          />
          <path
            className="cls-9"
            d="m91.46,64.18v-1.23c.36.23.78.35,1.28.35s.93-.18,1.21-.53c.29-.35.43-.86.43-1.51h-.03c-.3.43-.75.66-1.36.66-.55,0-1.01-.19-1.38-.58-.36-.39-.54-.89-.54-1.51,0-.72.22-1.3.67-1.73.44-.44,1.02-.65,1.72-.65s1.3.27,1.73.82.64,1.33.64,2.35c0,1.21-.26,2.15-.78,2.82s-1.24,1.01-2.17,1.01c-.55,0-1.03-.09-1.43-.26Zm1.96-5.6c-.26,0-.47.1-.64.31-.17.21-.25.48-.25.81s.08.61.25.81c.17.2.39.29.67.29.26,0,.47-.09.63-.28.16-.19.25-.42.25-.71,0-.36-.09-.65-.26-.89-.17-.24-.39-.35-.65-.35Z"
          />
        </g>
      </g>
      <g id="apartment_description">
        <g id="A49_text">
          <path
            className="cls-8"
            d="m508.93,58.41h-1.17l-.34-1.06h-1.69l-.34,1.06h-1.16l1.73-4.77h1.27l1.69,4.77Zm-1.75-1.88l-.51-1.6c-.04-.12-.06-.26-.08-.43h-.03c-.01.14-.04.28-.08.42l-.52,1.61h1.22Z"
          />
          <path
            className="cls-8"
            d="m512.54,57.4h-.57v1.01h-.96v-1.01h-2.08v-.67l2-3.08h1.03v3h.57v.75Zm-1.52-2.7h-.02c-.03.08-.09.2-.18.37l-1.02,1.58h1.21v-1.5c0-.13,0-.29.01-.46Z"
          />
          <path
            className="cls-8"
            d="m513.08,58.31v-.86c.25.16.55.25.9.25s.65-.12.85-.37c.2-.25.3-.6.3-1.06h-.02c-.21.31-.53.46-.96.46-.39,0-.71-.14-.97-.41-.26-.27-.38-.63-.38-1.06,0-.51.16-.91.47-1.22.31-.31.72-.46,1.21-.46s.92.19,1.22.58c.3.38.45.94.45,1.65,0,.85-.18,1.51-.55,1.99-.36.47-.87.71-1.53.71-.39,0-.72-.06-1.01-.19Zm1.38-3.94c-.18,0-.33.07-.45.22-.12.15-.18.34-.18.57s.06.43.17.57.27.21.47.21c.18,0,.33-.07.45-.2s.17-.3.17-.5c0-.25-.06-.46-.18-.62s-.27-.25-.46-.25Z"
          />
          <path
            className="cls-8"
            d="m504.56,65.26v-.5c.28.22.59.33.95.33.28,0,.51-.07.68-.21s.25-.33.25-.58c0-.53-.38-.8-1.14-.8h-.34v-.4h.32c.67,0,1.01-.25,1.01-.75,0-.46-.26-.69-.77-.69-.29,0-.56.1-.81.29v-.46c.26-.16.57-.23.93-.23s.62.09.83.27c.21.18.31.42.31.7,0,.54-.27.88-.82,1.04h0c.29.04.53.14.7.32.17.18.26.4.26.67,0,.37-.13.66-.39.89-.26.23-.61.34-1.04.34-.39,0-.7-.07-.93-.22Z"
          />
          <path className="cls-8" d="m509.15,63.97h-1.56v-.37h1.56v.37Z" />
          <path
            className="cls-8"
            d="m512.32,64.33h-.52v1.08h-.46v-1.08h-1.92v-.3l1.82-2.7h.56v2.62h.52v.38Zm-.99-.38v-1.77c0-.12,0-.27.01-.42h-.01c-.03.07-.08.17-.16.31l-1.26,1.88h1.41Z"
          />
          <path
            className="cls-8"
            d="m515.98,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
          />
          <path
            className="cls-8"
            d="m519.71,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
          />
          <path
            className="cls-8"
            d="m507.57,72.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
          />
          <path
            className="cls-8"
            d="m510.38,68.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
          />
          <path
            className="cls-8"
            d="m513.68,70.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
          />
          <path
            className="cls-8"
            d="m517.88,72.41h-.53l-.43-1.15h-1.73l-.41,1.15h-.53l1.57-4.08h.5l1.57,4.08Zm-1.12-1.58l-.64-1.74c-.02-.06-.04-.15-.06-.27h-.01c-.02.12-.04.21-.07.27l-.64,1.74h1.42Z"
          />
          <path
            className="cls-8"
            d="m520.46,72.41h-2.12v-4.08h.48v3.65h1.64v.43Z"
          />
          <path
            className="cls-8"
            d="m523.78,72.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
          />
          <path
            className="cls-8"
            d="m507.77,88.75h-3.01v-.83h.99v-2.97l-1.01.22v-.85l2.06-.42v4.01h.98v.83Z"
          />
          <path
            className="cls-8"
            d="m511.51,88.75h-3.01v-.83h.99v-2.97l-1.01.22v-.85l2.06-.42v4.01h.98v.83Z"
          />
          <path
            className="cls-8"
            d="m515.03,84.03v.88c-.24-.14-.5-.21-.79-.21-.35,0-.64.13-.85.4-.22.27-.33.63-.34,1.08h.02c.23-.34.56-.51,1-.51.4,0,.71.14.95.41.23.27.35.63.35,1.07,0,.48-.15.88-.46,1.19-.31.32-.7.48-1.18.48-.54,0-.95-.19-1.26-.58-.3-.39-.45-.93-.45-1.63,0-.82.19-1.48.58-1.98.39-.49.91-.74,1.58-.74.37,0,.65.04.86.13Zm-1.32,2.43c-.19,0-.35.07-.46.2-.11.14-.17.31-.17.52,0,.23.06.43.17.6s.27.25.46.25.34-.07.45-.22c.11-.15.17-.34.17-.58,0-.51-.21-.77-.63-.77Z"
          />
          <path
            className="cls-8"
            d="m516.86,87.91l-.49,1.68h-.82l.34-1.68h.97Z"
          />
          <path
            className="cls-8"
            d="m517.64,88.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
          />
          <path
            className="cls-8"
            d="m528.4,88.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
          />
          <path
            className="cls-8"
            d="m531.01,86.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
          />
          <path
            className="cls-8"
            d="m504.9,100.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
          />
          <path
            className="cls-8"
            d="m508.35,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
          />
          <path
            className="cls-8"
            d="m511.1,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
          />
          <path
            className="cls-8"
            d="m513.8,103.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
          />
          <path
            className="cls-8"
            d="m517.01,103.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
          />
          <path
            className="cls-8"
            d="m519.84,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
          />
          <path
            className="cls-8"
            d="m521.92,103.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
          />
          <path
            className="cls-8"
            d="m525.77,103.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
          />
          <path
            className="cls-8"
            d="m528.66,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
          />
          <path
            className="cls-8"
            d="m505.14,110.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
          />
          <path
            className="cls-8"
            d="m510.03,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
          />
          <path
            className="cls-8"
            d="m512.29,108.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
          />
          <path
            className="cls-8"
            d="m514.94,108l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
          />
          <path
            className="cls-8"
            d="m517.64,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
          />
          <path
            className="cls-8"
            d="m520.63,110.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
          />
          <path
            className="cls-8"
            d="m523.3,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
          />
          <path className="cls-8" d="m505.13,117.92h-.47v-4.32h.47v4.32Z" />
          <path
            className="cls-8"
            d="m508.02,117.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
          />
          <path
            className="cls-8"
            d="m511.17,117.92h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
          />
          <path
            className="cls-8"
            d="m513.26,117.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
          />
          <path
            className="cls-8"
            d="m516.08,116.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
          />
          <path
            className="cls-8"
            d="m518.99,116.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
          />
          <path
            className="cls-8"
            d="m521.97,117.92h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
          />
          <path
            className="cls-8"
            d="m524.62,117.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
          />
          <path
            className="cls-8"
            d="m527.51,117.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
          />
          <path className="cls-8" d="m505.13,124.92h-.47v-4.32h.47v4.32Z" />
          <path
            className="cls-8"
            d="m508.29,124.92h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
          />
          <path
            className="cls-8"
            d="m510.31,124.98c-.43,0-.78-.14-1.03-.41-.26-.27-.39-.63-.39-1.08,0-.49.13-.87.4-1.15s.63-.41,1.09-.41.77.13,1.02.4c.24.27.37.64.37,1.11s-.13.84-.39,1.12c-.26.28-.62.42-1.06.42Zm.03-2.66c-.3,0-.54.1-.71.31s-.26.49-.26.84.09.62.26.82.41.3.71.3.53-.1.7-.29.24-.47.24-.83-.08-.65-.24-.84-.39-.3-.7-.3Z"
          />
          <path
            className="cls-8"
            d="m513.71,124.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
          />
          <path
            className="cls-8"
            d="m516.53,123.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
          />
          <path
            className="cls-8"
            d="m519.44,123.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
          />
          <path
            className="cls-8"
            d="m519.82,124.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
          />
          <path
            className="cls-8"
            d="m524.67,123.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
          />
          <path
            className="cls-8"
            d="m527.58,123.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
          />
          <path
            className="cls-8"
            d="m530.56,124.92h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
          />
          <path
            className="cls-8"
            d="m505.88,138.98c-.43,0-.78-.14-1.03-.41-.26-.27-.39-.63-.39-1.08,0-.49.13-.87.4-1.15s.63-.41,1.09-.41.77.13,1.02.4c.24.27.37.64.37,1.11s-.13.84-.39,1.12c-.26.28-.62.42-1.06.42Zm.03-2.66c-.3,0-.54.1-.71.31s-.26.49-.26.84.09.62.26.82.41.3.71.3.53-.1.7-.29.24-.47.24-.83-.08-.65-.24-.84-.39-.3-.7-.3Z"
          />
          <path
            className="cls-8"
            d="m512.08,138.92h-.47v-1.67c0-.32-.05-.56-.15-.7-.1-.14-.27-.22-.5-.22-.2,0-.37.09-.51.27-.14.18-.21.4-.21.66v1.66h-.47v-1.73c0-.57-.22-.86-.66-.86-.21,0-.37.09-.51.26s-.2.4-.2.67v1.66h-.47v-2.92h.47v.46h.01c.21-.35.51-.53.91-.53.2,0,.37.06.52.17.15.11.25.26.3.44.22-.4.54-.6.97-.6.64,0,.96.4.96,1.19v1.8Z"
          />
          <path
            className="cls-8"
            d="m514.9,138.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
          />
          <path
            className="cls-8"
            d="m507.08,145.92h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
          />
          <path
            className="cls-8"
            d="m509.5,145.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
          />
          <path
            className="cls-8"
            d="m511.59,145.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
          />
          <path
            className="cls-8"
            d="m513.42,145.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
          />
          <path
            className="cls-8"
            d="m515.12,145.99c-.43,0-.78-.14-1.03-.41-.26-.27-.39-.63-.39-1.08,0-.49.13-.87.4-1.15s.63-.41,1.09-.41.77.13,1.02.4c.24.27.37.64.37,1.11s-.13.84-.39,1.12c-.26.28-.62.42-1.06.42Zm.03-2.66c-.3,0-.54.1-.71.31s-.26.49-.26.84.09.62.26.82.41.3.71.3.53-.1.7-.29.24-.47.24-.83-.08-.65-.24-.84-.39-.3-.7-.3Z"
          />
          <path
            className="cls-8"
            d="m518.52,145.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
          />
          <path
            className="cls-8"
            d="m521.35,144.58h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
          />
          <path
            className="cls-8"
            d="m523.43,143.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
          />
          <path
            className="cls-8"
            d="m525.88,145.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
          />
          <path
            className="cls-8"
            d="m526.43,145.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
          />
          <path
            className="cls-8"
            d="m528.76,145.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
          />
          <path
            className="cls-8"
            d="m531.51,142.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  A49_int: [94.8, 65.9],
  A49_bal: [71.2, 25.1],
  A49_ter: [92.4, 119.7],
  YHT_ter: [171.32, 84.24],
}

const SVG = styled.svg`
  .cls-1 {
    fill: url(#linear-gradient-2);
  }
  .cls-2 {
    fill: #fff;
  }
  .cls-3,
  .cls-4 {
    stroke: #141414;
  }
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6 {
    stroke-miterlimit: 10;
  }
  .cls-3,
  .cls-5 {
    stroke-width: 0.5px;
  }
  .cls-3,
  .cls-6 {
    fill: #cdcdb4;
  }
  .cls-7 {
    fill: #b4b4b4;
  }
  .cls-4 {
    stroke-width: 0.1px;
  }
  .cls-4,
  .cls-5 {
    fill: #f0f0e1;
  }
  .cls-5 {
    stroke: #1a1a1a;
  }
  .cls-8 {
    fill: #141414;
  }
  .cls-9 {
    fill: #505050;
    opacity: 0.99;
  }
  .cls-10 {
    fill: url(#linear-gradient-3);
  }
  .cls-11 {
    fill: url(#linear-gradient);
  }
  .cls-12 {
    fill: #d2d2d2;
  }
  .cls-6 {
    stroke: #7f7f7f;
    stroke-width: 0.25px;
  }

  #up,
  #down {
    rect {
      transition: all 0.2s ease;
    }
  }

  #down:hover {
    cursor: pointer;
    padding: 20px;

    rect.cls-2 {
      fill: var(--secondary-color);
    }
  }

  #up {
    cursor: not-allowed;

    rect.cls-2 {
      fill: var(--grey);
    }
  }

  .disable-lift-btn {
    cursor: not-allowed;

    .cls-1 {
      fill: var(--grey);
    }

    .cls-6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text .cls-2 {
    fill: var(--light);
    cursor: pointer;
  }

  .green-text .cls-2 {
    fill: var(--green-color);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }
`
