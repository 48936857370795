export const FLOOR_12_DATA = [
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 'A49_bal',
    sceneName: 'scene_valo_krs12_A49_bal',
    sceneName_future: 'scene_valo_krs12_A49_bal_future',
    name: 'A49_bal',
    label: 'A49',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_bal',
      4: 'A13_bal',
      5: 'A17_bal',
      6: 'A24_bal',
      7: 'A28_bal',
      8: 'A33_bal',
      9: 'A38_bal',
      10: 'A42_bal',
      11: 'A47_bal',
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 'A49_int',
    sceneName: 'scene_valo_krs12_A49_int',
    sceneName_future: 'scene_valo_krs12_A49_int_future',
    name: 'A49_int',
    label: 'A49',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      4: 'A13_int',
      5: 'A17_int',
      6: 'A24_int',
      7: 'A28_int',
      8: 'A33_int',
      9: 'A38_int',
      10: 'A42_int',
      11: 'A47_int',
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 'A49_ter',
    sceneName: 'scene_valo_krs12_A49_ter',
    sceneName_future: 'scene_valo_krs12_A49_ter_future',
    name: 'A49_ter',
    label: 'A49',
    lookAt: '175, 3, 120',
    face: 'south-west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      4: 'A13_int',
      5: 'A17_int',
      6: 'A24_int',
      7: 'A28_int',
      8: 'A33_int',
      9: 'B78_ter',
      10: 'A45_ter',
      11: 'A48_ter',
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 'YHT_ter',
    sceneName: 'scene_valo_krs12_YHT_ter',
    sceneName_future: 'scene_valo_krs12_YHT_ter_future',
    name: 'YHT_ter',
    label: 'Yhteisterassi',
    lookAt: '60, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      4: 'A13_int',
      5: 'A17_int',
      6: 'A24_int',
      7: 'A28_int',
      8: 'B79_ter',
      9: 'B78_ter',
      10: 'A45_ter',
      11: 'A48_ter',
    },
  },
]
