import { FLOOR_2_DATA } from './floor_2_data'
import { FLOOR_3_DATA } from './floor_3_data'
import { FLOOR_4_DATA } from './floor_4_data'
import { FLOOR_5_DATA } from './floor_5_data'
import { FLOOR_6_DATA } from './floor_6_data'
import { FLOOR_7_DATA } from './floor_7_data'
import { FLOOR_8_DATA } from './floor_8_data'
import { FLOOR_9_DATA } from './floor_9_data'
import { FLOOR_10_DATA } from './floor_10_data'
import { FLOOR_11_DATA } from './floor_11_data'
import { FLOOR_12_DATA } from './floor_12_data'

export const FLOORS_DATA = {
  2: FLOOR_2_DATA,
  3: FLOOR_3_DATA,
  4: FLOOR_4_DATA,
  5: FLOOR_5_DATA,
  6: FLOOR_6_DATA,
  7: FLOOR_7_DATA,
  8: FLOOR_8_DATA,
  9: FLOOR_9_DATA,
  10: FLOOR_10_DATA,
  11: FLOOR_11_DATA,
  12: FLOOR_12_DATA,
}
