export const FLOOR_3_DATA = [
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A04_bal',
    sceneName: 'scene_valo_krs03_A04_bal',
    sceneName_future: 'scene_valo_krs03_A04_bal_future',
    name: 'A04_bal',
    label: 'A04',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      4: 'A11_bal',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A04_int',
    sceneName: 'scene_valo_krs03_A04_int',
    sceneName_future: 'scene_valo_krs03_A04_int_future',
    name: 'A04_int',
    label: 'A04',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      4: 'A11_int',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A05_bal',
    sceneName: 'scene_valo_krs03_A05_bal',
    sceneName_future: 'scene_valo_krs03_A05_bal_future',
    name: 'A05_bal',
    label: 'A05',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      4: 'A12_bal',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A05_int',
    sceneName: 'scene_valo_krs03_A05_int',
    sceneName_future: 'scene_valo_krs03_A05_int_future',
    name: 'A05_int',
    label: 'A05',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      4: 'A12_int',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A06_bal',
    sceneName: 'scene_valo_krs03_A06_bal',
    sceneName_future: 'scene_valo_krs03_A06_bal_future',
    name: 'A06_bal',
    label: 'A06',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      4: 'A13_bal',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A06_int',
    sceneName: 'scene_valo_krs03_A06_int',
    sceneName_future: 'scene_valo_krs03_A06_int_future',
    name: 'A06_int',
    label: 'A06',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      4: 'A13_int',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A07_bal',
    sceneName: 'scene_valo_krs03_A07_bal',
    sceneName_future: 'scene_valo_krs03_A07_bal_future',
    name: 'A07_bal',
    label: 'A07',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      4: 'A14_bal',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A07_int',
    sceneName: 'scene_valo_krs03_A07_int',
    sceneName_future: 'scene_valo_krs03_A07_int_future',
    name: 'A07_int',
    label: 'A07',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      4: 'A14_int',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A08_bal',
    sceneName: 'scene_valo_krs03_A08_bal',
    sceneName_future: 'scene_valo_krs03_A08_bal_future',
    name: 'A08_bal',
    label: 'A08',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      4: 'A08_bal',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A08_int',
    sceneName: 'scene_valo_krs03_A08_int',
    sceneName_future: 'scene_valo_krs03_A08_int_future',
    name: 'A08_int',
    label: 'A08',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      4: 'A08_int',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A09_bal',
    sceneName: 'scene_valo_krs03_A09_bal',
    sceneName_future: 'scene_valo_krs03_A09_bal_future',
    name: 'A09_bal',
    label: 'A09',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      4: 'A09_bal',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A09_int',
    sceneName: 'scene_valo_krs03_A09_int',
    sceneName_future: 'scene_valo_krs03_A09_int_future',
    name: 'A09_int',
    label: 'A09',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      4: 'A09_int',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A10_bal',
    sceneName: 'scene_valo_krs03_A10_bal',
    sceneName_future: 'scene_valo_krs03_A10_bal_future',
    name: 'A10_bal',
    label: 'A10',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      4: 'A10_bal',
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A10_int',
    sceneName: 'scene_valo_krs03_A10_int',
    sceneName_future: 'scene_valo_krs03_A10_int_future',
    name: 'A10_int',
    label: 'A10',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      4: 'A10_int',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B53_bal',
    sceneName: 'scene_valo_krs03_B53_bal',
    sceneName_future: 'scene_valo_krs03_B53_bal_future',
    name: 'B53_bal',
    label: 'B53',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      4: 'B53_bal',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B53_int',
    sceneName: 'scene_valo_krs03_B53_int',
    sceneName_future: 'scene_valo_krs03_B53_int_future',
    name: 'B53_int',
    label: 'B53',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      4: 'B53_int',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B54_bal',
    sceneName: 'scene_valo_krs03_B54_bal',
    sceneName_future: 'scene_valo_krs03_B54_bal_future',
    name: 'B54_bal',
    label: 'B54',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      4: 'B54_bal',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B54_int',
    sceneName: 'scene_valo_krs03_B54_int',
    sceneName_future: 'scene_valo_krs03_B54_int_future',
    name: 'B54_int',
    label: 'B54',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      4: 'B54_int',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B55_bal',
    sceneName: 'scene_valo_krs03_B55_bal',
    sceneName_future: 'scene_valo_krs03_B55_bal_future',
    name: 'B55_bal',
    label: 'B55',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      4: 'B58_bal',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B55_int',
    sceneName: 'scene_valo_krs03_B55_int',
    sceneName_future: 'scene_valo_krs03_B55_int_future',
    name: 'B55_int',
    label: 'B55',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      4: 'B58_int',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B56_bal',
    sceneName: 'scene_valo_krs03_B56_bal',
    sceneName_future: 'scene_valo_krs03_B56_bal_future',
    name: 'B56_bal',
    label: 'B56',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      4: 'B59_bal',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B56_int',
    sceneName: 'scene_valo_krs03_B56_int',
    sceneName_future: 'scene_valo_krs03_B56_int_future',
    name: 'B56_int',
    label: 'B56',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      4: 'B59_int',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B57_bal',
    sceneName: 'scene_valo_krs03_B57_bal',
    sceneName_future: 'scene_valo_krs03_B57_bal_future',
    name: 'B57_bal',
    label: 'B57',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_bal',
      4: 'B60_bal',
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 'B57_int',
    sceneName: 'scene_valo_krs03_B57_int',
    sceneName_future: 'scene_valo_krs03_B57_int_future',
    name: 'B57_int',
    label: 'B57',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_int',
      4: 'B60_int',
    },
  },
  // {
  //   floor: 3,
  //   building: 'B',
  //   apartmentNumber: 'B52_bal',
  //   sceneName: 'scene_valo_krs03_B52_bal',
  //   sceneName_future: 'scene_valo_krs03_B52_bal_future',
  //   name: 'B52_bal',
  //   label: 'B52',
  //   lookAt: '68, 3, 120',
  //   face: 'east',

  //   relatedApartments: {
  //     2: 'B52_int',
  //     4: 'B61_bal',
  //   },
  // },
  // {
  //   floor: 3,
  //   building: 'B',
  //   apartmentNumber: 'B52_int',
  //   sceneName: 'scene_valo_krs03_B52_int',
  //   sceneName_future: 'scene_valo_krs03_B52_int_future',
  //   name: 'B52_int',
  //   label: 'B52',
  //   lookAt: '68, 3, 120',
  //   face: 'east',

  //   relatedApartments: {
  //     2: 'B52_int',
  //     4: 'B61_int',
  //   },
  // },
]
