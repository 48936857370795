export const FLOOR_7_DATA = [
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A26_bal',
    sceneName: 'scene_valo_krs07_A26_bal',
    sceneName_future: 'scene_valo_krs07_A26_bal_future',
    name: 'A26_bal',
    label: 'A26',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_bal',
      4: 'A11_bal',
      5: 'A15_bal',
      6: 'A22_bal',
      8: 'A31_bal',
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A26_int',
    sceneName: 'scene_valo_krs07_A26_int',
    sceneName_future: 'scene_valo_krs07_A26_int_future',
    name: 'A26_int',
    label: 'A26',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      6: 'A22_int',
      8: 'A31_int',
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A27_bal',
    sceneName: 'scene_valo_krs07_A27_bal',
    sceneName_future: 'scene_valo_krs07_A27_bal_future',
    name: 'A27_bal',
    label: 'A27',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_bal',
      4: 'A12_bal',
      5: 'A16_bal',
      6: 'A23_bal',
      8: 'A32_bal',
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A27_int',
    sceneName: 'scene_valo_krs07_A27_int',
    sceneName_future: 'scene_valo_krs07_A27_int_future',
    name: 'A27_int',
    label: 'A27',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_int',
      4: 'A12_int',
      5: 'A16_int',
      6: 'A23_int',
      8: 'A32_int',
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A28_bal',
    sceneName: 'scene_valo_krs07_A28_bal',
    sceneName_future: 'scene_valo_krs07_A28_bal_future',
    name: 'A28_bal',
    label: 'A28',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_bal',
      4: 'A13_bal',
      5: 'A17_bal',
      6: 'A24_bal',
      8: 'A33_bal',
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A28_int',
    sceneName: 'scene_valo_krs07_A28_int',
    sceneName_future: 'scene_valo_krs07_A28_int_future',
    name: 'A28_int',
    label: 'A28',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      4: 'A13_int',
      5: 'A17_int',
      6: 'A24_int',
      8: 'A33_int',
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A29_bal',
    sceneName: 'scene_valo_krs07_A29_bal',
    sceneName_future: 'scene_valo_krs07_A29_bal_future',
    name: 'A29_bal',
    label: 'A29',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_bal',
      4: 'A14_bal',
      5: 'A18_bal',
      6: 'A25_bal',
      8: 'A34_bal',
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A29_int',
    sceneName: 'scene_valo_krs07_A29_int',
    sceneName_future: 'scene_valo_krs07_A29_int_future',
    name: 'A29_int',
    label: 'A29',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_int',
      4: 'A14_int',
      5: 'A18_int',
      6: 'A25_int',
      8: 'A34_int',
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A30_bal',
    sceneName: 'scene_valo_krs07_A30_bal',
    sceneName_future: 'scene_valo_krs07_A30_bal_future',
    name: 'A30_bal',
    label: 'A30',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_bal',
      4: 'A08_bal',
      5: 'A19_bal',
      6: 'A20_bal',
      8: 'A35_bal',
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A30_int',
    sceneName: 'scene_valo_krs07_A30_int',
    sceneName_future: 'scene_valo_krs07_A30_int_future',
    name: 'A30_int',
    label: 'A30',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_int',
      4: 'A08_int',
      5: 'A19_int',
      6: 'A20_int',
      8: 'A35_int',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B72_bal',
    sceneName: 'scene_valo_krs07_B72_bal',
    sceneName_future: 'scene_valo_krs07_B72_bal_future',
    name: 'B72_bal',
    label: 'B72',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_bal',
      4: 'B53_bal',
      5: 'B62_bal',
      6: 'B62_bal',
      8: 'B77_bal',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B72_int',
    sceneName: 'scene_valo_krs07_B72_int',
    sceneName_future: 'scene_valo_krs07_B72_int_future',
    name: 'B72_int',
    label: 'B72',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_int',
      4: 'B53_int',
      5: 'B62_int',
      6: 'B62_int',
      8: 'B77_int',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B73_bal',
    sceneName: 'scene_valo_krs07_B73_bal',
    sceneName_future: 'scene_valo_krs07_B73_bal_future',
    name: 'B73_bal',
    label: 'B73',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B55_bal',
      4: 'B58_bal',
      5: 'B64_bal',
      6: 'B68_bal',
      8: 'B79_bal',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B73_int',
    sceneName: 'scene_valo_krs07_B73_int',
    sceneName_future: 'scene_valo_krs07_B73_int_future',
    name: 'B73_int',
    label: 'B73',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B55_int',
      4: 'B58_int',
      5: 'B64_int',
      6: 'B68_int',
      8: 'B79_int',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B74_bal',
    sceneName: 'scene_valo_krs07_B74_bal',
    sceneName_future: 'scene_valo_krs07_B74_bal_future',
    name: 'B74_bal',
    label: 'B74',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      3: 'B56_bal',
      4: 'B59_bal',
      5: 'B65_bal',
      6: 'B69_bal',
      8: 'B79_bal',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B74_int',
    sceneName: 'scene_valo_krs07_B74_int',
    sceneName_future: 'scene_valo_krs07_B74_int_future',
    name: 'B74_int',
    label: 'B74',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      3: 'B56_int',
      4: 'B59_int',
      5: 'B65_int',
      6: 'B69_int',
      8: 'B79_int',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B75_bal',
    sceneName: 'scene_valo_krs07_B75_bal',
    sceneName_future: 'scene_valo_krs07_B75_bal_future',
    name: 'B75_bal',
    label: 'B75',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_int',
      3: 'B57_bal',
      4: 'B60_bal',
      5: 'B66_bal',
      6: 'B70_bal',
      8: 'B79_bal',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B75_int',
    sceneName: 'scene_valo_krs07_B75_int',
    sceneName_future: 'scene_valo_krs07_B75_int_future',
    name: 'B75_int',
    label: 'B75',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_int',
      3: 'B57_int',
      4: 'B60_int',
      5: 'B66_int',
      6: 'B70_int',
      8: 'B79_int',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B76_bal',
    sceneName: 'scene_valo_krs07_B76_bal',
    sceneName_future: 'scene_valo_krs07_B76_bal_future',
    name: 'B76_bal',
    label: 'B76',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B52_int',
      3: 'B52_bal',
      4: 'B61_bal',
      5: 'B67_bal',
      6: 'B71_bal',
      8: 'B79_bal',
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 'B76_int',
    sceneName: 'scene_valo_krs07_B76_int',
    sceneName_future: 'scene_valo_krs07_B76_int_future',
    name: 'B76_int',
    label: 'B76',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B52_int',
      3: 'B57_int',
      4: 'B61_int',
      5: 'B67_int',
      6: 'B71_int',
      8: 'B79_int',
    },
  },
]
