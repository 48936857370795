export const FLOOR_4_DATA = [
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A11_bal',
    sceneName: 'scene_valo_krs04_A11_bal',
    sceneName_future: 'scene_valo_krs04_A11_bal_future',
    name: 'A11_bal',
    label: 'A11',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_bal',
      5: 'A15_bal',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A11_int',
    sceneName: 'scene_valo_krs04_A11_int',
    sceneName_future: 'scene_valo_krs04_A11_int_future',
    name: 'A11_int',
    label: 'A11',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      5: 'A15_int',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A12_bal',
    sceneName: 'scene_valo_krs04_A12_bal',
    sceneName_future: 'scene_valo_krs04_A12_bal_future',
    name: 'A12_bal',
    label: 'A12',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_bal',
      5: 'A16_bal',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A12_int',
    sceneName: 'scene_valo_krs04_A12_int',
    sceneName_future: 'scene_valo_krs04_A12_int_future',
    name: 'A12_int',
    label: 'A12',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_int',
      5: 'A16_int',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A13_bal',
    sceneName: 'scene_valo_krs04_A13_bal',
    sceneName_future: 'scene_valo_krs04_A13_bal_future',
    name: 'A13_bal',
    label: 'A13',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_bal',
      5: 'A17_bal',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A13_int',
    sceneName: 'scene_valo_krs04_A13_int',
    sceneName_future: 'scene_valo_krs04_A13_int_future',
    name: 'A13_int',
    label: 'A13',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      5: 'A17_int',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A14_bal',
    sceneName: 'scene_valo_krs04_A14_bal',
    sceneName_future: 'scene_valo_krs04_A14_bal_future',
    name: 'A14_bal',
    label: 'A14',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_bal',
      5: 'A18_bal',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A14_int',
    sceneName: 'scene_valo_krs04_A14_int',
    sceneName_future: 'scene_valo_krs04_A14_int_future',
    name: 'A14_int',
    label: 'A14',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_int',
      5: 'A18_int',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A08_bal',
    sceneName: 'scene_valo_krs03_A08_bal',
    sceneName_future: 'scene_valo_krs03_A08_bal_future',
    name: 'A08_bal',
    label: 'A08',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_bal',
      5: 'A19_bal',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A08_int',
    sceneName: 'scene_valo_krs04_A08_int',
    sceneName_future: 'scene_valo_krs04_A08_int_future',
    name: 'A08_int',
    label: 'A08',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_int',
      5: 'A19_int',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A09_bal',
    sceneName: 'scene_valo_krs03_A09_bal',
    sceneName_future: 'scene_valo_krs03_A09_bal_future',
    name: 'A09_bal',
    label: 'A09',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A09_bal',
      5: 'A20_bal',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A09_int',
    sceneName: 'scene_valo_krs04_A09_int',
    sceneName_future: 'scene_valo_krs04_A09_int_future',
    name: 'A09_int',
    label: 'A09',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A09_int',
      5: 'A20_int',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A10_bal',
    sceneName: 'scene_valo_krs03_A10_bal',
    sceneName_future: 'scene_valo_krs03_A10_bal_future',
    name: 'A10_bal',
    label: 'A10',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A10_bal',
      5: 'A21_bal',
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A10_int',
    sceneName: 'scene_valo_krs04_A10_int',
    sceneName_future: 'scene_valo_krs04_A10_int_future',
    name: 'A10_int',
    label: 'A10',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A10_int',
      5: 'A21_int',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B53_bal',
    sceneName: 'scene_valo_krs03_B53_bal',
    sceneName_future: 'scene_valo_krs03_B53_bal_future',
    name: 'B53_bal',
    label: 'B53',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_bal',
      5: 'B62_bal',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B53_int',
    sceneName: 'scene_valo_krs04_B53_int',
    sceneName_future: 'scene_valo_krs04_B53_int_future',
    name: 'B53_int',
    label: 'B53',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_int',
      5: 'B62_int',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B54_bal',
    sceneName: 'scene_valo_krs03_B54_bal',
    sceneName_future: 'scene_valo_krs03_B54_bal_future',
    name: 'B54_bal',
    label: 'B54',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B54_bal',
      5: 'B63_bal',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B54_int',
    sceneName: 'scene_valo_krs04_B54_int',
    sceneName_future: 'scene_valo_krs04_B54_int_future',
    name: 'B54_int',
    label: 'B54',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B54_int',
      5: 'B63_int',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B58_bal',
    sceneName: 'scene_valo_krs04_B58_bal',
    sceneName_future: 'scene_valo_krs04_B58_bal_future',
    name: 'B58_bal',
    label: 'B58',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B55_bal',
      5: 'B64_bal',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B58_int',
    sceneName: 'scene_valo_krs04_B58_int',
    sceneName_future: 'scene_valo_krs04_B58_int_future',
    name: 'B58_int',
    label: 'B58',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B55_int',
      5: 'B64_int',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B59_bal',
    sceneName: 'scene_valo_krs04_B59_bal',
    sceneName_future: 'scene_valo_krs04_B59_bal_future',
    name: 'B59_bal',
    label: 'B59',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      3: 'B56_bal',
      5: 'B65_bal',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B59_int',
    sceneName: 'scene_valo_krs04_B59_int',
    sceneName_future: 'scene_valo_krs04_B59_int_future',
    name: 'B59_int',
    label: 'B59',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      3: 'B56_int',
      5: 'B65_int',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B60_bal',
    sceneName: 'scene_valo_krs04_B60_bal',
    sceneName_future: 'scene_valo_krs04_B60_bal_future',
    name: 'B60_bal',
    label: 'B60',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_int',
      3: 'B57_bal',
      5: 'B66_bal',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B60_int',
    sceneName: 'scene_valo_krs04_B60_int',
    sceneName_future: 'scene_valo_krs04_B60_int_future',
    name: 'B60_int',
    label: 'B60',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_int',
      3: 'B57_int',
      5: 'B66_int',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B61_bal',
    sceneName: 'scene_valo_krs04_B61_bal',
    sceneName_future: 'scene_valo_krs04_B61_bal_future',
    name: 'B61_bal',
    label: 'B61',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B52_int',
      3: 'B52_bal',
      5: 'B67_bal',
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 'B61_int',
    sceneName: 'scene_valo_krs04_B61_int',
    sceneName_future: 'scene_valo_krs04_B61_int_future',
    name: 'B61_int',
    label: 'B61',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B52_int',
      3: 'B57_int',
      5: 'B67_int',
    },
  },
]
