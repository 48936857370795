export const FLOOR_2_DATA = [
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 'A01_int',
    sceneName: 'scene_valo_krs02_A01_int',
    sceneName_future: 'scene_valo_krs02_A01_int_future',
    name: 'A01_int',
    label: 'A01',
    lookAt: '85.7, 3, 120',
    face: 'south-east',

    relatedApartments: {
      3: 'A04_int',
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 'A01_bal',
    sceneName: 'scene_valo_krs02_A01_bal',
    sceneName_future: 'scene_valo_krs02_A01_bal_future',
    name: 'A01_bal',
    label: 'A01',
    lookAt: '85.7, 3, 120',
    face: 'south-east',

    relatedApartments: {
      3: 'A04_int',
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 'A02_int',
    sceneName: 'scene_valo_krs02_A02_int',
    sceneName_future: 'scene_valo_krs02_A02_int_future',
    name: 'A02_int',
    label: 'A02',
    lookAt: '148.5, 3, 120',
    face: 'south',

    relatedApartments: {
      3: 'A07_int',
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 'A02_bal',
    sceneName: 'scene_valo_krs02_A02_bal',
    sceneName_future: 'scene_valo_krs02_A02_bal_future',
    name: 'A02_bal',
    label: 'A02',
    lookAt: '148.5, 3, 120',
    face: 'south',

    relatedApartments: {
      3: 'A07_int',
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 'A03_int',
    sceneName: 'scene_valo_krs02_A03_int',
    sceneName_future: 'scene_valo_krs02_A03_int_future',
    name: 'A03_int',
    label: 'A03',
    lookAt: '148.5, 3, 120',
    face: 'south',

    relatedApartments: {
      3: 'A09_int',
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 'A03_bal',
    sceneName: 'scene_valo_krs02_A03_bal',
    sceneName_future: 'scene_valo_krs02_A03_bal_future',
    name: 'A03_bal',
    label: 'A03',
    lookAt: '148.5, 3, 120',
    face: 'south',

    relatedApartments: {
      3: 'A09_int',
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 'B50_int',
    sceneName: 'scene_valo_krs02_B50_int',
    sceneName_future: 'scene_valo_krs02_B50_int_future',
    name: 'B50_int',
    label: 'B50',
    lookAt: '148.5, 3, 120',
    face: 'south',

    relatedApartments: {
      3: 'B53_int',
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 'B50_bal',
    sceneName: 'scene_valo_krs02_B50_bal',
    sceneName_future: 'scene_valo_krs02_B50_bal_future',
    name: 'B50_bal',
    label: 'B50',
    lookAt: '148.5, 3, 120',
    face: 'south',

    relatedApartments: {
      3: 'B53_int',
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 'B52_int',
    sceneName: 'scene_valo_krs02_B52_int',
    sceneName_future: 'scene_valo_krs02_B52_int_future',
    name: 'B52_int',
    label: 'B52',
    lookAt: '-105, 3, 120',
    face: 'south-west',

    relatedApartments: {
      3: 'B57_int',
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 'B52_bal',
    sceneName: 'scene_valo_krs02_B52_bal',
    sceneName_future: 'scene_valo_krs02_B52_bal_future',
    name: 'B52_bal',
    label: 'B52',
    lookAt: '-105, 3, 120',
    face: 'south-west',

    relatedApartments: {
      3: 'B57_int',
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 'B51_int',
    sceneName: 'scene_valo_krs02_B51_int',
    sceneName_future: 'scene_valo_krs02_B51_int_future',
    name: 'B51_int',
    label: 'B51',
    lookAt: '68, 3, 120',
    face: 'west',

    relatedApartments: {
      3: 'B57_int',
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 'B51_bal',
    sceneName: 'scene_valo_krs02_B51_bal',
    sceneName_future: 'scene_valo_krs02_B51_bal_future',
    name: 'B51_bal',
    label: 'B51',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      3: 'B57_bal',
    },
  },
]
