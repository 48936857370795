export const FLOOR_10_DATA = [
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A41_bal',
    sceneName: 'scene_valo_krs10_A41_bal',
    sceneName_future: 'scene_valo_krs10_A41_bal_future',
    name: 'A41_bal',
    label: 'A41',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_bal',
      4: 'A11_bal',
      5: 'A15_bal',
      6: 'A22_bal',
      7: 'A26_bal',
      8: 'A31_bal',
      9: 'A36_bal',
      11: 'A46_bal',
      12: 'A49_bal',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A41_int',
    sceneName: 'scene_valo_krs10_A41_int',
    sceneName_future: 'scene_valo_krs10_A41_int_future',
    name: 'A41_int',
    label: 'A41',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      6: 'A22_int',
      7: 'A26_int',
      8: 'A31_int',
      9: 'A36_int',
      11: 'A46_int',
      12: 'A49_int',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A42_bal',
    sceneName: 'scene_valo_krs10_A42_bal',
    sceneName_future: 'scene_valo_krs10_A42_bal_future',
    name: 'A42_bal',
    label: 'A42',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_bal',
      4: 'A12_bal',
      5: 'A16_bal',
      6: 'A23_bal',
      7: 'A27_bal',
      8: 'A32_bal',
      9: 'A37_bal',
      11: 'A47_bal',
      12: 'A49_bal',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A42_int',
    sceneName: 'scene_valo_krs10_A42_int',
    sceneName_future: 'scene_valo_krs10_A42_int_future',
    name: 'A42_int',
    label: 'A42',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_int',
      4: 'A12_int',
      5: 'A16_int',
      6: 'A23_int',
      7: 'A27_int',
      8: 'A32_int',
      9: 'A37_int',
      11: 'A47_int',
      12: 'A49_int',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A43_bal',
    sceneName: 'scene_valo_krs10_A43_bal',
    sceneName_future: 'scene_valo_krs10_A43_bal_future',
    name: 'A43_bal',
    label: 'A43',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_bal',
      4: 'A13_bal',
      5: 'A17_bal',
      6: 'A24_bal',
      7: 'A28_bal',
      8: 'A33_bal',
      9: 'A38_bal',
      11: 'A47_bal',
      12: 'A49_bal',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A43_int',
    sceneName: 'scene_valo_krs10_A43_int',
    sceneName_future: 'scene_valo_krs10_A43_int_future',
    name: 'A43_int',
    label: 'A43',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      4: 'A13_int',
      5: 'A17_int',
      6: 'A24_int',
      7: 'A28_int',
      8: 'A33_int',
      9: 'A38_int',
      11: 'A47_int',
      12: 'A49_int',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A44_bal',
    sceneName: 'scene_valo_krs10_A44_bal',
    sceneName_future: 'scene_valo_krs10_A44_bal_future',
    name: 'A44_bal',
    label: 'A44',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_bal',
      4: 'A14_bal',
      5: 'A18_bal',
      6: 'A25_bal',
      7: 'A29_bal',
      8: 'A34_bal',
      9: 'A39_bal',
      11: 'A48_bal',
      12: 'A49_bal',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A44_int',
    sceneName: 'scene_valo_krs10_A44_int',
    sceneName_future: 'scene_valo_krs10_A44_int_future',
    name: 'A44_int',
    label: 'A44',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_int',
      4: 'A14_int',
      5: 'A18_int',
      6: 'A25_int',
      7: 'A29_int',
      8: 'A34_int',
      9: 'A39_int',
      11: 'A48_int',
      12: 'A49_int',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A45_bal',
    sceneName: 'scene_valo_krs10_A45_bal',
    sceneName_future: 'scene_valo_krs10_A45_bal_future',
    name: 'A45_bal',
    label: 'A45',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_bal',
      4: 'A08_bal',
      5: 'A19_bal',
      6: 'A20_bal',
      7: 'A30_bal',
      8: 'A35_bal',
      9: 'A40_bal',
      11: 'A48_bal',
      12: 'A49_bal',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A45_int',
    sceneName: 'scene_valo_krs10_A45_int',
    sceneName_future: 'scene_valo_krs10_A45_int_future',
    name: 'A45_int',
    label: 'A45',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_int',
      4: 'A08_int',
      5: 'A19_int',
      6: 'A20_int',
      7: 'A30_int',
      8: 'A35_int',
      9: 'A40_int',
      11: 'A48_int',
      12: 'A49_int',
    },
  },
  {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A45_ter',
    sceneName: 'scene_valo_krs10_A45_ter',
    sceneName_future: 'scene_valo_krs10_A45_ter_future',
    name: 'A45_ter',
    label: 'A45',
    lookAt: '60, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_int',
      4: 'A08_int',
      5: 'A19_int',
      6: 'A20_int',
      7: 'A30_int',
      8: 'A35_int',
      9: 'B78_ter',
      11: 'A48_ter',
      12: 'YHT_ter',
    },
  },
]
