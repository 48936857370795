import React from 'react'
import styled from 'styled-components'

export function Info() {
  return (
    <Wrapper>
      <p>
        3d-luonnoskuva asunnosta. Asunto rakennetaan myyntisuunnitelmien mukaan.
      </p>
      <p>Malli päivittyy suunnittelun edetessä.</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;

  p {
    margin: 0;
    color: #fff;
    font-family: 'CeraPro-Medium', sans-serif;
    letter-spacing: 0.6px;
  }

  @media (max-width: 575px) {
    display: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    bottom: 10px;
    p {
      font-size: 8px;
    }
  }
`
