export const initialApartments = {
  2: {
    floor: 2,
    building: 'A',
    apartmentNumber: 'A01_int',
    sceneName: 'scene_valo_krs02_A01_int',
    sceneName_future: 'scene_valo_krs02_A01_int_future',
    name: 'A01_int',
    label: 'A01',
    lookAt: '85.7, 3, 120',
    face: 'south-east',

    relatedApartments: {
      3: 'A04_int',
    },
  },
  3: {
    floor: 3,
    building: 'A',
    apartmentNumber: 'A04_int',
    sceneName: 'scene_valo_krs03_A04_int',
    sceneName_future: 'scene_valo_krs03_A04_int_future',
    name: 'A04_int',
    label: 'A04',
    lookAt: '85.7, 3, 120',
    face: 'south-east',

    relatedApartments: {
      2: 'A01_int',
      4: 'A11_int',
    },
  },
  4: {
    floor: 4,
    building: 'A',
    apartmentNumber: 'A11_int',
    sceneName: 'scene_valo_krs04_A11_int',
    sceneName_future: 'scene_valo_krs04_A11_int_future',
    name: 'A11_int',
    label: 'A11',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      5: 'A015_int',
    },
  },
  5: {
    floor: 5,
    building: 'A',
    apartmentNumber: 'A15_int',
    sceneName: 'scene_valo_krs05_A15_int',
    sceneName_future: 'scene_valo_krs05_A15_int_future',
    name: 'A15_int',
    label: 'A15',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      6: 'A22_int',
    },
  },
  6: {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A22_int',
    sceneName: 'scene_valo_krs06_A22_int',
    sceneName_future: 'scene_valo_krs06_A22_int_future',
    name: 'A22_int',
    label: 'A22',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      7: 'A26_int',
    },
  },
  7: {
    floor: 7,
    building: 'A',
    apartmentNumber: 'A26_int',
    sceneName: 'scene_valo_krs07_A26_int',
    sceneName_future: 'scene_valo_krs07_A26_int_future',
    name: 'A26_int',
    label: 'A26',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      6: 'A22_int',
      8: 'A31_int',
    },
  },
  8: {
    floor: 8,
    building: 'A',
    apartmentNumber: 'A31_int',
    sceneName: 'scene_valo_krs08_A31_int',
    sceneName_future: 'scene_valo_krs08_A31_int_future',
    name: 'A31_int',
    label: 'A31',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      6: 'A22_int',
      7: 'A26_int',
      9: 'A36_int',
    },
  },
  9: {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A36_int',
    sceneName: 'scene_valo_krs09_A36_int',
    sceneName_future: 'scene_valo_krs09_A36_int_future',
    name: 'A36_int',
    label: 'A36',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      6: 'A22_int',
      7: 'A26_int',
      8: 'A31_int',
      10: 'A41_int',
    },
  },
  10: {
    floor: 10,
    building: 'A',
    apartmentNumber: 'A41_int',
    sceneName: 'scene_valo_krs10_A41_int',
    sceneName_future: 'scene_valo_krs10_A41_int_future',
    name: 'A41_int',
    label: 'A41',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      6: 'A22_int',
      7: 'A26_int',
      8: 'A31_int',
      9: 'A36_int',
      11: 'A46_int',
    },
  },
  11: {
    floor: 11,
    building: 'A',
    apartmentNumber: 'A46_int',
    sceneName: 'scene_valo_krs11_A46_int',
    sceneName_future: 'scene_valo_krs11_A46_int_future',
    name: 'A46_int',
    label: 'A46',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      6: 'A22_int',
      7: 'A26_int',
      8: 'A31_int',
      9: 'A36_int',
      10: 'A41_int',
      12: 'A49_int',
    },
  },
  12: {
    floor: 12,
    building: 'A',
    apartmentNumber: 'A49_int',
    sceneName: 'scene_valo_krs12_A49_int',
    sceneName_future: 'scene_valo_krs12_A49_int_future',
    name: 'A49_int',
    label: 'A49',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      4: 'A13_int',
      5: 'A17_int',
      6: 'A24_int',
      7: 'A28_int',
      8: 'A33_int',
      9: 'A38_int',
      10: 'A42_int',
      11: 'A48_int',
    },
  },
}
