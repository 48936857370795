export const FLOOR_9_DATA = [
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A36_bal',
    sceneName: 'scene_valo_krs09_A36_bal',
    sceneName_future: 'scene_valo_krs09_A36_bal_future',
    name: 'A36_bal',
    label: 'A36',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_bal',
      4: 'A11_bal',
      5: 'A15_bal',
      6: 'A22_bal',
      7: 'A26_bal',
      8: 'A31_bal',
      10: 'A41_bal',
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A36_int',
    sceneName: 'scene_valo_krs09_A36_int',
    sceneName_future: 'scene_valo_krs09_A36_int_future',
    name: 'A36_int',
    label: 'A36',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      6: 'A22_int',
      7: 'A26_int',
      8: 'A31_int',
      10: 'A41_int',
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A37_bal',
    sceneName: 'scene_valo_krs09_A37_bal',
    sceneName_future: 'scene_valo_krs09_A37_bal_future',
    name: 'A37_bal',
    label: 'A37',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_bal',
      4: 'A12_bal',
      5: 'A16_bal',
      6: 'A23_bal',
      7: 'A27_bal',
      8: 'A32_bal',
      10: 'A42_bal',
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A37_int',
    sceneName: 'scene_valo_krs09_A37_int',
    sceneName_future: 'scene_valo_krs09_A37_int_future',
    name: 'A37_int',
    label: 'A37',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_int',
      4: 'A12_int',
      5: 'A16_int',
      6: 'A23_int',
      7: 'A27_int',
      8: 'A32_int',
      10: 'A42_int',
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A38_bal',
    sceneName: 'scene_valo_krs09_A38_bal',
    sceneName_future: 'scene_valo_krs09_A38_bal_future',
    name: 'A38_bal',
    label: 'A38',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_bal',
      4: 'A13_bal',
      5: 'A17_bal',
      6: 'A24_bal',
      7: 'A28_bal',
      8: 'A33_bal',
      10: 'A43_bal',
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A38_int',
    sceneName: 'scene_valo_krs09_A38_int',
    sceneName_future: 'scene_valo_krs09_A38_int_future',
    name: 'A38_int',
    label: 'A38',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      4: 'A13_int',
      5: 'A17_int',
      6: 'A24_int',
      7: 'A28_int',
      8: 'A33_int',
      10: 'A43_int',
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A39_bal',
    sceneName: 'scene_valo_krs09_A39_bal',
    sceneName_future: 'scene_valo_krs09_A39_bal_future',
    name: 'A39_bal',
    label: 'A39',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_bal',
      4: 'A14_bal',
      5: 'A18_bal',
      6: 'A25_bal',
      7: 'A29_bal',
      8: 'A34_bal',
      10: 'A44_bal',
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A39_int',
    sceneName: 'scene_valo_krs09_A39_int',
    sceneName_future: 'scene_valo_krs09_A39_int_future',
    name: 'A39_int',
    label: 'A39',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_int',
      4: 'A14_int',
      5: 'A18_int',
      6: 'A25_int',
      7: 'A29_int',
      8: 'A34_int',
      10: 'A44_int',
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A40_bal',
    sceneName: 'scene_valo_krs09_A40_bal',
    sceneName_future: 'scene_valo_krs09_A40_bal_future',
    name: 'A40_bal',
    label: 'A40',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_bal',
      4: 'A08_bal',
      5: 'A19_bal',
      6: 'A20_bal',
      7: 'A30_bal',
      8: 'A35_bal',
      10: 'A45_bal',
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 'A40_int',
    sceneName: 'scene_valo_krs09_A40_int',
    sceneName_future: 'scene_valo_krs09_A40_int_future',
    name: 'A40_int',
    label: 'A40',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_int',
      4: 'A08_int',
      5: 'A19_int',
      6: 'A20_int',
      7: 'A30_int',
      8: 'A35_int',
      10: 'A45_int',
    },
  },
  {
    floor: 9,
    building: 'B',
    apartmentNumber: 'B78_bal',
    sceneName: 'scene_valo_krs09_B78_bal',
    sceneName_future: 'scene_valo_krs09_B78_bal_future',
    name: 'B78_bal',
    label: 'B78',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_bal',
      4: 'B53_bal',
      5: 'B62_bal',
      6: 'B62_bal',
      7: 'B72_bal',
      8: 'B77_bal',
      10: 'A45_bal',
    },
  },
  {
    floor: 9,
    building: 'B',
    apartmentNumber: 'B78_int',
    sceneName: 'scene_valo_krs09_B78_int',
    sceneName_future: 'scene_valo_krs09_B78_int_future',
    name: 'B78_int',
    label: 'B78',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_int',
      4: 'B53_int',
      5: 'B62_int',
      6: 'B62_int',
      7: 'B72_int',
      8: 'B77_int',
      10: 'A45_int',
    },
  },
  {
    floor: 9,
    building: 'B',
    apartmentNumber: 'B78_ter',
    sceneName: 'scene_valo_krs09_B78_ter',
    sceneName_future: 'scene_valo_krs09_B78_ter_future',
    name: 'B78_ter',
    label: 'B78',
    lookAt: '60, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_int',
      4: 'B53_int',
      5: 'B62_int',
      6: 'B62_int',
      7: 'B72_int',
      8: 'B79_ter',
      10: 'A45_ter',
      11: 'A48_ter',
    },
  },
]
