import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor7({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  apartments,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="rakennus_8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 540 238.96"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="227.2"
          y1="-503.74"
          x2="227.2"
          y2="-547.16"
          gradientTransform="translate(-164.9 -377.96) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".01" stopColor="#ebebeb" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="192.78"
          y1="-497.62"
          x2="192.78"
          y2="-541.04"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="232.88"
          y1="-460.21"
          x2="232.88"
          y2="-503.62"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="211.71"
          y1="-458.6"
          x2="211.71"
          y2="-502.01"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="260.13"
          y1="-428.96"
          x2="260.13"
          y2="-472.37"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="235.52"
          y1="-407.45"
          x2="235.52"
          y2="-450.87"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="323.65"
          y1="-444.47"
          x2="323.65"
          y2="-487.89"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="303.05"
          y1="-407.45"
          x2="303.05"
          y2="-450.87"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="379.05"
          y1="-436.17"
          x2="379.05"
          y2="-479.58"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="383.1"
          y1="-407.45"
          x2="383.1"
          y2="-450.87"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="453.61"
          y1="-435.48"
          x2="453.61"
          y2="-478.9"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="455.38"
          y1="-407.45"
          x2="455.38"
          y2="-450.87"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="519.68"
          y1="-435.48"
          x2="519.68"
          y2="-478.9"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="512.67"
          y1="-407.45"
          x2="512.67"
          y2="-450.87"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-15"
          x1="558.92"
          y1="-447.49"
          x2="558.92"
          y2="-490.9"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-16"
          x1="601.45"
          y1="-407.45"
          x2="601.45"
          y2="-450.87"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-17"
          x1="564.3"
          y1="-498.03"
          x2="564.3"
          y2="-541.44"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-18"
          x1="585.86"
          y1="-507.13"
          x2="585.86"
          y2="-550.54"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-19"
          x1="526.99"
          y1="-530.22"
          x2="526.99"
          y2="-573.63"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-20"
          x1="577.1"
          y1="-557.2"
          x2="577.1"
          y2="-600.61"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <rect id="bg-2" className="cls-2" width="539.67" height="238.96" />
        <path
          id="UI_frame"
          className="cls-17"
          d="m0,0v238.96h539.99V0H0Zm464.11,133.75v-55.72h35.38v55.72h-35.38Zm35.38.89v31.33h-35.38v-31.33h35.38Zm-35.38-57.5v-31.33h35.38v31.33h-35.38Zm74.99,89.72v62.48h-74.99v-62.48h74.99Zm-38.71-.89v-74.83h0v-45.33h38.71v120.15h-38.71ZM463.22.81v228.52H.89V.81h462.33Z"
        />
        <path
          id="hartela_logo"
          className="cls-17"
          d="m510.68,184.48l-4.58,1.82v-3.24l-5.71-1.77-5.71,1.77v3.24l-4.58-1.82-6.5,2.01v20.44l5.55,4.21,4.62-2.67v6.19l6.63,5.04,6.63-5.04v-6.19l4.62,2.67,5.55-4.21v-20.44l-6.5-2.01Zm-10.3-2.34l4.02,1.25-4.02,1.42-4.02-1.42,4.02-1.25Zm-4.9,4.48v-2.69h0s0,0,0,0l4.5,1.59v3.22h0s-4.51,1.79-4.51,1.79v-3.9s0,0,0,0h0Zm-5.43-1.29l4.14,1.65-5,1.77-4.06-1.88,4.93-1.53Zm.02,11.24l1.83-.78,1.85-.79h0s0,0,0,0v3.66h0s0,0,0,0l-3.68-2.08h0s0,0,0,0Zm9.91-1.95v23.76l-5.41-4.12v-7.88l-5.81-3.82v7.28l-4.36-3.32h0v-19.1l4.36,2.01v7.32l5.81,3.28v-7.92h0s2.71,1.25,2.71,1.25l2.71,1.25h0Zm.4-.71l-5.2-2.4,5.2-2.07,5.2,2.07-5.2,2.4Zm10.81,10.15v5.9l-4.18-2.42h0v-.72h0s4.18-2.76,4.18-2.76h0Zm-.5-7.49l-3.68,2.08v-3.66h0s1.86.8,1.86.8l1.82.78h0s0,0,0,0Zm.5-7.11v6.44l-4.18-1.8h0v-2.9l-1.18-.47v-3.16h0s0,0,0,0l5.37,1.89h0Zm.38-.72l-5-1.77,4.13-1.65h0l4.93,1.53-4.06,1.88Z"
        />
        <polygon
          id="compass_arrow"
          points="148.46 147.45 130.65 160.28 135.19 163.89 117.68 185.88 118.26 186.34 135.76 164.35 146.49 150.88 139.94 167.67 140.43 168.07 148.46 147.45"
        />
        <g id="paaotsikko">
          <path
            className="cls-2"
            d="m476.03,13.22h-1.41v-2.54h-2.58v2.54h-1.41v-6.24h1.41v2.49h2.58v-2.49h1.41v6.24Z"
          />
          <path
            className="cls-2"
            d="m480.81,13.22h-1.3v-.64h-.02c-.3.5-.74.75-1.33.75-.43,0-.77-.12-1.02-.37s-.37-.57-.37-.98c0-.86.51-1.36,1.54-1.5l1.21-.16c0-.49-.26-.73-.79-.73s-1.04.16-1.51.47v-1.04c.19-.1.45-.19.79-.26s.63-.11.91-.11c1.27,0,1.91.63,1.91,1.9v2.66Zm-1.29-1.81v-.3l-.81.1c-.45.06-.67.26-.67.6,0,.16.05.29.16.39.11.1.26.15.44.15.26,0,.47-.09.63-.27.16-.18.24-.4.24-.68Z"
          />
          <path
            className="cls-2"
            d="m484.58,10.01c-.17-.09-.36-.13-.58-.13-.3,0-.53.11-.7.33-.17.22-.25.52-.25.89v2.13h-1.37v-4.46h1.37v.83h.02c.22-.6.61-.91,1.17-.91.15,0,.26.02.34.05v1.27Z"
          />
          <path
            className="cls-2"
            d="m487.69,13.17c-.2.11-.51.16-.92.16-.97,0-1.45-.5-1.45-1.51v-2.04h-.72v-1.01h.72v-.96l1.37-.39v1.35h1v1.01h-1v1.8c0,.46.18.7.55.7.14,0,.29-.04.45-.13v1.02Z"
          />
          <path
            className="cls-2"
            d="m492.25,11.39h-2.91c.05.65.45.97,1.22.97.49,0,.92-.12,1.29-.35v.99c-.41.22-.95.33-1.61.33-.72,0-1.28-.2-1.68-.6-.4-.4-.6-.96-.6-1.67s.21-1.33.64-1.76c.43-.43.96-.65,1.58-.65s1.15.19,1.51.58.53.91.53,1.57v.58Zm-1.28-.84c0-.64-.26-.96-.77-.96-.22,0-.41.09-.57.27s-.26.41-.29.68h1.64Z"
          />
          <path className="cls-2" d="m494.21,13.22h-1.37v-6.6h1.37v6.6Z" />
          <path
            className="cls-2"
            d="m498.86,13.22h-1.3v-.64h-.02c-.3.5-.74.75-1.33.75-.43,0-.77-.12-1.02-.37s-.37-.57-.37-.98c0-.86.51-1.36,1.54-1.5l1.21-.16c0-.49-.26-.73-.79-.73s-1.04.16-1.51.47v-1.04c.19-.1.45-.19.79-.26s.63-.11.91-.11c1.27,0,1.91.63,1.91,1.9v2.66Zm-1.29-1.81v-.3l-.81.1c-.45.06-.67.26-.67.6,0,.16.05.29.16.39.11.1.26.15.44.15.26,0,.47-.09.63-.27.16-.18.24-.4.24-.68Z"
          />
          <path
            className="cls-2"
            d="m473.74,16.34l-1.62,4.37h-.56l-1.58-4.37h.57l1.21,3.46c.04.11.07.24.09.39h.01c.02-.12.05-.25.1-.39l1.23-3.46h.55Z"
          />
          <path
            className="cls-2"
            d="m476.65,19.27h-2.2c0,.35.1.62.28.8.18.19.42.28.74.28.35,0,.67-.12.97-.35v.47c-.27.2-.64.3-1.09.3s-.79-.14-1.04-.42-.38-.68-.38-1.2c0-.49.14-.88.41-1.19s.62-.46,1.02-.46.72.13.95.4c.22.26.34.63.34,1.1v.26Zm-.51-.42c0-.29-.07-.51-.21-.67-.14-.16-.33-.24-.57-.24s-.44.08-.6.25c-.17.17-.27.39-.3.66h1.68Z"
          />
          <path
            className="cls-2"
            d="m478.87,18.09c-.09-.07-.21-.1-.38-.1-.21,0-.39.1-.53.3-.14.2-.21.47-.21.82v1.59h-.5v-3.12h.5v.64h.01c.07-.22.18-.39.33-.51.15-.12.31-.18.49-.18.13,0,.23.01.3.04v.52Z"
          />
          <path
            className="cls-2"
            d="m481.85,20.71h-.7l-1.38-1.5h-.01v1.5h-.5v-4.62h.5v2.93h.01l1.31-1.43h.65l-1.45,1.5,1.56,1.61Z"
          />
          <path
            className="cls-2"
            d="m484.79,20.71h-.7l-1.38-1.5h-.01v1.5h-.5v-4.62h.5v2.93h.01l1.31-1.43h.65l-1.45,1.5,1.56,1.61Z"
          />
          <path
            className="cls-2"
            d="m486.45,20.78c-.46,0-.83-.15-1.1-.44-.28-.29-.41-.68-.41-1.16,0-.52.14-.93.43-1.23.29-.29.67-.44,1.16-.44s.83.14,1.09.43c.26.29.39.68.39,1.19s-.14.9-.42,1.2-.66.45-1.13.45Zm.04-2.84c-.32,0-.57.11-.76.33-.19.22-.28.52-.28.9s.09.66.28.87c.19.21.44.32.76.32s.57-.1.75-.31c.17-.21.26-.51.26-.89s-.09-.69-.26-.9c-.17-.21-.42-.32-.75-.32Z"
          />
          <path
            className="cls-2"
            d="m488.46,20.59v-.54c.27.2.57.3.9.3.44,0,.66-.15.66-.44,0-.08-.02-.15-.06-.21-.04-.06-.09-.11-.15-.15-.06-.04-.14-.08-.22-.12-.09-.04-.18-.07-.28-.11-.14-.05-.26-.11-.36-.17-.11-.06-.19-.12-.26-.19-.07-.07-.12-.15-.16-.24-.04-.09-.05-.19-.05-.31,0-.15.03-.28.1-.39.07-.11.16-.21.27-.28s.24-.13.38-.17c.14-.04.29-.06.44-.06.27,0,.51.05.72.14v.51c-.23-.15-.49-.23-.79-.23-.09,0-.18.01-.25.03-.08.02-.14.05-.19.09-.05.04-.1.08-.12.14-.03.05-.04.11-.04.18,0,.08.01.15.04.2s.07.1.13.15c.06.04.13.08.21.12.08.04.17.07.28.11.14.05.26.11.37.16.11.06.2.12.28.19s.14.15.18.24c.04.09.06.2.06.33,0,.15-.03.29-.1.4-.07.11-.16.21-.27.28-.11.08-.24.13-.39.17-.15.04-.3.05-.47.05-.32,0-.6-.06-.83-.19Z"
          />
          <path
            className="cls-2"
            d="m493.37,20.71h-.5v-.49h-.01c-.22.37-.54.56-.96.56-.31,0-.55-.08-.73-.25-.17-.16-.26-.38-.26-.65,0-.58.34-.92,1.03-1.02l.93-.13c0-.53-.21-.79-.64-.79-.38,0-.71.13-1.02.38v-.51c.31-.2.66-.29,1.06-.29.73,0,1.1.39,1.1,1.16v2.03Zm-.5-1.58l-.75.1c-.23.03-.41.09-.52.17-.12.08-.18.23-.18.44,0,.15.05.28.16.37.11.1.25.14.43.14.25,0,.45-.09.61-.26.16-.17.24-.39.24-.66v-.31Z"
          />
          <path
            className="cls-2"
            d="m496.39,20.71h-.5v-.49h-.01c-.22.37-.54.56-.96.56-.31,0-.55-.08-.73-.25-.17-.16-.26-.38-.26-.65,0-.58.34-.92,1.03-1.02l.93-.13c0-.53-.21-.79-.64-.79-.38,0-.71.13-1.02.38v-.51c.31-.2.66-.29,1.06-.29.73,0,1.1.39,1.1,1.16v2.03Zm-.5-1.58l-.75.1c-.23.03-.41.09-.52.17-.12.08-.18.23-.18.44,0,.15.05.28.16.37.11.1.25.14.43.14.25,0,.45-.09.61-.26.16-.17.24-.39.24-.66v-.31Z"
          />
          <path
            className="cls-2"
            d="m498.8,18.09c-.09-.07-.21-.1-.38-.1-.21,0-.39.1-.53.3-.14.2-.21.47-.21.82v1.59h-.5v-3.12h.5v.64h.01c.07-.22.18-.39.33-.51.15-.12.31-.18.49-.18.13,0,.23.01.3.04v.52Z"
          />
          <path
            className="cls-2"
            d="m501.7,19.27h-2.2c0,.35.1.62.28.8.18.19.42.28.74.28.35,0,.67-.12.97-.35v.47c-.27.2-.64.3-1.09.3s-.79-.14-1.04-.42-.38-.68-.38-1.2c0-.49.14-.88.41-1.19s.62-.46,1.02-.46.72.13.95.4c.22.26.34.63.34,1.1v.26Zm-.51-.42c0-.29-.07-.51-.21-.67-.14-.16-.33-.24-.57-.24s-.44.08-.6.25c-.17.17-.27.39-.3.66h1.68Z"
          />
          <path
            className="cls-2"
            d="m504.88,20.71h-.5v-1.78c0-.66-.24-.99-.72-.99-.25,0-.46.09-.62.28-.16.19-.25.42-.25.71v1.78h-.5v-3.12h.5v.52h.01c.24-.39.58-.59,1.02-.59.34,0,.6.11.78.33s.27.54.27.96v1.91Z"
          />
          <path
            className="cls-2"
            d="m510.54,16.34l-1.62,4.37h-.56l-1.58-4.37h.57l1.21,3.46c.04.11.07.24.09.39h.01c.02-.12.05-.25.1-.39l1.23-3.46h.55Z"
          />
          <path
            className="cls-2"
            d="m513.17,20.71h-.5v-.49h-.01c-.22.37-.54.56-.96.56-.31,0-.55-.08-.73-.25-.17-.16-.26-.38-.26-.65,0-.58.34-.92,1.03-1.02l.93-.13c0-.53-.21-.79-.64-.79-.38,0-.71.13-1.02.38v-.51c.31-.2.66-.29,1.06-.29.73,0,1.1.39,1.1,1.16v2.03Zm-.5-1.58l-.75.1c-.23.03-.41.09-.52.17-.12.08-.18.23-.18.44,0,.15.05.28.16.37.11.1.25.14.43.14.25,0,.45-.09.61-.26.16-.17.24-.39.24-.66v-.31Z"
          />
          <path className="cls-2" d="m514.46,20.71h-.5v-4.62h.5v4.62Z" />
          <path
            className="cls-2"
            d="m516.62,20.78c-.46,0-.83-.15-1.1-.44-.28-.29-.41-.68-.41-1.16,0-.52.14-.93.43-1.23.29-.29.67-.44,1.16-.44s.83.14,1.09.43c.26.29.39.68.39,1.19s-.14.9-.42,1.2-.66.45-1.13.45Zm.04-2.84c-.32,0-.57.11-.76.33-.19.22-.28.52-.28.9s.09.66.28.87c.19.21.44.32.76.32s.57-.1.75-.31c.17-.21.26-.51.26-.89s-.09-.69-.26-.9c-.17-.21-.42-.32-.75-.32Z"
          />
        </g>
      </g>
      <g id="apartments">
        <polygon
          id="general_fill"
          className="cls-9"
          points="26.02 137.87 75.13 31.63 429.23 31.63 401.55 201.93 401.55 201.93 401.34 203.17 325.99 190.92 339.42 108.02 123.97 108.02 94.79 170.94 25.52 138.94 26.02 137.87 26.02 137.87"
        />
        <g
          onClick={() => changeView(7, 'A26_int')}
          onMouseOver={() => setElementOnHover('A26_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A26"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_A26_int'
                ? 'active-apt'
                : elementOnHover === 'A26_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A26_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="80.28 116.12 46.67 100.38 29.5 137.52 93.05 166.93 119.16 110.62 89.22 96.8 80.28 116.12"
          />
        </g>
        <g
          onClick={() => changeView(7, 'A27_int')}
          onMouseOver={() => setElementOnHover('A27_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A27"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_A27_int'
                ? 'active-apt'
                : elementOnHover === 'A27_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A27_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="97.35 76.05 65.16 61.13 64.19 63.23 63.9 63.1 47.23 99.16 79.65 114.35 97.35 76.05"
          />
        </g>
        <g
          onClick={() => changeView(7, 'A28_int')}
          onMouseOver={() => setElementOnHover('A28_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A28"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_A28_int'
                ? 'active-apt'
                : elementOnHover === 'A28_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A28_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="109.34 56.05 109.34 35.26 107.05 35.26 107.07 34.64 77.05 34.64 66.38 57.73 66.67 57.87 65.72 59.92 99.13 75.39 96.94 80.15 109.29 80.15 109.29 70.88 122.39 70.85 122.39 56.05 109.34 56.05"
          />
        </g>
        <g
          onClick={() => changeView(7, 'A29_int')}
          onMouseOver={() => setElementOnHover('A29_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A29"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_A29_int'
                ? 'active-apt'
                : elementOnHover === 'A29_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A29_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="181.64 34.64 112.96 34.64 112.96 35.26 110.68 35.26 110.68 54.71 123.73 54.71 123.73 70.85 139.53 70.88 139.53 71.8 151.68 71.79 151.68 93.51 181.7 93.51 181.64 34.64"
          />
        </g>
        <g
          onClick={() => changeView(7, 'A30_int')}
          onMouseOver={() => setElementOnHover('A30_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A30"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_A30_int'
                ? 'active-apt'
                : elementOnHover === 'A30_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A30_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="182.92 34.64 183.03 104.74 253.95 104.74 253.82 34.64 182.92 34.64"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B72_int')}
          onMouseOver={() => setElementOnHover('B72_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B72"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_B72_int'
                ? 'active-apt'
                : elementOnHover === 'B72_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B72_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="323.98 70.86 323.92 34.64 255.21 34.64 255.27 104.74 322.52 104.74 322.61 94.85 323.98 70.86"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B73_int')}
          onMouseOver={() => setElementOnHover('B73_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B73"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_B73_int'
                ? 'active-apt'
                : elementOnHover === 'B73_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B73_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="341.99 74.48 353.87 74.48 353.87 91.51 366.32 93.54 366.36 74.5 370.22 74.5 370.23 35.26 367.21 35.26 367.22 34.64 325.31 34.64 325.32 70.87 341.99 70.87 341.99 74.48"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B74_int')}
          onMouseOver={() => setElementOnHover('B74_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B74"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_B74_int'
                ? 'active-apt'
                : elementOnHover === 'B74_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B74_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="373.12 34.64 373.12 35.26 371.57 35.26 371.57 75.76 367.69 75.84 367.66 93.76 414.84 101.43 425.7 34.64 373.12 34.64"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B75_int')}
          onMouseOver={() => setElementOnHover('B75_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B75"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_B75_int'
                ? 'active-apt'
                : elementOnHover === 'B75_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B75_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="372.24 140.31 407.26 146 407.99 141.49 408.31 141.54 414.62 102.75 379.28 97.01 372.24 140.31"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B76_int')}
          onMouseOver={() => setElementOnHover('B76_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B76"
            className={`cls-14 ${
              sceneName === 'scene_valo_krs07_B76_int'
                ? 'active-apt'
                : elementOnHover === 'B76_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B76_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="375.6 111.32 343.07 106.03 329.62 188.61 398.83 199.89 407.37 147.38 370.7 141.42 375.6 111.32"
          />
        </g>
      </g>
      <g id="balcony">
        <g
          onClick={() => changeView(7, 'A26_bal')}
          onMouseOver={() => setElementOnHover('A26_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A26-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_A26_bal'
                ? 'active-apt'
                : elementOnHover === 'A26_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A26_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="13.82 132.23 32.03 92.92 44.11 98.79 26.02 137.87 13.82 132.23"
          />
        </g>
        <g
          onClick={() => changeView(7, 'A27_bal')}
          onMouseOver={() => setElementOnHover('A27_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A27-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_A27_bal'
                ? 'active-apt'
                : elementOnHover === 'A27_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A27_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="50.2 53.51 32.03 92.92 44.12 98.72 62.29 59.39 50.2 53.51"
          />
        </g>
        <g
          onClick={() => changeView(7, 'A28_bal')}
          onMouseOver={() => setElementOnHover('A28_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A28-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_A28_bal'
                ? 'active-apt'
                : elementOnHover === 'A28_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A28_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="66.65 18.18 50.2 53.51 62.29 59.39 75.13 31.63 110.01 31.63 110.02 18.18 66.65 18.18"
          />
        </g>
        <g
          onClick={() => changeView(7, 'A29_bal')}
          onMouseOver={() => setElementOnHover('A29_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="A29-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_A29_bal'
                ? 'active-apt'
                : elementOnHover === 'A29_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A29_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            x="110.02"
            y="18.18"
            width="72.26"
            height="13.45"
          />
        </g>
        <g
          onClick={() => changeView(7, 'A30_bal')}
          onMouseOver={() => setElementOnHover('A30_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="A30-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_A30_bal'
                ? 'active-apt'
                : elementOnHover === 'A30_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A30_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            x="182.29"
            y="18.18"
            width="72.25"
            height="13.45"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B72_bal')}
          onMouseOver={() => setElementOnHover('B72_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="B72-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_B72_bal'
                ? 'active-apt'
                : elementOnHover === 'B72_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B72_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            x="254.54"
            y="18.18"
            width="72.28"
            height="13.45"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B73_bal')}
          onMouseOver={() => setElementOnHover('B73_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="B73-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_B73_bal'
                ? 'active-apt'
                : elementOnHover === 'B73_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B73_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            x="326.8"
            y="18.18"
            width="43.36"
            height="13.45"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B74_bal')}
          onMouseOver={() => setElementOnHover('B74_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B74-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_B74_bal'
                ? 'active-apt'
                : elementOnHover === 'B74_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B74_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="445.14 18.16 370.16 18.16 370.16 31.63 429.23 31.63 417.83 102.06 431.04 104.3 445.14 18.16"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B75_bal')}
          onMouseOver={() => setElementOnHover('B75_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B75-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_B75_bal'
                ? 'active-apt'
                : elementOnHover === 'B75_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B75_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="424.08 147.1 431.04 104.3 417.83 102.06 410.89 144.87 424.08 147.1"
          />
        </g>
        <g
          onClick={() => changeView(7, 'B76_bal')}
          onMouseOver={() => setElementOnHover('B76_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B76-bal"
            className={`cls-13 ${
              sceneName === 'scene_valo_krs07_B76_bal'
                ? 'active-apt'
                : elementOnHover === 'B76_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B76_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="414.81 204.16 424.08 147.1 410.89 144.87 401.55 201.93 414.81 204.16"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polygon
          id="shape_126"
          className="cls-12"
          points="75.13 31.63 27.26 135.18 30 136.45 77.05 34.64 425.7 34.64 399.04 198.6 402.01 199.09 429.23 31.63 75.13 31.63"
        />
        <polygon
          id="shape_125"
          className="cls-12"
          points="94.21 164.59 97.18 165.97 123.97 108.02 339.42 108.02 326.91 184.96 327.56 185.07 327.48 185.55 330.07 185.98 343.28 104.74 121.87 104.74 94.21 164.59"
        />
        <polygon
          id="shape_123"
          className="cls-17"
          points="309.4 34.97 309.41 34.64 309.41 31.63 315.32 31.63 315.31 34.64 315.31 34.97 309.4 34.97"
        />
        <polyline
          id="shape_122"
          className="cls-17"
          points="322.61 94.85 322.61 104.73 323.95 104.73 324 94.85 341.99 94.85 341.99 94.84 353.87 94.84 353.87 92.85 366.11 94.84 368.13 95.17 377.99 96.77 376.17 107.95 375.83 110.03 366.11 108.45 365.89 109.75 368.28 110.13 375.6 111.32 370.72 141.33 370.7 141.42 407.04 147.32 407.06 147.24 407.06 147.2 407.04 147.32 407.37 147.38 408.39 147.54 408.66 147.59 409.69 147.75 409.8 147.77 410.12 147.82 410.28 147.85 410.34 147.86 411.28 142.02 411.23 142.01 411.07 141.99 410.75 141.94 410.64 141.92 409.61 141.75 409.34 141.71 408.31 141.54 407.99 141.49 407.25 146.02 407.26 146 372.24 140.31 376.92 111.54 377.47 108.16 379.29 96.94 379.28 97.01 414.62 102.75 414.32 104.58 415.35 104.75 415.62 104.79 416.65 104.96 416.76 104.97 417.08 105.03 417.24 105.05 417.29 105.06 417.75 102.24 417.73 102.37 430.16 104.51 430.23 104.06 417.81 101.92 418.24 99.23 418.19 99.22 418.03 99.19 417.7 99.14 417.59 99.12 416.57 98.95 416.3 98.91 415.27 98.74 414.82 101.51 414.84 101.43 379.5 95.68 378.2 95.47 368.34 93.87 368.24 94.52 368.34 93.87 367.66 93.76 367.69 75.84 371.57 75.84 371.57 75.76 371.57 75.67 371.57 75.24 371.57 75.11 371.57 74.67 371.57 74.59 371.57 74.5 371.56 74.5 371.57 35.26 373.12 35.26 373.12 34.64 371.18 34.64 370.96 34.64 370.83 34.64 373.13 34.64 373.13 33.6 373.13 33.33 373.13 32.29 373.13 32.18 373.13 31.85 373.13 31.68 373.13 31.63 370.37 31.63 370.4 31.63 370.4 19.02 369.94 19.03 369.94 31.63 367.22 31.63 367.22 31.68 367.22 31.85 367.22 32.18 367.22 32.29 367.22 33.33 367.22 33.6 367.22 34.64 370.23 34.64 370.23 34.64 367.21 34.64 367.21 35.26 370.23 35.26 370.22 74.5 366.36 74.5 366.35 74.59 366.32 93.54 366.32 93.54 366.21 94.19 366.32 93.54 353.87 91.51 353.87 74.48 341.99 74.48 341.99 70.87 325.32 70.87 325.31 34.63 323.92 34.64 323.98 70.86 311.74 70.87 311.74 94.84"
        />
        <polygon
          id="shape_121"
          className="cls-17"
          points="280.49 34.96 280.49 34.64 280.5 34.64 280.5 33.6 280.5 33.33 280.5 32.29 280.5 32.18 280.5 31.85 280.5 31.68 280.5 31.63 283.22 31.63 283.68 31.63 286.41 31.63 286.41 31.68 286.41 31.85 286.41 32.18 286.41 32.29 286.41 33.33 286.41 33.6 286.41 34.64 286.4 34.64 286.4 34.96 280.49 34.96"
        />
        <polyline
          id="shape_120"
          className="cls-17"
          points="295.04 104.74 302.32 104.74 302.32 105.92 302.32 107.36 302.82 107.36 302.82 108.02 293.13 108.02 293.13 107.36 293.62 107.36 293.62 105.92 293.62 104.74 293.7 104.74"
        />
        <polygon
          id="shape_119"
          className="cls-17"
          points="410.85 144.71 410.77 145.17 423.2 147.31 423.27 146.85 410.85 144.71"
        />
        <polygon
          id="shape_118"
          className="cls-17"
          points="401.57 201.78 401.5 202.23 413.92 204.37 413.99 203.92 401.57 201.78"
        />
        <polygon
          id="shape_117"
          className="cls-17"
          points="109.34 56.05 109.42 56.05 109.51 56.05 109.94 56.05 110.07 56.05 110.51 56.05 110.59 56.05 110.68 56.05 110.68 54.88 110.68 55.31 110.68 55.44 110.68 55.88 110.68 55.96 110.68 56.05 122.39 56.05 122.39 70.85 122.48 70.85 122.56 70.85 122.99 70.85 123.13 70.85 123.56 70.85 123.64 70.85 123.73 70.85 123.73 54.71 123.64 54.71 110.68 54.71 110.68 35.26 112.96 35.26 112.96 34.64 107.05 34.64 107.05 35.26 109.34 35.26 109.34 56.05"
        />
        <polygon
          id="shape_116"
          className="cls-17"
          points="98.53 33.6 98.53 33.33 98.53 32.29 98.53 32.18 98.53 31.85 98.53 31.68 98.53 31.63 92.61 31.63 92.61 31.68 92.61 31.85 92.61 32.18 92.61 32.29 92.61 33.33 92.61 33.6 92.61 34.64 98.53 34.64 98.53 33.6"
        />
        <polygon
          id="shape_115"
          className="cls-17"
          points="112.98 33.6 112.98 33.33 112.98 32.29 112.98 32.18 112.98 31.85 112.98 31.68 112.98 21.34 109.68 21.34 107.07 21.34 107.07 31.68 107.07 31.85 107.07 32.18 107.07 32.29 107.07 33.33 107.07 33.6 107.07 34.64 112.98 34.64 112.98 33.6"
        />
        <polygon
          id="shape_114"
          className="cls-17"
          points="156.34 33.6 156.34 33.33 156.34 32.29 156.34 32.18 156.34 31.85 156.34 31.68 156.34 31.63 150.43 31.63 150.43 31.68 150.43 31.85 150.43 32.18 150.43 32.29 150.43 33.33 150.43 33.6 150.43 34.64 156.34 34.64 156.34 33.6"
        />
        <rect
          id="shape_113"
          className="cls-17"
          x="164.88"
          y="31.63"
          width="5.91"
          height="3.01"
        />
        <polygon
          id="shape_112"
          className="cls-17"
          points="73.49 35.69 73.79 35.82 73.89 35.87 74.83 36.31 75.08 36.42 76.02 36.86 77.05 34.64 84.07 34.64 84.07 33.6 84.07 33.33 84.07 32.29 84.07 32.18 84.07 31.85 84.07 31.68 84.07 31.63 75.18 31.63 75.13 31.63 73.29 35.6 73.34 35.62 73.49 35.69"
        />
        <polygon
          id="shape_111"
          className="cls-17"
          points="141.88 33.6 141.88 33.33 141.88 32.29 141.88 32.18 141.88 31.85 141.88 31.68 141.88 31.63 135.97 31.63 135.97 31.68 135.97 31.85 135.97 32.18 135.97 32.29 135.97 33.33 135.97 33.6 135.97 34.64 141.88 34.64 141.88 33.6"
        />
        <polygon
          id="shape_110"
          className="cls-17"
          points="127.43 33.6 127.43 33.33 127.43 32.29 127.43 32.18 127.43 31.85 127.43 31.68 127.43 31.63 121.52 31.63 121.52 31.68 121.52 31.85 121.52 32.18 121.52 32.29 121.52 33.33 121.52 33.6 121.52 34.64 127.43 34.64 127.43 33.6"
        />
        <polygon
          id="shape_109"
          className="cls-17"
          points="55.6 75.18 55.7 75.23 56.64 75.67 56.89 75.78 57.83 76.22 60.31 70.85 59.37 70.41 59.12 70.3 58.18 69.86 58.08 69.82 57.78 69.68 57.63 69.61 57.58 69.59 55.1 74.95 55.15 74.98 55.3 75.05 55.6 75.18"
        />
        <polygon
          id="shape_108"
          className="cls-17"
          points="67.43 48.81 67.72 48.94 67.83 48.99 68.77 49.43 69.02 49.54 69.96 49.98 72.44 44.61 71.5 44.18 71.25 44.06 70.31 43.62 70.2 43.58 69.91 43.44 69.76 43.37 69.71 43.35 67.23 48.72 67.28 48.74 67.43 48.81"
        />
        <polygon
          id="shape_107"
          className="cls-17"
          points="31.04 127.52 31.34 127.66 31.44 127.71 32.39 128.14 32.64 128.26 33.58 128.69 36.06 123.33 35.12 122.89 34.87 122.78 33.92 122.34 33.82 122.29 33.52 122.16 33.38 122.09 33.33 122.07 30.85 127.43 30.89 127.45 31.04 127.52"
        />
        <polygon
          id="shape_106"
          className="cls-17"
          points="39.99 109.22 39.89 109.17 39.59 109.04 39.44 108.97 39.39 108.95 36.91 114.31 36.96 114.34 37.11 114.4 37.41 114.54 37.51 114.59 38.45 115.02 38.7 115.14 39.94 115.71 42.42 110.34 41.18 109.77 40.93 109.66 39.99 109.22"
        />
        <polygon
          id="shape_105"
          className="cls-17"
          points="29.5 137.52 30 136.45 29.05 136.01 28.8 135.9 27.86 135.46 27.76 135.41 27.46 135.28 27.31 135.21 27.26 135.18 26.12 137.66 14.62 132.45 14.7 132.27 13.21 131.58 12.93 132.18 14.42 132.87 25.92 138.08 25.52 138.94 25.57 138.97 25.72 139.03 26.02 139.17 26.12 139.22 27.06 139.65 27.31 139.77 28.25 140.21 91.83 169.56 93.05 166.93 29.5 137.52"
        />
        <polygon
          id="shape_104"
          className="cls-17"
          points="49.23 88.17 49.53 88.3 49.63 88.35 50.58 88.79 50.83 88.9 51.77 89.34 54.25 83.97 53.31 83.53 53.06 83.42 52.12 82.98 52.01 82.94 51.72 82.8 51.57 82.73 51.52 82.71 49.04 88.07 49.09 88.1 49.23 88.17"
        />
        <polygon
          id="shape_103"
          className="cls-17"
          points="387.59 33.6 387.59 33.33 387.59 32.29 387.59 32.18 387.59 31.85 387.59 31.68 387.59 31.63 381.67 31.63 381.67 31.68 381.67 31.85 381.67 32.18 381.67 32.29 381.67 33.33 381.67 33.6 381.67 34.64 387.59 34.64 387.59 33.6"
        />
        <polygon
          id="shape_102"
          className="cls-17"
          points="402.04 33.6 402.04 33.33 402.04 32.29 402.04 32.18 402.04 31.85 402.04 31.68 402.04 31.63 396.13 31.63 396.13 31.68 396.13 31.85 396.13 32.18 396.13 32.29 396.13 33.33 396.13 33.6 396.13 34.64 402.04 34.64 402.04 33.6"
        />
        <polygon
          id="shape_101"
          className="cls-17"
          points="344.23 33.6 344.23 33.33 344.23 32.29 344.23 32.18 344.23 31.85 344.23 31.68 344.23 31.63 338.31 31.63 338.31 31.68 338.31 31.85 338.31 32.18 338.31 32.29 338.31 33.33 338.31 33.6 338.31 34.64 344.23 34.64 344.23 33.6"
        />
        <polygon
          id="shape_100"
          className="cls-17"
          points="358.68 33.6 358.68 33.33 358.68 32.29 358.68 32.18 358.68 31.85 358.68 31.68 358.68 31.63 352.77 31.63 352.77 31.68 352.77 31.85 352.77 32.18 352.77 32.29 352.77 33.33 352.77 33.6 352.77 34.64 358.68 34.64 358.68 33.6"
        />
        <polygon
          id="shape_99"
          className="cls-17"
          points="423.71 62.18 424.04 62.23 424.2 62.26 424.25 62.26 425.2 56.43 425.15 56.42 424.99 56.39 424.66 56.34 424.55 56.32 423.53 56.16 423.26 56.11 422.23 55.95 422.23 55.95 421.91 55.89 420.96 61.73 421.28 61.78 422.31 61.95 422.58 61.99 423.6 62.16 423.71 62.18"
        />
        <polygon
          id="shape_98"
          className="cls-17"
          points="423.6 47.52 424.63 47.68 424.9 47.73 425.92 47.89 426.03 47.91 426.36 47.96 426.52 47.99 426.57 48 429.23 31.63 429.18 31.63 410.58 31.63 410.58 31.68 410.58 31.85 410.58 32.18 410.58 32.29 410.58 33.33 410.58 33.6 410.58 34.64 425.7 34.64 423.6 47.52"
        />
        <polygon
          id="shape_97"
          className="cls-17"
          points="421.39 76.44 421.72 76.5 421.88 76.52 421.93 76.53 422.88 70.69 422.83 70.69 422.67 70.66 422.34 70.61 422.23 70.59 421.21 70.42 420.94 70.38 419.91 70.21 418.96 76.05 419.99 76.21 420.26 76.26 421.28 76.42 421.39 76.44"
        />
        <polygon
          id="shape_96"
          className="cls-17"
          points="420.02 84.87 419.91 84.85 418.89 84.69 418.62 84.64 417.59 84.48 417.59 84.48 417.27 84.42 416.32 90.26 416.64 90.31 417.67 90.48 417.94 90.52 418.96 90.69 419.08 90.71 419.4 90.76 419.56 90.79 419.61 90.8 420.56 84.96 420.51 84.95 420.35 84.93 420.02 84.87"
        />
        <rect
          id="shape_95"
          className="cls-17"
          x="193.78"
          y="31.63"
          width="5.91"
          height="3.01"
        />
        <polygon
          id="shape_94"
          className="cls-17"
          points="214.15 33.6 214.15 33.33 214.15 32.29 214.15 32.18 214.15 31.85 214.15 31.68 214.15 31.63 208.24 31.63 208.24 31.68 208.24 31.85 208.24 32.18 208.24 32.29 208.24 33.33 208.24 33.6 208.24 34.64 214.15 34.64 214.15 33.6"
        />
        <polygon
          id="shape_93"
          className="cls-17"
          points="329.77 31.63 327.1 31.63 327.09 19 326.58 19 326.53 31.63 323.86 31.63 323.86 34.64 329.77 34.64 329.77 31.63"
        />
        <polygon
          id="shape_92"
          className="cls-17"
          points="228.6 33.6 228.6 33.33 228.6 32.29 228.6 32.18 228.6 31.85 228.6 31.68 228.6 31.63 225.87 31.63 225.41 31.63 222.69 31.63 222.69 31.68 222.69 31.85 222.69 32.18 222.69 32.29 222.69 33.33 222.69 33.6 222.69 34.64 228.6 34.64 228.6 33.6"
        />
        <polygon
          id="shape_91"
          className="cls-17"
          points="185.24 33.6 185.24 33.33 185.24 32.29 185.24 32.18 185.24 31.85 185.24 31.68 185.24 31.63 182.57 31.62 182.56 19 182 19 182 31.63 179.33 31.63 179.33 31.68 179.33 31.85 179.33 32.18 179.33 32.29 179.33 33.33 179.33 33.6 179.33 34.64 185.24 34.64 185.24 33.6"
        />
        <polygon
          id="shape_90"
          className="cls-17"
          points="300.87 33.6 300.87 33.33 300.87 32.29 300.87 32.18 300.87 31.85 300.87 31.68 300.87 31.63 294.95 31.63 294.95 31.68 294.95 31.85 294.95 32.18 294.95 32.29 294.95 33.33 294.95 33.6 294.95 34.64 300.87 34.64 300.87 33.6"
        />
        <polygon
          id="shape_89"
          className="cls-17"
          points="243.06 33.6 243.06 33.33 243.06 32.29 243.06 32.18 243.06 31.85 243.06 31.68 243.06 31.63 237.14 31.63 237.14 31.68 237.14 31.85 237.14 32.18 237.14 32.29 237.14 33.33 237.14 33.6 237.14 34.64 243.06 34.64 243.06 33.6"
        />
        <polygon
          id="shape_88"
          className="cls-17"
          points="271.96 33.6 271.96 33.33 271.96 32.29 271.96 32.18 271.96 31.85 271.96 31.68 271.96 31.63 266.05 31.63 266.05 31.68 266.05 31.85 266.05 32.18 266.05 32.29 266.05 33.33 266.05 33.6 266.05 34.64 271.96 34.64 271.96 33.6"
        />
        <polygon
          id="shape_87"
          className="cls-17"
          points="143.34 105.92 143.34 104.74 134.64 104.74 134.64 105.92 134.64 107.36 134.14 107.36 134.14 108.02 143.83 108.02 143.83 107.36 143.34 107.36 143.34 105.92"
        />
        <rect
          id="shape_86"
          className="cls-17"
          x="336.35"
          y="133.84"
          width="5.91"
          height="1.77"
          transform="translate(151.88 448.02) rotate(-80.77)"
        />
        <polygon
          id="shape_85"
          className="cls-17"
          points="333.87 142.17 332.31 151.73 332.96 151.84 333.04 151.35 334.47 151.58 335.63 151.77 337.03 143.18 335.86 142.99 334.44 142.76 334.52 142.27 333.87 142.17"
        />
        <polygon
          id="shape_84"
          className="cls-17"
          points="336.19 127.9 334.63 137.46 335.28 137.57 335.36 137.08 336.79 137.32 337.95 137.51 339.35 128.91 338.18 128.72 336.76 128.49 336.84 128.01 336.19 127.9"
        />
        <rect
          id="shape_83"
          className="cls-17"
          x="331.87"
          y="161.05"
          width="5.91"
          height="1.64"
          transform="translate(121.33 466.39) rotate(-80.77)"
        />
        <polygon
          id="shape_82"
          className="cls-17"
          points="316.78 105.92 316.78 104.74 315.38 104.74 315.38 102.96 309.47 102.96 309.47 104.74 308.07 104.74 308.07 105.92 308.07 107.36 307.58 107.36 307.58 108.02 317.27 108.02 317.27 107.36 316.78 107.36 316.78 105.92"
        />
        <polygon
          id="shape_81"
          className="cls-17"
          points="329.23 170.7 327.67 180.26 328.32 180.37 328.4 179.88 329.83 180.11 331 180.3 332.39 171.71 331.22 171.52 329.8 171.29 329.88 170.8 329.23 170.7"
        />
        <polygon
          id="shape_80"
          className="cls-17"
          points="331.55 156.43 329.99 166 330.64 166.1 330.72 165.61 332.15 165.85 333.31 166.04 334.71 157.44 333.54 157.25 332.12 157.02 332.2 156.54 331.55 156.43"
        />
        <polygon
          id="shape_79"
          className="cls-17"
          points="331.23 104.74 322.52 104.74 322.52 105.92 322.52 107.36 322.03 107.36 322.03 108.02 331.72 108.02 331.72 107.36 331.23 107.36 331.23 105.92 331.23 104.74"
        />
        <polygon
          id="shape_78"
          className="cls-17"
          points="404.7 170.24 403.68 170.07 403.35 170.02 402.4 175.85 402.73 175.91 403.75 176.07 404.02 176.12 405.05 176.28 405.16 176.3 405.48 176.36 405.65 176.38 405.7 176.39 406.65 170.55 406.59 170.55 406.43 170.52 406.11 170.47 406 170.45 404.97 170.28 404.7 170.24"
        />
        <polygon
          id="shape_77"
          className="cls-17"
          points="410.63 127.27 409.69 133.11 410.71 133.28 410.98 133.32 412.01 133.49 412.12 133.51 412.44 133.56 412.6 133.58 412.66 133.59 413.6 127.76 413.55 127.75 413.39 127.72 413.07 127.67 412.96 127.65 411.93 127.49 411.66 127.44 410.63 127.27"
        />
        <polygon
          id="shape_76"
          className="cls-17"
          points="328.91 185.79 327.48 185.55 327.56 185.07 326.91 184.96 325.99 190.92 326.66 191.03 328.08 191.26 329.25 191.45 330.07 185.98 328.91 185.79"
        />
        <polygon
          id="shape_75"
          className="cls-17"
          points="401.36 184.34 400.41 190.17 401.43 190.34 401.7 190.38 402.73 190.55 402.84 190.57 403.16 190.62 403.33 190.65 403.38 190.66 404.33 184.82 404.27 184.81 404.11 184.78 403.79 184.73 403.68 184.71 402.65 184.55 402.38 184.5 401.36 184.34"
        />
        <polygon
          id="shape_74"
          className="cls-17"
          points="413.03 119.01 413.3 119.06 414.33 119.22 414.44 119.24 414.76 119.29 414.92 119.32 414.97 119.33 415.92 113.49 415.87 113.48 415.71 113.46 415.39 113.4 415.27 113.39 414.25 113.22 413.98 113.18 412.95 113.01 412.95 113.01 412.63 112.96 411.68 118.79 412 118.84 413.03 119.01"
        />
        <polygon
          id="shape_73"
          className="cls-17"
          points="406.34 161.85 407.37 162.02 407.48 162.04 407.8 162.09 407.96 162.12 408.02 162.12 408.97 156.29 408.91 156.28 408.75 156.25 408.43 156.2 408.32 156.18 407.29 156.02 407.02 155.97 406 155.81 405.05 161.64 406.07 161.81 406.34 161.85"
        />
        <polygon
          id="shape_72"
          className="cls-17"
          points="401.96 199.08 401.79 199.05 401.47 199 401.36 198.98 400.33 198.81 400.06 198.77 399.04 198.6 398.83 199.89 329.67 188.62 329.25 191.45 398.37 202.69 399.4 202.86 399.67 202.9 400.69 203.07 400.8 203.08 401.13 203.14 401.29 203.16 401.34 203.17 402.01 199.09 401.96 199.08"
        />
        <polygon
          id="shape_71"
          className="cls-17"
          points="287.87 105.92 287.87 104.74 286.47 104.74 286.47 102.96 280.56 102.96 280.56 104.74 279.16 104.74 279.16 105.92 279.16 107.36 278.67 107.36 278.67 108.02 288.36 108.02 288.36 107.36 287.87 107.36 287.87 105.92"
        />
        <polygon
          id="shape_70"
          className="cls-17"
          points="258.96 105.92 258.96 104.74 254.64 104.74 255.27 104.74 255.27 104.74 255.26 98.36 255.21 34.64 257.51 34.64 257.51 33.6 257.51 33.33 257.51 32.29 257.51 32.18 257.51 31.85 257.51 31.68 257.51 31.63 254.78 31.63 254.77 19.02 254.31 19.03 254.32 31.63 251.6 31.63 251.6 31.68 251.6 31.85 251.6 32.18 251.6 32.29 251.6 33.33 251.6 33.6 251.6 34.64 254.55 34.64 253.89 34.64 253.95 104.74 250.26 104.74 250.26 105.92 250.26 107.36 249.77 107.36 249.77 108.02 259.46 108.02 259.46 107.36 258.96 107.36 258.96 105.92"
        />
        <polygon
          id="shape_69"
          className="cls-17"
          points="273.42 105.92 273.42 104.74 264.71 104.74 264.71 105.92 264.71 107.36 264.22 107.36 264.22 108.02 273.91 108.02 273.91 107.36 273.42 107.36 273.42 105.92"
        />
        <rect
          id="shape_68"
          className="cls-17"
          x="164.87"
          y="34.64"
          width="5.91"
          height=".62"
        />
        <rect
          id="shape_67"
          className="cls-17"
          x="193.8"
          y="34.64"
          width="5.91"
          height="1.31"
        />
        <polygon
          id="shape_66"
          className="cls-17"
          points="151.68 71.8 150.36 71.8 139.53 71.8 139.53 70.88 109.29 70.88 109.29 80.8 109.29 80.15 96.94 80.15 99.09 75.47 99.12 75.4 65.72 59.92 66.67 57.87 65.43 57.3 65.18 57.18 64.24 56.75 64.14 56.7 63.84 56.56 63.69 56.49 54.29 52.15 53.14 54.63 51.03 53.65 50.84 54.08 52.95 55.06 51.81 57.52 61.21 61.86 61.36 61.93 61.66 62.07 61.76 62.11 62.7 62.55 62.95 62.66 64.19 63.24 65.16 61.14 97.35 76.05 95.46 80.15 95.11 80.91 95.7 81.19 95.11 80.91 88.02 96.25 88.01 96.25 79.08 115.57 80.27 116.12 89.21 96.8 89.21 96.8 100.96 102.23 101.2 101.72 100.96 102.23 119.15 110.62 114.81 120.02 115.88 120.51 117.19 121.12 116.99 121.57 117.58 121.84 123.97 108.02 129.37 108.02 129.37 107.37 128.88 107.37 128.88 105.92 128.88 104.74 127.49 104.74 127.49 102.96 121.57 102.96 105.7 102.96 119.7 109.43 101.51 101.03 89.76 95.61 96.3 81.46 109.29 81.46 109.29 94.86 181.7 94.86 181.7 104.74 183.03 104.74 182.98 34.68 181.64 34.68 181.69 93.51 151.69 93.51 151.68 71.8"
        />
        <polygon
          id="shape_65"
          className="cls-17"
          points="172.25 105.92 172.25 104.74 170.85 104.74 170.85 102.96 164.94 102.96 164.94 104.74 163.54 104.74 163.54 105.92 163.54 107.36 163.05 107.36 163.05 108.02 172.74 108.02 172.74 107.36 172.25 107.36 172.25 105.92"
        />
        <polygon
          id="shape_64"
          className="cls-17"
          points="157.79 105.92 157.79 104.74 153.63 104.74 153.63 103.29 150.34 103.29 150.34 104.74 149.09 104.74 149.09 105.92 149.09 107.36 148.6 107.36 148.6 108.02 158.29 108.02 158.29 107.36 157.79 107.36 157.79 105.92"
        />
        <polygon
          id="shape_63"
          className="cls-17"
          points="102.66 152.57 101.35 151.97 100.27 151.47 96.62 159.37 97.69 159.87 99.01 160.48 98.8 160.92 99.4 161.2 103.46 152.4 102.87 152.13 102.66 152.57"
        />
        <polygon
          id="shape_62"
          className="cls-17"
          points="114.79 126.33 113.47 125.73 112.4 125.23 108.75 133.13 109.82 133.63 111.13 134.24 110.93 134.68 111.52 134.96 115.59 126.16 114.99 125.89 114.79 126.33"
        />
        <polygon
          id="shape_61"
          className="cls-17"
          points="108.72 139.45 107.41 138.85 106.34 138.35 102.68 146.25 103.76 146.75 105.07 147.36 104.86 147.8 105.46 148.08 109.53 139.28 108.93 139.01 108.72 139.45"
        />
        <polygon
          id="shape_60"
          className="cls-17"
          points="201.15 105.92 201.15 104.74 199.76 104.74 199.76 102.96 193.84 102.96 193.84 104.74 192.45 104.74 192.45 106.05 192.45 107.36 191.95 107.36 191.95 108.02 201.64 108.02 201.64 107.36 201.15 107.36 201.15 105.92"
        />
        <polygon
          id="shape_59"
          className="cls-17"
          points="215.6 104.74 215.6 105.92 215.6 107.36 216.1 107.36 216.1 108.02 206.41 108.02 206.41 107.36 206.9 107.36 206.9 105.92 206.9 104.74 215.6 104.74"
        />
        <polygon
          id="shape_58"
          className="cls-17"
          points="186.7 104.74 186.7 105.92 186.7 107.36 187.19 107.36 187.19 108.02 177.5 108.02 177.5 107.36 177.99 107.36 177.99 105.92 177.99 104.74 186.7 104.74"
        />
        <polygon
          id="shape_57"
          className="cls-17"
          points="230.06 105.92 230.06 104.74 228.66 104.74 228.66 102.96 222.75 102.96 222.75 104.74 221.35 104.74 221.35 105.92 221.35 107.36 220.86 107.36 220.86 108.02 230.55 108.02 230.55 107.36 230.06 107.36 230.06 105.92"
        />
        <polygon
          id="shape_56"
          className="cls-17"
          points="244.51 105.92 244.51 104.74 235.81 104.74 235.81 105.92 235.81 107.36 235.31 107.36 235.31 108.02 245 108.02 245 107.36 244.51 107.36 244.51 105.92"
        />
        <polygon
          id="shape_55"
          className="cls-17"
          points="96.59 165.69 95.28 165.09 94.21 164.59 91.83 169.56 92.91 170.06 94.22 170.67 94.79 170.94 97.4 165.52 96.8 165.25 96.59 165.69"
        />
        <polygon
          id="shape_54"
          className="cls-17"
          points="356.9 106.95 343.28 104.74 336.98 104.74 336.98 105.92 336.98 107.36 336.48 107.36 336.48 108.02 339.42 108.02 336.95 123.2 337.6 123.3 337.68 122.82 339.11 123.05 340.27 123.24 342.75 108.02 343.07 106.03 353.57 107.74 352.93 111.7 365.25 113.7 366.11 108.45 364.81 108.24 356.9 106.95"
        />
        <rect
          id="shape_53"
          className="cls-17"
          x="396.13"
          y="34.64"
          width="5.91"
          height=".62"
        />
        <rect
          id="shape_52"
          className="cls-17"
          x="338.31"
          y="34.64"
          width="5.91"
          height=".62"
        />
        <polygon
          id="shape_51"
          className="cls-17"
          points="228.6 34.64 222.69 34.64 222.69 35.26 226.32 35.26 228.6 35.26 228.6 34.64"
        />
        <rect
          id="shape_50"
          className="cls-17"
          x="135.97"
          y="34.64"
          width="5.91"
          height=".62"
        />
        <rect
          id="shape_49"
          className="cls-17"
          x="78.16"
          y="34.64"
          width="5.91"
          height=".62"
        />
        <rect
          id="shape_48"
          className="cls-17"
          x="340.93"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_47"
          className="cls-17"
          x="312.02"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_46"
          className="cls-17"
          x="283.11"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_45"
          className="cls-17"
          x="268.66"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_44"
          className="cls-17"
          x="326.47"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_43"
          className="cls-17"
          x="426.07"
          y="132.01"
          width=".66"
          height="1.64"
          transform="translate(226.87 532.39) rotate(-80.77)"
        />
        <rect
          id="shape_42"
          className="cls-17"
          x="428.39"
          y="117.75"
          width=".66"
          height="1.64"
          transform="translate(242.9 522.71) rotate(-80.77)"
        />
        <rect
          id="shape_41"
          className="cls-17"
          x="297.57"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_40"
          className="cls-17"
          x="442.31"
          y="32.15"
          width=".66"
          height="1.64"
          transform="translate(339.06 464.59) rotate(-80.77)"
        />
        <rect
          id="shape_39"
          className="cls-17"
          x="435.35"
          y="74.95"
          width=".66"
          height="1.64"
          transform="translate(290.98 493.65) rotate(-80.77)"
        />
        <rect
          id="shape_38"
          className="cls-17"
          x="437.67"
          y="60.68"
          width=".66"
          height="1.64"
          transform="translate(307.01 483.96) rotate(-80.77)"
        />
        <rect
          id="shape_37"
          className="cls-17"
          x="398.74"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_36"
          className="cls-17"
          x="423.76"
          y="146.28"
          width=".66"
          height="1.64"
          transform="translate(210.84 542.08) rotate(-80.77)"
        />
        <rect
          id="shape_35"
          className="cls-17"
          x="444.65"
          y="17.66"
          width=".99"
          height=".99"
          transform="translate(426.4 463.27) rotate(-89.92)"
        />
        <rect
          id="shape_34"
          className="cls-17"
          x="439.99"
          y="46.42"
          width=".66"
          height="1.64"
          transform="translate(323.03 474.27) rotate(-80.77)"
        />
        <rect
          id="shape_33"
          className="cls-17"
          x="433.03"
          y="89.21"
          width=".66"
          height="1.64"
          transform="translate(274.95 503.33) rotate(-80.77)"
        />
        <rect
          id="shape_32"
          className="cls-17"
          x="369.83"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_31"
          className="cls-17"
          x="355.38"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_30"
          className="cls-17"
          x="384.29"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_29"
          className="cls-17"
          x="427.64"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_28"
          className="cls-17"
          x="413.19"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_27"
          className="cls-17"
          x="430.71"
          y="103.48"
          width=".66"
          height="1.64"
          transform="translate(258.92 513.02) rotate(-80.77)"
        />
        <rect
          id="shape_26"
          className="cls-17"
          x="416.8"
          y="189.07"
          width=".66"
          height="1.64"
          transform="translate(162.76 571.14) rotate(-80.77)"
        />
        <rect
          id="shape_25"
          className="cls-17"
          x="49.87"
          y="52.69"
          width=".66"
          height="1.64"
          transform="translate(-19.43 76.63) rotate(-65.19)"
        />
        <rect
          id="shape_24"
          className="cls-17"
          x="62"
          y="26.45"
          width=".66"
          height="1.64"
          transform="translate(11.42 72.41) rotate(-65.19)"
        />
        <rect
          id="shape_23"
          className="cls-17"
          x="55.93"
          y="39.57"
          width=".66"
          height="1.64"
          transform="translate(-4.01 74.52) rotate(-65.19)"
        />
        <rect
          id="shape_22"
          className="cls-17"
          x="66.32"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_21"
          className="cls-17"
          x="80.77"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_20"
          className="cls-17"
          x="95.23"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_19"
          className="cls-17"
          x="43.81"
          y="65.81"
          width=".66"
          height="1.64"
          transform="translate(-34.86 78.74) rotate(-65.19)"
        />
        <rect
          id="shape_18"
          className="cls-17"
          x="19.55"
          y="118.29"
          width=".66"
          height="1.64"
          transform="translate(-96.58 87.18) rotate(-65.19)"
        />
        <rect
          id="shape_17"
          className="cls-17"
          x="25.62"
          y="105.17"
          width=".66"
          height="1.64"
          transform="translate(-81.15 85.07) rotate(-65.19)"
        />
        <rect
          id="shape_16"
          className="cls-17"
          x="37.74"
          y="78.93"
          width=".66"
          height="1.64"
          transform="translate(-50.29 80.85) rotate(-65.19)"
        />
        <rect
          id="shape_15"
          className="cls-17"
          x="109.68"
          y="17.36"
          width=".66"
          height="3.97"
        />
        <polygon
          id="shape_14"
          className="cls-17"
          points="32.76 93.19 44.12 98.72 42.97 101.19 43.02 101.22 43.17 101.28 43.47 101.42 43.57 101.47 44.51 101.9 44.76 102.02 45.71 102.46 46.75 100.19 46.67 100.38 79.09 115.57 79.65 114.35 47.23 99.16 48.19 97.09 47.24 96.65 46.99 96.54 46.05 96.1 45.95 96.06 45.65 95.92 45.5 95.85 45.46 95.83 44.31 98.3 32.26 92.44 32.19 92.59 31.4 92.22 31.12 92.82 32.62 93.51 32.76 93.19"
        />
        <rect
          id="shape_13"
          className="cls-17"
          x="153.04"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_12"
          className="cls-17"
          x="210.85"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_11"
          className="cls-17"
          x="421.44"
          y="160.54"
          width=".66"
          height="1.64"
          transform="translate(194.81 551.77) rotate(-80.77)"
        />
        <rect
          id="shape_10"
          className="cls-17"
          x="196.4"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_9"
          className="cls-17"
          x="124.13"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_8"
          className="cls-17"
          x="419.12"
          y="174.81"
          width=".66"
          height="1.64"
          transform="translate(178.77 561.45) rotate(-80.76)"
        />
        <rect
          id="shape_7"
          className="cls-17"
          x="225.3"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_6"
          className="cls-17"
          x="239.76"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_5"
          className="cls-17"
          x="138.58"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_4"
          className="cls-17"
          x="254.21"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_3"
          className="cls-17"
          x="181.94"
          y="17.36"
          width=".66"
          height="1.64"
        />
        <rect
          id="shape_2"
          className="cls-17"
          x="414.48"
          y="203.34"
          width=".66"
          height="1.64"
          transform="translate(146.73 580.83) rotate(-80.77)"
        />
        <rect
          id="shape_1"
          className="cls-17"
          x="167.49"
          y="17.36"
          width=".66"
          height="1.64"
        />
      </g>
      <g id="view">
        {apartmentNumber === 'A26_int' && (
          <g id="A26_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone"
              className="cls-22"
              d="m65.56,125.8c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A26_bal' && (
          <g id="A26_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-2"
              className="cls-1"
              d="m31.14,119.68c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A27_int' && (
          <g id="A27_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-3"
              className="cls-21"
              d="m71.24,82.26c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-3"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A27_bal' && (
          <g id="A27_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-4"
              className="cls-24"
              d="m50.07,80.66c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A28_int' && (
          <g id="A28_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-5"
              className="cls-16"
              d="m98.49,51.02c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-5"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A28_bal' && (
          <g id="A28_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-6"
              className="cls-8"
              d="m73.88,29.51c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-6"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A29_int' && (
          <g id="A29_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-7"
              className="cls-18"
              d="m162.01,66.53c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-7"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A29_bal' && (
          <g id="A29_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-8"
              className="cls-25"
              d="m141.4,29.51c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-8"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A30_int' && (
          <g id="A30_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-9"
              className="cls-20"
              d="m217.41,58.22c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-9"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A30_bal' && (
          <g id="A30_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-10"
              className="cls-11"
              d="m221.46,29.51c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-10"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B72_int' && (
          <g id="B72_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-11"
              className="cls-10"
              d="m291.97,57.54c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-11"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B72_bal' && (
          <g id="B72_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-12"
              className="cls-26"
              d="m293.74,29.51c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-12"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B73_int' && (
          <g id="B73_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-13"
              className="cls-27"
              d="m358.04,57.54c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-13"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B73_bal' && (
          <g id="B73_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-14"
              className="cls-6"
              d="m351.03,29.51c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-14"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B74_int' && (
          <g id="B74_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-15"
              className="cls-4"
              d="m397.28,69.55c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-15"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B74_bal' && (
          <g id="B74_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-16"
              className="cls-15"
              d="m439.81,29.51c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-16"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B75_int' && (
          <g id="B75_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-17"
              className="cls-3"
              d="m402.66,120.09c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-17"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B75_bal' && (
          <g id="B75_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-18"
              className="cls-5"
              d="m424.22,129.19c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-18"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B76_int' && (
          <g id="B76_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-19"
              className="cls-23"
              d="m365.35,152.27c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-19"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B76_bal' && (
          <g id="B76_b" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-20"
              className="cls-7"
              d="m415.46,179.26c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-20"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
      </g>
      <g id="elevator">
        <g id="down" onClick={() => changeFloor('down')}>
          <rect
            id="bg-3"
            className="cls-2"
            x="464.11"
            y="134.64"
            width="35.38"
            height="31.33"
          />
          <polygon
            id="down-2"
            points="472.06 145.31 491.54 145.31 481.8 155.29 472.06 145.31"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <rect
            id="bg-4"
            className="cls-2"
            x="464.11"
            y="45.81"
            width="35.38"
            height="31.33"
          />
          <polygon
            id="up-2"
            points="491.54 65.51 472.06 65.51 481.8 55.54 491.54 65.51"
          />
        </g>
        <path
          id="_7-2"
          d="m487.37,96.96v1.58l-7.69,16.09h-2.47l7.66-15.63v-.05h-8.64v-1.99h11.14Z"
        />
      </g>
      <g id="apartmentnr">
        <g
          id="A26"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'A26_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'A26_int')}
          onMouseOver={() => setElementOnHover('A26_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m72.89,134.81h-1.66l-.48-1.51h-2.41l-.48,1.51h-1.65l2.46-6.77h1.81l2.41,6.77Zm-2.49-2.68l-.73-2.28c-.05-.17-.09-.37-.11-.61h-.04c-.02.2-.06.4-.12.59l-.74,2.29h1.73Z"
          />
          <path
            className="cls-19"
            d="m74.94,133.55v.03h2.75v1.24h-4.46v-1.16l1.94-1.85c.39-.38.66-.69.82-.94.16-.25.24-.52.24-.8,0-.62-.34-.93-1.01-.93-.58,0-1.14.23-1.67.69v-1.31c.59-.38,1.25-.57,1.99-.57.69,0,1.23.17,1.62.52s.58.82.58,1.41c0,.79-.47,1.61-1.42,2.45l-1.39,1.24Z"
          />
          <path
            className="cls-19"
            d="m82.8,128.11v1.25c-.34-.2-.72-.29-1.12-.29-.5,0-.9.19-1.21.57-.31.38-.47.89-.48,1.54h.03c.32-.48.8-.72,1.42-.72.57,0,1.02.19,1.35.58.33.38.5.89.5,1.53,0,.68-.22,1.24-.66,1.69-.44.45-1,.67-1.68.67-.76,0-1.36-.28-1.78-.83-.43-.55-.64-1.32-.64-2.32,0-1.17.27-2.11.83-2.81s1.3-1.05,2.24-1.05c.52,0,.93.06,1.22.18Zm-1.87,3.45c-.27,0-.49.1-.65.29-.16.19-.24.44-.24.74,0,.33.08.61.25.85.17.23.38.35.66.35s.48-.1.64-.31.24-.48.24-.82c0-.73-.3-1.09-.89-1.09Z"
          />
        </g>
        <g
          id="A27"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'A27_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'A27_int')}
          onMouseOver={() => setElementOnHover('A27_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m70.22,91.41h-1.66l-.48-1.51h-2.41l-.48,1.51h-1.65l2.46-6.77h1.81l2.41,6.77Zm-2.49-2.68l-.73-2.28c-.05-.17-.09-.37-.11-.61h-.04c-.02.2-.06.4-.12.59l-.74,2.29h1.73Z"
          />
          <path
            className="cls-19"
            d="m72.27,90.15v.03h2.75v1.24h-4.46v-1.16l1.94-1.85c.39-.38.66-.69.82-.94.16-.25.24-.52.24-.8,0-.62-.34-.93-1.01-.93-.58,0-1.14.23-1.67.69v-1.31c.59-.38,1.25-.57,1.99-.57.69,0,1.23.17,1.62.52s.58.82.58,1.41c0,.79-.47,1.61-1.42,2.45l-1.39,1.24Z"
          />
          <path
            className="cls-19"
            d="m80.53,85.33l-2.39,6.08h-1.58l2.41-5.55h-3.15v-1.22h4.71v.69Z"
          />
        </g>
        <g
          id="A28"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'A28_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'A28_int')}
          onMouseOver={() => setElementOnHover('A28_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m89.55,52.06h-1.66l-.48-1.51h-2.41l-.48,1.51h-1.65l2.46-6.77h1.81l2.41,6.77Zm-2.49-2.68l-.73-2.28c-.05-.17-.09-.37-.11-.61h-.04c-.02.2-.06.4-.12.59l-.74,2.29h1.73Z"
          />
          <path
            className="cls-19"
            d="m91.61,50.79v.03h2.75v1.24h-4.46v-1.16l1.94-1.85c.39-.38.66-.69.82-.94.16-.25.24-.52.24-.8,0-.62-.34-.93-1.01-.93-.58,0-1.14.23-1.67.69v-1.31c.59-.38,1.25-.57,1.99-.57.69,0,1.23.17,1.62.52s.58.82.58,1.41c0,.79-.47,1.61-1.42,2.45l-1.39,1.24Z"
          />
          <path
            className="cls-19"
            d="m96.45,48.54v-.02c-.77-.32-1.16-.83-1.16-1.54,0-.52.21-.95.62-1.29.42-.34.95-.51,1.61-.51s1.21.16,1.6.47.6.72.6,1.22c0,.74-.43,1.28-1.28,1.63v.02c.46.13.82.36,1.07.67.25.31.38.66.38,1.04,0,.6-.22,1.07-.66,1.42-.44.35-1.06.52-1.85.52-.69,0-1.24-.17-1.66-.51-.42-.34-.63-.79-.63-1.34,0-.81.45-1.4,1.36-1.78Zm1,.57c-.63.25-.95.63-.95,1.14,0,.25.1.45.29.61.19.16.43.24.71.24.3,0,.54-.08.72-.24.18-.16.27-.37.27-.62,0-.52-.35-.89-1.04-1.13Zm.88-2.1c0-.23-.07-.41-.22-.55-.15-.14-.34-.21-.58-.21s-.43.07-.59.21c-.16.14-.23.33-.23.55,0,.42.27.74.81.96.54-.22.81-.55.81-.97Z"
          />
        </g>
        <g
          id="A29"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'A29_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'A29_int')}
          onMouseOver={() => setElementOnHover('A29_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m150.02,52.06h-1.66l-.48-1.51h-2.41l-.48,1.51h-1.65l2.46-6.77h1.81l2.41,6.77Zm-2.49-2.68l-.73-2.28c-.05-.17-.09-.37-.11-.61h-.04c-.02.2-.06.4-.12.59l-.74,2.29h1.73Z"
          />
          <path
            className="cls-19"
            d="m152.08,50.79v.03h2.75v1.24h-4.46v-1.16l1.94-1.85c.39-.38.66-.69.82-.94.16-.25.24-.52.24-.8,0-.62-.34-.93-1.01-.93-.58,0-1.14.23-1.67.69v-1.31c.59-.38,1.25-.57,1.99-.57.69,0,1.23.17,1.62.52s.58.82.58,1.41c0,.79-.47,1.61-1.42,2.45l-1.39,1.24Z"
          />
          <path
            className="cls-19"
            d="m155.93,51.91v-1.23c.36.23.78.35,1.28.35s.93-.18,1.21-.53c.29-.35.43-.86.43-1.51h-.03c-.3.43-.75.66-1.36.66-.55,0-1.01-.19-1.38-.58-.36-.39-.54-.89-.54-1.51,0-.72.22-1.3.67-1.73.44-.44,1.02-.65,1.72-.65s1.3.27,1.73.82.64,1.33.64,2.35c0,1.21-.26,2.15-.78,2.82s-1.24,1.01-2.17,1.01c-.55,0-1.03-.09-1.43-.26Zm1.96-5.6c-.26,0-.47.1-.64.31-.17.21-.25.48-.25.81s.08.61.25.81c.17.2.39.29.67.29.26,0,.47-.09.63-.28.16-.19.25-.42.25-.71,0-.36-.09-.65-.26-.89-.17-.24-.39-.35-.65-.35Z"
          />
        </g>
        <g
          id="A30"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'A30_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'A30_int')}
          onMouseOver={() => setElementOnHover('A30_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m216.67,72.92h-1.66l-.48-1.51h-2.41l-.48,1.51h-1.65l2.46-6.77h1.81l2.41,6.77Zm-2.49-2.68l-.73-2.28c-.05-.17-.09-.37-.11-.61h-.04c-.02.2-.06.4-.12.59l-.74,2.29h1.73Z"
          />
          <path
            className="cls-19"
            d="m217.19,72.7v-1.3c.45.33.98.5,1.59.5.38,0,.68-.08.89-.25.21-.16.32-.39.32-.68s-.13-.54-.39-.7c-.26-.16-.62-.25-1.08-.25h-.61v-1.14h.56c.88,0,1.32-.29,1.32-.88,0-.55-.34-.83-1.01-.83-.45,0-.89.15-1.32.44v-1.22c.48-.24,1.03-.36,1.66-.36.69,0,1.23.16,1.62.47.39.31.58.72.58,1.21,0,.88-.45,1.44-1.34,1.66v.02c.48.06.86.23,1.13.52s.42.64.42,1.06c0,.63-.23,1.13-.69,1.5s-1.1.55-1.92.55c-.7,0-1.27-.11-1.7-.34Z"
          />
          <path
            className="cls-19"
            d="m224.56,73.04c-1.61,0-2.42-1.13-2.42-3.39,0-1.17.22-2.07.65-2.68.44-.62,1.07-.92,1.9-.92,1.57,0,2.36,1.15,2.36,3.45,0,1.15-.21,2.02-.64,2.63-.43.61-1.05.92-1.85.92Zm.07-5.86c-.65,0-.97.81-.97,2.43,0,1.53.32,2.29.95,2.29s.92-.79.92-2.36-.3-2.36-.91-2.36Z"
          />
        </g>
        <g
          id="B72"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'B72_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'B72_int')}
          onMouseOver={() => setElementOnHover('B72_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m281.51,72.92v-6.77h2.46c.75,0,1.33.14,1.74.42.41.28.61.67.61,1.17,0,.37-.12.68-.37.96-.25.27-.56.46-.95.57v.02c.48.06.87.24,1.15.53.29.29.43.66.43,1.08,0,.62-.22,1.11-.67,1.48-.44.36-1.05.54-1.82.54h-2.6Zm1.52-5.65v1.61h.67c.31,0,.56-.08.74-.23.18-.15.27-.36.27-.63,0-.5-.37-.75-1.11-.75h-.57Zm0,2.74v1.78h.83c.35,0,.63-.08.83-.25.2-.16.3-.39.3-.67s-.1-.48-.29-.64c-.2-.15-.47-.23-.82-.23h-.83Z"
          />
          <path
            className="cls-19"
            d="m291.85,66.84l-2.39,6.08h-1.58l2.41-5.55h-3.15v-1.22h4.71v.69Z"
          />
          <path
            className="cls-19"
            d="m294.23,71.66v.03h2.75v1.24h-4.46v-1.16l1.94-1.85c.39-.38.67-.69.82-.94.16-.25.24-.52.24-.8,0-.62-.33-.93-1.01-.93-.58,0-1.14.23-1.67.69v-1.31c.59-.38,1.25-.57,1.99-.57.69,0,1.23.17,1.62.52s.58.82.58,1.41c0,.79-.47,1.61-1.42,2.45l-1.39,1.24Z"
          />
        </g>
        <g
          id="B73"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'B73_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'B73_int')}
          onMouseOver={() => setElementOnHover('B73_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m338.47,52.06v-6.77h2.46c.75,0,1.33.14,1.74.42.41.28.61.67.61,1.17,0,.37-.12.68-.37.96-.25.27-.56.46-.95.57v.02c.48.06.87.24,1.15.53.29.29.43.66.43,1.08,0,.62-.22,1.11-.67,1.48-.44.36-1.05.54-1.82.54h-2.6Zm1.52-5.65v1.61h.67c.31,0,.56-.08.74-.23.18-.15.27-.36.27-.63,0-.5-.37-.75-1.11-.75h-.57Zm0,2.74v1.78h.83c.35,0,.63-.08.83-.25.2-.16.3-.39.3-.67s-.1-.48-.29-.64c-.2-.15-.47-.23-.82-.23h-.83Z"
          />
          <path
            className="cls-19"
            d="m348.82,45.98l-2.39,6.08h-1.58l2.41-5.55h-3.15v-1.22h4.71v.69Z"
          />
          <path
            className="cls-19"
            d="m349.67,51.83v-1.3c.45.33.98.5,1.59.5.38,0,.68-.08.89-.25.21-.16.32-.39.32-.68s-.13-.54-.39-.7c-.26-.16-.62-.25-1.08-.25h-.61v-1.14h.56c.88,0,1.32-.29,1.32-.88,0-.55-.34-.83-1.01-.83-.45,0-.89.15-1.32.44v-1.22c.48-.24,1.03-.36,1.66-.36.69,0,1.23.16,1.62.47s.58.72.58,1.21c0,.88-.45,1.44-1.34,1.66v.02c.48.06.86.23,1.13.52.28.29.42.64.42,1.06,0,.63-.23,1.13-.69,1.5-.46.37-1.1.55-1.92.55-.7,0-1.27-.11-1.7-.34Z"
          />
        </g>
        <g
          id="B74"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'B74_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'B74_int')}
          onMouseOver={() => setElementOnHover('B74_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m389.49,66.88v-6.77h2.46c.75,0,1.33.14,1.74.42.41.28.61.67.61,1.17,0,.37-.12.68-.37.96-.25.27-.56.46-.95.57v.02c.48.06.87.24,1.15.53.29.29.43.66.43,1.08,0,.62-.22,1.11-.67,1.48-.44.36-1.05.54-1.82.54h-2.6Zm1.52-5.65v1.61h.67c.31,0,.56-.08.74-.23.18-.15.27-.36.27-.63,0-.5-.37-.75-1.11-.75h-.57Zm0,2.74v1.78h.83c.35,0,.63-.08.83-.25.2-.16.3-.39.3-.67s-.1-.48-.29-.64c-.2-.15-.47-.23-.82-.23h-.83Z"
          />
          <path
            className="cls-19"
            d="m399.84,60.8l-2.39,6.08h-1.58l2.41-5.55h-3.15v-1.22h4.71v.69Z"
          />
          <path
            className="cls-19"
            d="m405.3,65.44h-.81v1.44h-1.36v-1.44h-2.95v-.95l2.84-4.38h1.47v4.27h.81v1.06Zm-2.15-3.84h-.03c-.04.11-.13.29-.25.53l-1.45,2.25h1.71v-2.13c0-.19,0-.4.02-.65Z"
          />
        </g>
        <g
          id="B75"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'B75_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'B75_int')}
          onMouseOver={() => setElementOnHover('B75_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m387.68,123.58v-6.77h2.46c.75,0,1.33.14,1.74.42.41.28.61.67.61,1.17,0,.37-.12.68-.37.96-.25.27-.56.46-.95.57v.02c.48.06.87.24,1.15.53.29.29.43.66.43,1.08,0,.62-.22,1.11-.67,1.48-.44.36-1.05.54-1.82.54h-2.6Zm1.52-5.65v1.61h.67c.31,0,.56-.08.74-.23.18-.15.27-.36.27-.63,0-.5-.37-.75-1.11-.75h-.57Zm0,2.74v1.78h.83c.35,0,.63-.08.83-.25.2-.16.3-.39.3-.67s-.1-.48-.29-.64c-.2-.15-.47-.23-.82-.23h-.83Z"
          />
          <path
            className="cls-19"
            d="m398.02,117.5l-2.39,6.08h-1.58l2.41-5.55h-3.15v-1.22h4.71v.69Z"
          />
          <path
            className="cls-19"
            d="m398.95,123.4v-1.27c.46.28.96.42,1.48.42.39,0,.7-.09.92-.28.22-.19.33-.44.33-.76,0-.67-.47-1-1.42-1-.31,0-.68.03-1.12.08v-3.78h3.75v1.22h-2.47v1.35c.2-.02.38-.03.56-.03.7,0,1.24.18,1.63.55s.59.86.59,1.47c0,.68-.23,1.24-.7,1.67s-1.1.65-1.91.65c-.65,0-1.2-.1-1.64-.29Z"
          />
        </g>
        <g
          id="B76"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'B76_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(7, 'B76_int')}
          onMouseOver={() => setElementOnHover('B76_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-19"
            d="m360.35,169.84v-6.77h2.46c.75,0,1.33.14,1.74.42.41.28.61.67.61,1.17,0,.37-.12.68-.37.96-.25.27-.56.46-.95.57v.02c.48.06.87.24,1.15.53.29.29.43.66.43,1.08,0,.62-.22,1.11-.67,1.48-.44.36-1.05.54-1.82.54h-2.6Zm1.52-5.65v1.61h.67c.31,0,.56-.08.74-.23.18-.15.27-.36.27-.63,0-.5-.37-.75-1.11-.75h-.57Zm0,2.74v1.78h.83c.35,0,.63-.08.83-.25.2-.16.3-.39.3-.67s-.1-.48-.29-.64c-.2-.15-.47-.23-.82-.23h-.83Z"
          />
          <path
            className="cls-19"
            d="m370.7,163.77l-2.39,6.08h-1.58l2.41-5.55h-3.15v-1.22h4.71v.69Z"
          />
          <path
            className="cls-19"
            d="m375.61,163.15v1.25c-.34-.2-.72-.29-1.12-.29-.5,0-.9.19-1.21.57-.31.38-.47.89-.48,1.54h.03c.32-.48.8-.72,1.42-.72.57,0,1.02.19,1.35.58.33.38.5.89.5,1.53,0,.68-.22,1.24-.66,1.69-.44.45-1,.67-1.68.67-.76,0-1.36-.28-1.78-.83-.43-.55-.64-1.32-.64-2.32,0-1.17.28-2.11.83-2.81s1.3-1.05,2.24-1.05c.52,0,.93.06,1.22.18Zm-1.87,3.45c-.27,0-.49.1-.65.29-.16.19-.24.44-.24.74,0,.33.08.61.25.85.17.23.38.35.66.35s.48-.1.64-.31.24-.48.24-.82c0-.73-.3-1.09-.89-1.09Z"
          />
        </g>
      </g>
      <g id="apartment_description">
        {(apartmentNumber === 'A26_int' || apartmentNumber === 'A26_bal') && (
          <g id="A26_text">
            <path
              className="cls-17"
              d="m508.93,58.41h-1.17l-.34-1.06h-1.69l-.34,1.06h-1.16l1.73-4.77h1.27l1.69,4.77Zm-1.75-1.88l-.51-1.6c-.04-.12-.06-.26-.08-.43h-.03c-.01.14-.04.28-.08.42l-.52,1.61h1.22Z"
            />
            <path
              className="cls-17"
              d="m510.37,57.52v.02h1.93v.87h-3.14v-.82l1.37-1.31c.27-.26.47-.48.58-.66.11-.18.17-.37.17-.56,0-.44-.24-.66-.71-.66-.41,0-.8.16-1.18.49v-.92c.41-.27.88-.4,1.4-.4.49,0,.87.12,1.14.37.27.25.41.58.41.99,0,.56-.33,1.13-1,1.72l-.98.87Z"
            />
            <path
              className="cls-17"
              d="m515.9,53.69v.88c-.24-.14-.5-.21-.79-.21-.35,0-.64.13-.85.4-.22.27-.33.63-.34,1.08h.02c.23-.34.56-.51,1-.51.4,0,.71.14.95.41.23.27.35.63.35,1.07,0,.48-.15.88-.46,1.19-.31.32-.7.48-1.18.48-.54,0-.95-.19-1.26-.58-.3-.39-.45-.93-.45-1.63,0-.82.19-1.48.58-1.98.39-.49.91-.74,1.58-.74.37,0,.65.04.86.13Zm-1.32,2.43c-.19,0-.35.07-.46.2-.11.14-.17.31-.17.52,0,.23.06.43.17.6s.27.25.46.25.34-.07.45-.22c.11-.15.17-.34.17-.58,0-.51-.21-.77-.63-.77Z"
            />
            <path
              className="cls-17"
              d="m504.56,65.26v-.5c.28.22.59.33.95.33.28,0,.51-.07.68-.21s.25-.33.25-.58c0-.53-.38-.8-1.14-.8h-.34v-.4h.32c.67,0,1.01-.25,1.01-.75,0-.46-.26-.69-.77-.69-.29,0-.56.1-.81.29v-.46c.26-.16.57-.23.93-.23s.62.09.83.27c.21.18.31.42.31.7,0,.54-.27.88-.82,1.04h0c.29.04.53.14.7.32.17.18.26.4.26.67,0,.37-.13.66-.39.89-.26.23-.61.34-1.04.34-.39,0-.7-.07-.93-.22Z"
            />
            <path className="cls-17" d="m509.15,63.97h-1.56v-.37h1.56v.37Z" />
            <path
              className="cls-17"
              d="m512.32,64.33h-.52v1.08h-.46v-1.08h-1.92v-.3l1.82-2.7h.56v2.62h.52v.38Zm-.99-.38v-1.77c0-.12,0-.27.01-.42h-.01c-.03.07-.08.17-.16.31l-1.26,1.88h1.41Z"
            />
            <path
              className="cls-17"
              d="m515.98,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m519.71,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m523.59,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m526.4,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m529.7,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m530.55,65.25v-.56c.06.06.14.11.23.15.09.04.18.08.28.12s.2.06.3.07c.1.02.19.03.28.03.29,0,.51-.05.66-.16s.22-.27.22-.47c0-.11-.02-.21-.07-.29-.05-.08-.12-.16-.2-.22-.08-.07-.19-.13-.3-.19-.12-.06-.24-.13-.38-.2-.14-.07-.28-.14-.4-.22-.12-.07-.23-.16-.32-.25-.09-.09-.16-.19-.21-.3-.05-.11-.08-.25-.08-.4,0-.19.04-.35.12-.49.08-.14.19-.25.32-.34.13-.09.28-.16.45-.2.17-.04.34-.07.52-.07.4,0,.7.05.88.15v.54c-.24-.17-.55-.25-.93-.25-.1,0-.21.01-.31.03s-.2.06-.28.11c-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29,0,.1.02.19.06.27.04.08.1.14.17.21.08.06.17.12.28.18.11.06.23.12.38.19.15.07.29.15.42.23s.25.17.34.26.18.2.24.32c.06.12.09.25.09.4,0,.2-.04.37-.12.51-.08.14-.19.25-.32.34-.13.09-.29.15-.46.19s-.36.06-.55.06c-.06,0-.14,0-.24-.02s-.19-.03-.29-.05c-.1-.02-.19-.04-.28-.07-.09-.03-.16-.06-.21-.1Z"
            />
            <path
              className="cls-17"
              d="m505.42,79.27v-.02c-.55-.22-.82-.59-.82-1.09,0-.37.15-.67.44-.91.29-.24.67-.36,1.13-.36s.85.11,1.13.33.42.51.42.86c0,.52-.3.9-.9,1.15h0c.32.11.57.27.75.48.18.22.27.46.27.74,0,.42-.16.75-.47,1-.31.24-.75.37-1.3.37-.48,0-.87-.12-1.17-.36s-.44-.55-.44-.95c0-.57.32-.99.96-1.26Zm.7.4c-.45.17-.67.44-.67.8,0,.17.07.32.2.43.14.11.3.17.5.17.21,0,.38-.05.51-.17.13-.11.19-.26.19-.44,0-.36-.25-.63-.73-.8Zm.62-1.48c0-.16-.05-.29-.16-.39-.1-.1-.24-.14-.41-.14s-.3.05-.41.15c-.11.1-.16.23-.16.39,0,.3.19.52.57.67.38-.16.57-.38.57-.68Z"
            />
            <path
              className="cls-17"
              d="m508.42,81.59v-.91c.32.23.69.35,1.12.35.27,0,.48-.06.63-.17s.22-.28.22-.48-.09-.38-.28-.49c-.19-.11-.44-.17-.76-.17h-.43v-.8h.4c.62,0,.93-.21.93-.62,0-.39-.24-.58-.71-.58-.32,0-.63.1-.93.31v-.86c.33-.17.72-.25,1.17-.25.49,0,.87.11,1.14.33.27.22.41.5.41.85,0,.62-.32,1.01-.95,1.17v.02c.34.04.6.16.8.37.2.2.29.45.29.75,0,.45-.16.8-.49,1.06-.33.26-.78.39-1.35.39-.49,0-.89-.08-1.2-.24Z"
            />
            <path
              className="cls-17"
              d="m519.23,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m521.85,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m525.77,96.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m528.66,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,101.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,101l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-17" d="m505.13,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m508.02,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-17"
              d="m511.17,110.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m513.26,110.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m516.08,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m518.99,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m521.97,110.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path className="cls-17" d="m524.2,109.47h-1.56v-.37h1.56v.37Z" />
            <path
              className="cls-17"
              d="m507.08,117.92h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m509.74,115l-1.34,3.38c-.24.6-.58.91-1.01.91-.12,0-.22-.01-.3-.04v-.42c.1.03.19.05.28.05.24,0,.41-.14.53-.42l.23-.55-1.14-2.91h.52l.79,2.25s.03.1.06.22h.02s.03-.12.06-.22l.83-2.25h.48Z"
            />
            <path className="cls-17" d="m510.56,117.92h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m513.45,117.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-17"
              d="m514.01,117.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-17"
              d="m518.6,117.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.42,117.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m523.68,115.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m526.38,116.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m529.37,117.92h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path className="cls-17" d="m531.59,116.47h-1.56v-.37h1.56v.37Z" />
            <path
              className="cls-17"
              d="m507.08,124.92h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m509.5,124.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.73,124.92h-.47v-.5h-.01c-.22.38-.55.56-1,.56-.37,0-.66-.13-.88-.39s-.33-.62-.33-1.07c0-.48.12-.87.36-1.16.24-.29.57-.44.97-.44s.69.16.88.47h.01v-1.81h.47v4.32Zm-.47-1.32v-.43c0-.24-.08-.43-.23-.6-.16-.16-.35-.24-.59-.24-.28,0-.51.1-.67.31-.16.21-.25.5-.25.87,0,.34.08.6.24.8.16.2.37.29.63.29s.47-.09.63-.28c.16-.19.24-.43.24-.71Z"
            />
            <path
              className="cls-17"
              d="m515.89,124.92h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path className="cls-17" d="m517.15,124.92h-.47v-4.32h.47v4.32Z" />
            <path className="cls-17" d="m518.42,124.92h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m521.56,123.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
          </g>
        )}
        {(apartmentNumber === 'A27_int' || apartmentNumber === 'A27_bal') && (
          <g id="A27_text">
            <path
              className="cls-17"
              d="m508.93,58.41h-1.17l-.34-1.06h-1.69l-.34,1.06h-1.16l1.73-4.77h1.27l1.69,4.77Zm-1.75-1.88l-.51-1.6c-.04-.12-.06-.26-.08-.43h-.03c-.01.14-.04.28-.08.42l-.52,1.61h1.22Z"
            />
            <path
              className="cls-17"
              d="m510.37,57.52v.02h1.93v.87h-3.14v-.82l1.37-1.31c.27-.26.47-.48.58-.66.11-.18.17-.37.17-.56,0-.44-.24-.66-.71-.66-.41,0-.8.16-1.18.49v-.92c.41-.27.88-.4,1.4-.4.49,0,.87.12,1.14.37.27.25.41.58.41.99,0,.56-.33,1.13-1,1.72l-.98.87Z"
            />
            <path
              className="cls-17"
              d="m516.19,54.13l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m507,65.41h-2.31v-.41h.92v-3.17l-.95.28v-.44l1.41-.43v3.77h.92v.41Z"
            />
            <path className="cls-17" d="m509.15,63.97h-1.56v-.37h1.56v.37Z" />
            <path
              className="cls-17"
              d="m512.11,65.41h-2.46v-.42l1.19-1.19c.3-.3.52-.56.65-.76s.2-.42.2-.65-.07-.42-.2-.55c-.13-.13-.33-.19-.58-.19-.36,0-.71.16-1.05.47v-.49c.32-.25.69-.37,1.11-.37.37,0,.65.1.86.3.21.2.31.46.31.79,0,.26-.07.52-.22.77-.14.25-.4.56-.78.93l-.94.93h0s1.9.01,1.9.01v.42Z"
            />
            <path
              className="cls-17"
              d="m517.43,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m521.16,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m525.05,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m527.86,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m504.68,81.59v-.91c.32.23.69.35,1.12.35.27,0,.48-.06.63-.17s.22-.28.22-.48-.09-.38-.28-.49c-.19-.11-.44-.17-.76-.17h-.43v-.8h.4c.62,0,.93-.21.93-.62,0-.39-.24-.58-.71-.58-.32,0-.63.1-.93.31v-.86c.33-.17.72-.25,1.17-.25.49,0,.87.11,1.14.33.27.22.41.5.41.85,0,.62-.32,1.01-.95,1.17v.02c.34.04.6.16.8.37.2.2.29.45.29.75,0,.45-.16.8-.49,1.06-.33.26-.78.39-1.35.39-.49,0-.89-.08-1.2-.24Z"
            />
            <path
              className="cls-17"
              d="m508.48,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-17"
              d="m513.12,80.91l-.49,1.68h-.82l.34-1.68h.97Z"
            />
            <path
              className="cls-17"
              d="m513.9,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-17"
              d="m524.65,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m527.27,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m525.77,96.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m528.66,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,101.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,101l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-17" d="m505.13,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m508.02,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-17"
              d="m511.17,110.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m513.26,110.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m516.08,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m518.99,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m521.97,110.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path className="cls-17" d="m524.2,109.47h-1.56v-.37h1.56v.37Z" />
            <path
              className="cls-17"
              d="m507.08,117.92h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m509.74,115l-1.34,3.38c-.24.6-.58.91-1.01.91-.12,0-.22-.01-.3-.04v-.42c.1.03.19.05.28.05.24,0,.41-.14.53-.42l.23-.55-1.14-2.91h.52l.79,2.25s.03.1.06.22h.02s.03-.12.06-.22l.83-2.25h.48Z"
            />
            <path className="cls-17" d="m510.56,117.92h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m513.45,117.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-17"
              d="m514.01,117.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-17"
              d="m518.6,117.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.42,117.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m523.68,115.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m526.38,116.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m529.37,117.92h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path className="cls-17" d="m531.59,116.47h-1.56v-.37h1.56v.37Z" />
            <path
              className="cls-17"
              d="m507.08,124.92h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m509.5,124.92h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.73,124.92h-.47v-.5h-.01c-.22.38-.55.56-1,.56-.37,0-.66-.13-.88-.39s-.33-.62-.33-1.07c0-.48.12-.87.36-1.16.24-.29.57-.44.97-.44s.69.16.88.47h.01v-1.81h.47v4.32Zm-.47-1.32v-.43c0-.24-.08-.43-.23-.6-.16-.16-.35-.24-.59-.24-.28,0-.51.1-.67.31-.16.21-.25.5-.25.87,0,.34.08.6.24.8.16.2.37.29.63.29s.47-.09.63-.28c.16-.19.24-.43.24-.71Z"
            />
            <path
              className="cls-17"
              d="m515.89,124.92h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path className="cls-17" d="m517.15,124.92h-.47v-4.32h.47v4.32Z" />
            <path className="cls-17" d="m518.42,124.92h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m521.56,123.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
          </g>
        )}
        {(apartmentNumber === 'A28_int' || apartmentNumber === 'A28_bal') && (
          <g id="A28_text">
            <path
              className="cls-17"
              d="m508.93,58.41h-1.17l-.34-1.06h-1.69l-.34,1.06h-1.16l1.73-4.77h1.27l1.69,4.77Zm-1.75-1.88l-.51-1.6c-.04-.12-.06-.26-.08-.43h-.03c-.01.14-.04.28-.08.42l-.52,1.61h1.22Z"
            />
            <path
              className="cls-17"
              d="m510.37,57.52v.02h1.93v.87h-3.14v-.82l1.37-1.31c.27-.26.47-.48.58-.66.11-.18.17-.37.17-.56,0-.44-.24-.66-.71-.66-.41,0-.8.16-1.18.49v-.92c.41-.27.88-.4,1.4-.4.49,0,.87.12,1.14.37.27.25.41.58.41.99,0,.56-.33,1.13-1,1.72l-.98.87Z"
            />
            <path
              className="cls-17"
              d="m513.78,55.94v-.02c-.55-.22-.82-.59-.82-1.09,0-.37.15-.67.44-.91.29-.24.67-.36,1.13-.36s.85.11,1.13.33.42.51.42.86c0,.52-.3.9-.9,1.15h0c.32.11.57.27.75.48.18.22.27.46.27.74,0,.42-.16.75-.47,1-.31.24-.75.37-1.3.37-.48,0-.87-.12-1.17-.36s-.44-.55-.44-.95c0-.57.32-.99.96-1.26Zm.7.4c-.45.17-.67.44-.67.8,0,.17.07.32.2.43.14.11.3.17.5.17.21,0,.38-.05.51-.17.13-.11.19-.26.19-.44,0-.36-.25-.63-.73-.8Zm.62-1.48c0-.16-.05-.29-.16-.39-.1-.1-.24-.14-.41-.14s-.3.05-.41.15c-.11.1-.16.23-.16.39,0,.3.19.52.57.67.38-.16.57-.38.57-.68Z"
            />
            <path
              className="cls-17"
              d="m507,65.41h-2.31v-.41h.92v-3.17l-.95.28v-.44l1.41-.43v3.77h.92v.41Z"
            />
            <path
              className="cls-17"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m504.68,80.42v-.91c.32.23.69.35,1.12.35.27,0,.48-.06.63-.17s.22-.28.22-.48-.09-.38-.28-.49c-.19-.11-.44-.17-.76-.17h-.43v-.8h.4c.62,0,.93-.21.93-.62,0-.39-.24-.58-.71-.58-.32,0-.63.1-.93.31v-.86c.33-.17.72-.25,1.17-.25.49,0,.87.11,1.14.33.27.22.41.5.41.85,0,.62-.32,1.01-.95,1.17v.02c.34.04.6.16.8.37.2.2.29.45.29.75,0,.45-.16.8-.49,1.06-.33.26-.78.39-1.35.39-.49,0-.89-.08-1.2-.24Z"
            />
            <path
              className="cls-17"
              d="m509.17,78.1v-.02c-.55-.22-.82-.59-.82-1.09,0-.37.15-.67.44-.91.29-.24.67-.36,1.13-.36s.85.11,1.13.33.42.51.42.86c0,.52-.3.9-.9,1.15h0c.32.11.57.27.75.48.18.22.27.46.27.74,0,.42-.16.75-.47,1-.31.24-.75.37-1.3.37-.48,0-.87-.12-1.17-.36s-.44-.55-.44-.95c0-.57.32-.99.96-1.26Zm.7.4c-.45.17-.67.44-.67.8,0,.17.07.32.2.43.14.11.3.17.5.17.21,0,.38-.05.51-.17.13-.11.19-.26.19-.44,0-.36-.25-.63-.73-.8Zm.62-1.48c0-.16-.05-.29-.16-.39-.1-.1-.24-.14-.41-.14s-.3.05-.41.15c-.11.1-.16.23-.16.39,0,.3.19.52.57.67.38-.16.57-.38.57-.68Z"
            />
            <path
              className="cls-17"
              d="m513.12,79.74l-.49,1.68h-.82l.34-1.68h.97Z"
            />
            <path
              className="cls-17"
              d="m513.9,80.46v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-17"
              d="m524.65,80.58h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m527.27,78.64h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,92.09c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,95.75h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,95.75h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,95.75h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,95.75h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,95.75h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,95.72c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m525.77,95.62c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m528.66,95.75h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,102.33h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,102.75h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,100.3c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,99.83l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,101.41h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,102.75h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,101.41h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-17" d="m505.13,109.75h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m508.02,109.75h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-17"
              d="m511.17,109.75h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m513.26,109.72c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m516.08,108.41h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m518.99,108.41h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m521.97,109.75h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m524.62,109.63c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m527.51,109.75h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path className="cls-17" d="m505.13,116.75h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m508.29,116.75h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m510.31,116.82c-.43,0-.78-.14-1.03-.41-.26-.27-.39-.63-.39-1.08,0-.49.13-.87.4-1.15s.63-.41,1.09-.41.77.13,1.02.4c.24.27.37.64.37,1.11s-.13.84-.39,1.12c-.26.28-.62.42-1.06.42Zm.03-2.66c-.3,0-.54.1-.71.31s-.26.49-.26.84.09.62.26.82.41.3.71.3.53-.1.7-.29.24-.47.24-.83-.08-.65-.24-.84-.39-.3-.7-.3Z"
            />
            <path
              className="cls-17"
              d="m513.71,116.72c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m516.53,115.41h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.44,115.41h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.82,116.64v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-17"
              d="m524.67,115.41h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m527.58,115.41h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m530.56,116.75h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
          </g>
        )}
        {(apartmentNumber === 'A29_int' || apartmentNumber === 'A29_bal') && (
          <g id="A29_text">
            <path
              className="cls-17"
              d="m508.93,58.41h-1.17l-.34-1.06h-1.69l-.34,1.06h-1.16l1.73-4.77h1.27l1.69,4.77Zm-1.75-1.88l-.51-1.6c-.04-.12-.06-.26-.08-.43h-.03c-.01.14-.04.28-.08.42l-.52,1.61h1.22Z"
            />
            <path
              className="cls-17"
              d="m510.37,57.52v.02h1.93v.87h-3.14v-.82l1.37-1.31c.27-.26.47-.48.58-.66.11-.18.17-.37.17-.56,0-.44-.24-.66-.71-.66-.41,0-.8.16-1.18.49v-.92c.41-.27.88-.4,1.4-.4.49,0,.87.12,1.14.37.27.25.41.58.41.99,0,.56-.33,1.13-1,1.72l-.98.87Z"
            />
            <path
              className="cls-17"
              d="m513.08,58.31v-.86c.25.16.55.25.9.25s.65-.12.85-.37c.2-.25.3-.6.3-1.06h-.02c-.21.31-.53.46-.96.46-.39,0-.71-.14-.97-.41-.26-.27-.38-.63-.38-1.06,0-.51.16-.91.47-1.22.31-.31.72-.46,1.21-.46s.92.19,1.22.58c.3.38.45.94.45,1.65,0,.85-.18,1.51-.55,1.99-.36.47-.87.71-1.53.71-.39,0-.72-.06-1.01-.19Zm1.38-3.94c-.18,0-.33.07-.45.22-.12.15-.18.34-.18.57s.06.43.17.57.27.21.47.21c.18,0,.33-.07.45-.2s.17-.3.17-.5c0-.25-.06-.46-.18-.62s-.27-.25-.46-.25Z"
            />
            <path
              className="cls-17"
              d="m504.56,65.26v-.5c.28.22.59.33.95.33.28,0,.51-.07.68-.21s.25-.33.25-.58c0-.53-.38-.8-1.14-.8h-.34v-.4h.32c.67,0,1.01-.25,1.01-.75,0-.46-.26-.69-.77-.69-.29,0-.56.1-.81.29v-.46c.26-.16.57-.23.93-.23s.62.09.83.27c.21.18.31.42.31.7,0,.54-.27.88-.82,1.04h0c.29.04.53.14.7.32.17.18.26.4.26.67,0,.37-.13.66-.39.89-.26.23-.61.34-1.04.34-.39,0-.7-.07-.93-.22Z"
            />
            <path
              className="cls-17"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m507.83,77.47l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m511.51,81.75h-3.01v-.83h.99v-2.97l-1.01.22v-.85l2.06-.42v4.01h.98v.83Z"
            />
            <path
              className="cls-17"
              d="m513.12,80.91l-.49,1.68h-.82l.34-1.68h.97Z"
            />
            <path
              className="cls-17"
              d="m513.9,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-17"
              d="m524.65,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m527.27,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m524.32,96.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m527.21,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,101.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,101l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-17" d="m505.13,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m508.29,110.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m510.31,110.98c-.43,0-.78-.14-1.03-.41-.26-.27-.39-.63-.39-1.08,0-.49.13-.87.4-1.15s.63-.41,1.09-.41.77.13,1.02.4c.24.27.37.64.37,1.11s-.13.84-.39,1.12c-.26.28-.62.42-1.06.42Zm.03-2.66c-.3,0-.54.1-.71.31s-.26.49-.26.84.09.62.26.82.41.3.71.3.53-.1.7-.29.24-.47.24-.83-.08-.65-.24-.84-.39-.3-.7-.3Z"
            />
            <path
              className="cls-17"
              d="m513.71,110.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m516.53,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.44,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.82,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-17"
              d="m524.67,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m527.58,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m530.56,110.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
          </g>
        )}
        {(apartmentNumber === 'A30_int' || apartmentNumber === 'A30_bal') && (
          <g id="A30_text">
            <path
              className="cls-17"
              d="m508.93,58.41h-1.17l-.34-1.06h-1.69l-.34,1.06h-1.16l1.73-4.77h1.27l1.69,4.77Zm-1.75-1.88l-.51-1.6c-.04-.12-.06-.26-.08-.43h-.03c-.01.14-.04.28-.08.42l-.52,1.61h1.22Z"
            />
            <path
              className="cls-17"
              d="m509.29,58.25v-.91c.32.23.69.35,1.12.35.27,0,.48-.06.63-.17s.22-.28.22-.48-.09-.38-.28-.49c-.19-.11-.44-.17-.76-.17h-.43v-.8h.4c.62,0,.93-.21.93-.62,0-.39-.24-.58-.71-.58-.32,0-.63.1-.93.31v-.86c.33-.17.72-.25,1.17-.25.49,0,.87.11,1.14.33.27.22.41.5.41.85,0,.62-.32,1.01-.95,1.17v.02c.34.04.6.16.8.37.2.2.29.45.29.75,0,.45-.16.8-.49,1.06-.33.26-.78.39-1.35.39-.49,0-.89-.08-1.2-.24Z"
            />
            <path
              className="cls-17"
              d="m514.48,58.49c-1.13,0-1.7-.8-1.7-2.39,0-.83.15-1.46.46-1.89.31-.43.75-.65,1.33-.65,1.11,0,1.66.81,1.66,2.43,0,.81-.15,1.42-.45,1.85s-.74.64-1.3.64Zm.05-4.13c-.45,0-.68.57-.68,1.71,0,1.07.22,1.61.67,1.61s.65-.55.65-1.66-.21-1.66-.64-1.66Z"
            />
            <path
              className="cls-17"
              d="m507.14,64.33h-.52v1.08h-.46v-1.08h-1.92v-.3l1.82-2.7h.56v2.62h.52v.38Zm-.99-.38v-1.77c0-.12,0-.27.01-.42h-.01c-.03.07-.08.17-.16.31l-1.26,1.88h1.41Z"
            />
            <path
              className="cls-17"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m524.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m525.37,65.25v-.56c.06.06.14.11.23.15.09.04.18.08.28.12s.2.06.3.07c.1.02.19.03.28.03.29,0,.51-.05.66-.16s.22-.27.22-.47c0-.11-.02-.21-.07-.29-.05-.08-.12-.16-.2-.22-.08-.07-.19-.13-.3-.19-.12-.06-.24-.13-.38-.2-.14-.07-.28-.14-.4-.22-.12-.07-.23-.16-.32-.25-.09-.09-.16-.19-.21-.3-.05-.11-.08-.25-.08-.4,0-.19.04-.35.12-.49.08-.14.19-.25.32-.34.13-.09.28-.16.45-.2.17-.04.34-.07.52-.07.4,0,.7.05.88.15v.54c-.24-.17-.55-.25-.93-.25-.1,0-.21.01-.31.03s-.2.06-.28.11c-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29,0,.1.02.19.06.27.04.08.1.14.17.21.08.06.17.12.28.18.11.06.23.12.38.19.15.07.29.15.42.23s.25.17.34.26.18.2.24.32c.06.12.09.25.09.4,0,.2-.04.37-.12.51-.08.14-.19.25-.32.34-.13.09-.29.15-.46.19s-.36.06-.55.06c-.06,0-.14,0-.24-.02s-.19-.03-.29-.05c-.1-.02-.19-.04-.28-.07-.09-.03-.16-.06-.21-.1Z"
            />
            <path
              className="cls-17"
              d="m507.77,81.75h-3.01v-.83h.99v-2.97l-1.01.22v-.85l2.06-.42v4.01h.98v.83Z"
            />
            <path
              className="cls-17"
              d="m511.51,81.75h-3.01v-.83h.99v-2.97l-1.01.22v-.85l2.06-.42v4.01h.98v.83Z"
            />
            <path
              className="cls-17"
              d="m512.22,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-17"
              d="m522.98,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m525.59,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m524.32,96.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m527.21,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,101.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,101l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-17" d="m505.13,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m508.29,110.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m510.31,110.98c-.43,0-.78-.14-1.03-.41-.26-.27-.39-.63-.39-1.08,0-.49.13-.87.4-1.15s.63-.41,1.09-.41.77.13,1.02.4c.24.27.37.64.37,1.11s-.13.84-.39,1.12c-.26.28-.62.42-1.06.42Zm.03-2.66c-.3,0-.54.1-.71.31s-.26.49-.26.84.09.62.26.82.41.3.71.3.53-.1.7-.29.24-.47.24-.83-.08-.65-.24-.84-.39-.3-.7-.3Z"
            />
            <path
              className="cls-17"
              d="m513.71,110.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m516.53,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.44,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.82,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-17"
              d="m524.67,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m527.58,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m530.56,110.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
          </g>
        )}
        {(apartmentNumber === 'B72_int' || apartmentNumber === 'B72_bal') && (
          <g id="B72_text">
            <path
              className="cls-17"
              d="m504.73,58.41v-4.77h1.73c.53,0,.94.1,1.23.29.29.19.43.47.43.82,0,.26-.09.48-.26.67-.17.19-.4.33-.67.4h0c.34.06.61.18.81.39.2.21.3.46.3.76,0,.44-.16.78-.47,1.04s-.74.38-1.28.38h-1.83Zm1.07-3.97v1.13h.47c.22,0,.4-.05.52-.16.13-.11.19-.25.19-.44,0-.35-.26-.53-.78-.53h-.4Zm0,1.93v1.26h.58c.25,0,.44-.06.58-.17s.21-.27.21-.47-.07-.34-.21-.45c-.14-.11-.33-.16-.58-.16h-.59Z"
            />
            <path
              className="cls-17"
              d="m512.02,54.13l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m513.69,57.52v.02h1.93v.87h-3.14v-.82l1.37-1.31c.27-.26.47-.48.58-.66.11-.18.17-.37.17-.56,0-.44-.24-.66-.71-.66-.41,0-.8.16-1.18.49v-.92c.41-.27.88-.4,1.4-.4.49,0,.87.12,1.14.37.27.25.41.58.41.99,0,.56-.33,1.13-1,1.72l-.98.87Z"
            />
            <path
              className="cls-17"
              d="m507.14,64.33h-.52v1.08h-.46v-1.08h-1.92v-.3l1.82-2.7h.56v2.62h.52v.38Zm-.99-.38v-1.77c0-.12,0-.27.01-.42h-.01c-.03.07-.08.17-.16.31l-1.26,1.88h1.41Z"
            />
            <path
              className="cls-17"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m524.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m525.37,65.25v-.56c.06.06.14.11.23.15.09.04.18.08.28.12s.2.06.3.07c.1.02.19.03.28.03.29,0,.51-.05.66-.16s.22-.27.22-.47c0-.11-.02-.21-.07-.29-.05-.08-.12-.16-.2-.22-.08-.07-.19-.13-.3-.19-.12-.06-.24-.13-.38-.2-.14-.07-.28-.14-.4-.22-.12-.07-.23-.16-.32-.25-.09-.09-.16-.19-.21-.3-.05-.11-.08-.25-.08-.4,0-.19.04-.35.12-.49.08-.14.19-.25.32-.34.13-.09.28-.16.45-.2.17-.04.34-.07.52-.07.4,0,.7.05.88.15v.54c-.24-.17-.55-.25-.93-.25-.1,0-.21.01-.31.03s-.2.06-.28.11c-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29,0,.1.02.19.06.27.04.08.1.14.17.21.08.06.17.12.28.18.11.06.23.12.38.19.15.07.29.15.42.23s.25.17.34.26.18.2.24.32c.06.12.09.25.09.4,0,.2-.04.37-.12.51-.08.14-.19.25-.32.34-.13.09-.29.15-.46.19s-.36.06-.55.06c-.06,0-.14,0-.24-.02s-.19-.03-.29-.05c-.1-.02-.19-.04-.28-.07-.09-.03-.16-.06-.21-.1Z"
            />
            <path
              className="cls-17"
              d="m507.83,77.47l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m508.48,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-17"
              d="m519.23,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m521.85,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m525.77,96.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m528.66,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,101.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,101l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-17" d="m505.13,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m508.29,110.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m510.31,110.98c-.43,0-.78-.14-1.03-.41-.26-.27-.39-.63-.39-1.08,0-.49.13-.87.4-1.15s.63-.41,1.09-.41.77.13,1.02.4c.24.27.37.64.37,1.11s-.13.84-.39,1.12c-.26.28-.62.42-1.06.42Zm.03-2.66c-.3,0-.54.1-.71.31s-.26.49-.26.84.09.62.26.82.41.3.71.3.53-.1.7-.29.24-.47.24-.83-.08-.65-.24-.84-.39-.3-.7-.3Z"
            />
            <path
              className="cls-17"
              d="m513.71,110.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m516.53,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.44,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.82,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-17"
              d="m524.67,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m527.58,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m530.56,110.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
          </g>
        )}
        {(apartmentNumber === 'B73_int' || apartmentNumber === 'B73_bal') && (
          <g id="B73_text">
            <path
              className="cls-17"
              d="m504.73,58.41v-4.77h1.73c.53,0,.94.1,1.23.29.29.19.43.47.43.82,0,.26-.09.48-.26.67-.17.19-.4.33-.67.4h0c.34.06.61.18.81.39.2.21.3.46.3.76,0,.44-.16.78-.47,1.04s-.74.38-1.28.38h-1.83Zm1.07-3.97v1.13h.47c.22,0,.4-.05.52-.16.13-.11.19-.25.19-.44,0-.35-.26-.53-.78-.53h-.4Zm0,1.93v1.26h.58c.25,0,.44-.06.58-.17s.21-.27.21-.47-.07-.34-.21-.45c-.14-.11-.33-.16-.58-.16h-.59Z"
            />
            <path
              className="cls-17"
              d="m512.02,54.13l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m512.62,58.25v-.91c.32.23.69.35,1.12.35.27,0,.48-.06.63-.17s.22-.28.22-.48-.09-.38-.28-.49c-.19-.11-.44-.17-.76-.17h-.43v-.8h.4c.62,0,.93-.21.93-.62,0-.39-.24-.58-.71-.58-.32,0-.63.1-.93.31v-.86c.33-.17.72-.25,1.17-.25.49,0,.87.11,1.14.33.27.22.41.5.41.85,0,.62-.32,1.01-.95,1.17v.02c.34.04.6.16.8.37.2.2.29.45.29.75,0,.45-.16.8-.49,1.06-.33.26-.78.39-1.35.39-.49,0-.89-.08-1.2-.24Z"
            />
            <path
              className="cls-17"
              d="m506.92,65.41h-2.46v-.42l1.19-1.19c.3-.3.52-.56.65-.76s.2-.42.2-.65-.07-.42-.2-.55c-.13-.13-.33-.19-.58-.19-.36,0-.71.16-1.05.47v-.49c.32-.25.69-.37,1.11-.37.37,0,.65.1.86.3.21.2.31.46.31.79,0,.26-.07.52-.22.77-.14.25-.4.56-.78.93l-.94.93h0s1.9.01,1.9.01v.42Z"
            />
            <path
              className="cls-17"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m524.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m525.37,65.25v-.56c.06.06.14.11.23.15.09.04.18.08.28.12s.2.06.3.07c.1.02.19.03.28.03.29,0,.51-.05.66-.16s.22-.27.22-.47c0-.11-.02-.21-.07-.29-.05-.08-.12-.16-.2-.22-.08-.07-.19-.13-.3-.19-.12-.06-.24-.13-.38-.2-.14-.07-.28-.14-.4-.22-.12-.07-.23-.16-.32-.25-.09-.09-.16-.19-.21-.3-.05-.11-.08-.25-.08-.4,0-.19.04-.35.12-.49.08-.14.19-.25.32-.34.13-.09.28-.16.45-.2.17-.04.34-.07.52-.07.4,0,.7.05.88.15v.54c-.24-.17-.55-.25-.93-.25-.1,0-.21.01-.31.03s-.2.06-.28.11c-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29,0,.1.02.19.06.27.04.08.1.14.17.21.08.06.17.12.28.18.11.06.23.12.38.19.15.07.29.15.42.23s.25.17.34.26.18.2.24.32c.06.12.09.25.09.4,0,.2-.04.37-.12.51-.08.14-.19.25-.32.34-.13.09-.29.15-.46.19s-.36.06-.55.06c-.06,0-.14,0-.24-.02s-.19-.03-.29-.05c-.1-.02-.19-.04-.28-.07-.09-.03-.16-.06-.21-.1Z"
            />
            <path
              className="cls-17"
              d="m507.93,80.73h-.57v1.01h-.96v-1.01h-2.08v-.67l2-3.08h1.03v3h.57v.75Zm-1.52-2.7h-.02c-.03.08-.09.2-.18.37l-1.02,1.58h1.21v-1.5c0-.13,0-.29.01-.46Z"
            />
            <path
              className="cls-17"
              d="m508.48,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-17"
              d="m519.23,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m521.85,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m525.77,96.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m528.66,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,101.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,101l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-17" d="m505.13,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m508.29,110.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m510.31,110.98c-.43,0-.78-.14-1.03-.41-.26-.27-.39-.63-.39-1.08,0-.49.13-.87.4-1.15s.63-.41,1.09-.41.77.13,1.02.4c.24.27.37.64.37,1.11s-.13.84-.39,1.12c-.26.28-.62.42-1.06.42Zm.03-2.66c-.3,0-.54.1-.71.31s-.26.49-.26.84.09.62.26.82.41.3.71.3.53-.1.7-.29.24-.47.24-.83-.08-.65-.24-.84-.39-.3-.7-.3Z"
            />
            <path
              className="cls-17"
              d="m513.71,110.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m516.53,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.44,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.82,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-17"
              d="m524.67,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m527.58,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m530.56,110.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
          </g>
        )}
        {(apartmentNumber === 'B74_int' || apartmentNumber === 'B74_bal') && (
          <g id="B74_text">
            <path
              className="cls-17"
              d="m504.73,58.41v-4.77h1.73c.53,0,.94.1,1.23.29.29.19.43.47.43.82,0,.26-.09.48-.26.67-.17.19-.4.33-.67.4h0c.34.06.61.18.81.39.2.21.3.46.3.76,0,.44-.16.78-.47,1.04s-.74.38-1.28.38h-1.83Zm1.07-3.97v1.13h.47c.22,0,.4-.05.52-.16.13-.11.19-.25.19-.44,0-.35-.26-.53-.78-.53h-.4Zm0,1.93v1.26h.58c.25,0,.44-.06.58-.17s.21-.27.21-.47-.07-.34-.21-.45c-.14-.11-.33-.16-.58-.16h-.59Z"
            />
            <path
              className="cls-17"
              d="m512.02,54.13l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m515.86,57.4h-.57v1.01h-.96v-1.01h-2.08v-.67l2-3.08h1.03v3h.57v.75Zm-1.52-2.7h-.02c-.03.08-.09.2-.18.37l-1.02,1.58h1.21v-1.5c0-.13,0-.29.01-.46Z"
            />
            <path
              className="cls-17"
              d="m504.56,65.26v-.5c.28.22.59.33.95.33.28,0,.51-.07.68-.21s.25-.33.25-.58c0-.53-.38-.8-1.14-.8h-.34v-.4h.32c.67,0,1.01-.25,1.01-.75,0-.46-.26-.69-.77-.69-.29,0-.56.1-.81.29v-.46c.26-.16.57-.23.93-.23s.62.09.83.27c.21.18.31.42.31.7,0,.54-.27.88-.82,1.04h0c.29.04.53.14.7.32.17.18.26.4.26.67,0,.37-.13.66-.39.89-.26.23-.61.34-1.04.34-.39,0-.7-.07-.93-.22Z"
            />
            <path
              className="cls-17"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m507.83,77.47l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m508.48,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-17"
              d="m519.23,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m521.85,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m524.32,96.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m527.21,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,101.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,101l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-17" d="m505.13,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-17"
              d="m508.29,110.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m510.31,110.98c-.43,0-.78-.14-1.03-.41-.26-.27-.39-.63-.39-1.08,0-.49.13-.87.4-1.15s.63-.41,1.09-.41.77.13,1.02.4c.24.27.37.64.37,1.11s-.13.84-.39,1.12c-.26.28-.62.42-1.06.42Zm.03-2.66c-.3,0-.54.1-.71.31s-.26.49-.26.84.09.62.26.82.41.3.71.3.53-.1.7-.29.24-.47.24-.83-.08-.65-.24-.84-.39-.3-.7-.3Z"
            />
            <path
              className="cls-17"
              d="m513.71,110.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m516.53,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.44,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m519.82,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-17"
              d="m524.67,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m527.58,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m530.56,110.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
          </g>
        )}
        {(apartmentNumber === 'B75_int' || apartmentNumber === 'B75_bal') && (
          <g id="B75_text">
            <path
              className="cls-17"
              d="m504.73,58.41v-4.77h1.73c.53,0,.94.1,1.23.29.29.19.43.47.43.82,0,.26-.09.48-.26.67-.17.19-.4.33-.67.4h0c.34.06.61.18.81.39.2.21.3.46.3.76,0,.44-.16.78-.47,1.04s-.74.38-1.28.38h-1.83Zm1.07-3.97v1.13h.47c.22,0,.4-.05.52-.16.13-.11.19-.25.19-.44,0-.35-.26-.53-.78-.53h-.4Zm0,1.93v1.26h.58c.25,0,.44-.06.58-.17s.21-.27.21-.47-.07-.34-.21-.45c-.14-.11-.33-.16-.58-.16h-.59Z"
            />
            <path
              className="cls-17"
              d="m512.02,54.13l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m512.67,58.29v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-17"
              d="m506.92,65.41h-2.46v-.42l1.19-1.19c.3-.3.52-.56.65-.76s.2-.42.2-.65-.07-.42-.2-.55c-.13-.13-.33-.19-.58-.19-.36,0-.71.16-1.05.47v-.49c.32-.25.69-.37,1.11-.37.37,0,.65.1.86.3.21.2.31.46.31.79,0,.26-.07.52-.22.77-.14.25-.4.56-.78.93l-.94.93h0s1.9.01,1.9.01v.42Z"
            />
            <path
              className="cls-17"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m504.68,81.59v-.91c.32.23.69.35,1.12.35.27,0,.48-.06.63-.17s.22-.28.22-.48-.09-.38-.28-.49c-.19-.11-.44-.17-.76-.17h-.43v-.8h.4c.62,0,.93-.21.93-.62,0-.39-.24-.58-.71-.58-.32,0-.63.1-.93.31v-.86c.33-.17.72-.25,1.17-.25.49,0,.87.11,1.14.33.27.22.41.5.41.85,0,.62-.32,1.01-.95,1.17v.02c.34.04.6.16.8.37.2.2.29.45.29.75,0,.45-.16.8-.49,1.06-.33.26-.78.39-1.35.39-.49,0-.89-.08-1.2-.24Z"
            />
            <path
              className="cls-17"
              d="m511.57,77.47l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m519.23,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m521.85,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m524.32,96.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m527.21,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,101.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,101l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m525.55,100.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m527.93,103.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m530.5,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-17"
              d="m533.32,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-17"
              d="m536.48,103.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
          </g>
        )}
        {(apartmentNumber === 'B76_int' || apartmentNumber === 'B76_bal') && (
          <g id="B76_text">
            <path
              className="cls-17"
              d="m504.73,58.41v-4.77h1.73c.53,0,.94.1,1.23.29.29.19.43.47.43.82,0,.26-.09.48-.26.67-.17.19-.4.33-.67.4h0c.34.06.61.18.81.39.2.21.3.46.3.76,0,.44-.16.78-.47,1.04s-.74.38-1.28.38h-1.83Zm1.07-3.97v1.13h.47c.22,0,.4-.05.52-.16.13-.11.19-.25.19-.44,0-.35-.26-.53-.78-.53h-.4Zm0,1.93v1.26h.58c.25,0,.44-.06.58-.17s.21-.27.21-.47-.07-.34-.21-.45c-.14-.11-.33-.16-.58-.16h-.59Z"
            />
            <path
              className="cls-17"
              d="m512.02,54.13l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-17"
              d="m515.48,53.69v.88c-.24-.14-.5-.21-.79-.21-.35,0-.64.13-.85.4-.22.27-.33.63-.34,1.08h.02c.23-.34.56-.51,1-.51.4,0,.71.14.95.41.23.27.35.63.35,1.07,0,.48-.15.88-.46,1.19-.31.32-.7.48-1.18.48-.54,0-.95-.19-1.26-.58-.3-.39-.45-.93-.45-1.63,0-.82.19-1.48.58-1.98.39-.49.91-.74,1.58-.74.37,0,.65.04.86.13Zm-1.32,2.43c-.19,0-.35.07-.46.2-.11.14-.17.31-.17.52,0,.23.06.43.17.6s.27.25.46.25.34-.07.45-.22c.11-.15.17-.34.17-.58,0-.51-.21-.77-.63-.77Z"
            />
            <path
              className="cls-17"
              d="m507.14,64.33h-.52v1.08h-.46v-1.08h-1.92v-.3l1.82-2.7h.56v2.62h.52v.38Zm-.99-.38v-1.77c0-.12,0-.27.01-.42h-.01c-.03.07-.08.17-.16.31l-1.26,1.88h1.41Z"
            />
            <path
              className="cls-17"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-17"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-17"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-17"
              d="m524.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-17"
              d="m525.37,65.25v-.56c.06.06.14.11.23.15.09.04.18.08.28.12s.2.06.3.07c.1.02.19.03.28.03.29,0,.51-.05.66-.16s.22-.27.22-.47c0-.11-.02-.21-.07-.29-.05-.08-.12-.16-.2-.22-.08-.07-.19-.13-.3-.19-.12-.06-.24-.13-.38-.2-.14-.07-.28-.14-.4-.22-.12-.07-.23-.16-.32-.25-.09-.09-.16-.19-.21-.3-.05-.11-.08-.25-.08-.4,0-.19.04-.35.12-.49.08-.14.19-.25.32-.34.13-.09.28-.16.45-.2.17-.04.34-.07.52-.07.4,0,.7.05.88.15v.54c-.24-.17-.55-.25-.93-.25-.1,0-.21.01-.31.03s-.2.06-.28.11c-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29,0,.1.02.19.06.27.04.08.1.14.17.21.08.06.17.12.28.18.11.06.23.12.38.19.15.07.29.15.42.23s.25.17.34.26.18.2.24.32c.06.12.09.25.09.4,0,.2-.04.37-.12.51-.08.14-.19.25-.32.34-.13.09-.29.15-.46.19s-.36.06-.55.06c-.06,0-.14,0-.24-.02s-.19-.03-.29-.05c-.1-.02-.19-.04-.28-.07-.09-.03-.16-.06-.21-.1Z"
            />
            <path
              className="cls-17"
              d="m507.77,81.75h-3.01v-.83h.99v-2.97l-1.01.22v-.85l2.06-.42v4.01h.98v.83Z"
            />
            <path
              className="cls-17"
              d="m509.87,81.83c-1.13,0-1.7-.8-1.7-2.39,0-.83.15-1.46.46-1.89.31-.43.75-.65,1.33-.65,1.11,0,1.66.81,1.66,2.43,0,.81-.15,1.42-.45,1.85s-.74.64-1.3.64Zm.05-4.13c-.45,0-.68.57-.68,1.71,0,1.07.22,1.61.67,1.61s.65-.55.65-1.66-.21-1.66-.64-1.66Z"
            />
            <path
              className="cls-17"
              d="m512.21,81.64v-.86c.25.16.55.25.9.25s.65-.12.85-.37c.2-.25.3-.6.3-1.06h-.02c-.21.31-.53.46-.96.46-.39,0-.71-.14-.97-.41-.26-.27-.38-.63-.38-1.06,0-.51.16-.91.47-1.22.31-.31.72-.46,1.21-.46s.92.19,1.22.58c.3.38.45.94.45,1.65,0,.85-.18,1.51-.55,1.99-.36.47-.87.71-1.53.71-.39,0-.72-.06-1.01-.19Zm1.38-3.94c-.18,0-.33.07-.45.22-.12.15-.18.34-.18.57s.06.43.17.57.27.21.47.21c.18,0,.33-.07.45-.2s.17-.3.17-.5c0-.25-.06-.46-.18-.62s-.27-.25-.46-.25Z"
            />
            <path
              className="cls-17"
              d="m522.98,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-17"
              d="m525.59,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-17"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-17"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m524.32,96.79c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-17"
              d="m527.21,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m505.14,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-17"
              d="m510.03,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-17"
              d="m512.29,101.47c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-17"
              d="m514.94,101l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-17"
              d="m517.64,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m520.63,103.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-17"
              d="m523.3,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-17"
              d="m525.55,100.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-17"
              d="m527.93,103.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-17"
              d="m530.5,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-17"
              d="m533.32,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-17"
              d="m536.48,103.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
          </g>
        )}
      </g>
    </SVG>
  )
}

const pivots = {
  A26_int: [62.9, 121.4],
  A26_bal: [28.5, 115.2],
  A27_int: [68.6, 77.8],
  A27_bal: [47.4, 76.2],
  A28_int: [95.8, 46.6],
  A28_bal: [71.2, 25.1],
  A29_int: [152.6, 53.1],
  A29_bal: [138.7, 25.1],
  A30_int: [214.7, 53.8],
  A30_bal: [218.8, 25.1],
  B72_int: [289.3, 53.1],
  B72_bal: [291.1, 25.1],
  B73_int: [355.4, 53.1],
  B73_bal: [348.36, 25.06],
  B74_int: [394, 68.6],
  B74_bal: [437.1, 25.1],
  B75_int: [396.2, 121.6],
  B75_bal: [421.5, 124.7],
  B76_int: [362.7, 147.8],
  B76_bal: [412.8, 174.8],
}

const SVG = styled.svg`
  .cls-1 {
    fill: url(#linear-gradient-2);
  }
  .cls-2 {
    fill: #fff;
  }
  .cls-3 {
    fill: url(#linear-gradient-17);
  }
  .cls-4 {
    fill: url(#linear-gradient-15);
  }
  .cls-5 {
    fill: url(#linear-gradient-18);
  }
  .cls-6 {
    fill: url(#linear-gradient-14);
  }
  .cls-7 {
    fill: url(#linear-gradient-20);
  }
  .cls-8 {
    fill: url(#linear-gradient-6);
  }
  .cls-9 {
    fill: #b4b4b4;
  }
  .cls-10 {
    fill: url(#linear-gradient-11);
  }
  .cls-11 {
    fill: url(#linear-gradient-10);
  }
  .cls-12 {
    stroke: #141414;
    stroke-width: 0.1px;
  }
  .cls-12,
  .cls-13 {
    fill: #f0f0e1;
  }
  .cls-12,
  .cls-13,
  .cls-14 {
    stroke-miterlimit: 10;
  }
  .cls-15 {
    fill: url(#linear-gradient-16);
  }
  .cls-13 {
    stroke: #1a1a1a;
    stroke-width: 0.5px;
  }
  .cls-16 {
    fill: url(#linear-gradient-5);
  }
  .cls-17 {
    fill: #141414;
  }
  .cls-18 {
    fill: url(#linear-gradient-7);
  }
  .cls-19 {
    fill: #505050;
    opacity: 0.99;
  }
  .cls-20 {
    fill: url(#linear-gradient-9);
  }
  .cls-21 {
    fill: url(#linear-gradient-3);
  }
  .cls-22 {
    fill: url(#linear-gradient);
  }
  .cls-14 {
    fill: #cdcdb4;
    stroke: #7f7f7f;
    stroke-width: 0.25px;
  }
  .cls-23 {
    fill: url(#linear-gradient-19);
  }
  .cls-24 {
    fill: url(#linear-gradient-4);
  }
  .cls-25 {
    fill: url(#linear-gradient-8);
  }
  .cls-26 {
    fill: url(#linear-gradient-12);
  }
  .cls-27 {
    fill: url(#linear-gradient-13);
  }

  #up,
  #down {
    rect {
      transition: all 0.2s ease;
    }
  }

  #up:hover,
  #down:hover {
    cursor: pointer;
    padding: 20px;

    rect.cls-2 {
      fill: var(--secondary-color);
    }
  }

  .disable-lift-btn {
    cursor: not-allowed;

    .cls-1 {
      fill: var(--grey);
    }

    .cls-6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text .cls-2 {
    fill: var(--light);
    cursor: pointer;
  }

  .green-text .cls-2 {
    fill: var(--green-color);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }
`
