import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor2({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  apartments,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="rakennus_9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 540 238.96"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="238.75"
          y1="-513.16"
          x2="238.75"
          y2="-556.57"
          gradientTransform="translate(-164.9 -377.96) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".01" stopColor="#ebebeb" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="278"
          y1="-536.38"
          x2="278"
          y2="-579.79"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="344.39"
          y1="-471.72"
          x2="344.39"
          y2="-515.13"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="353.65"
          y1="-500.27"
          x2="353.65"
          y2="-543.68"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="383.91"
          y1="-500.27"
          x2="383.91"
          y2="-543.68"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="453.79"
          y1="-500.27"
          x2="453.79"
          y2="-543.68"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="404.74"
          y1="-464.38"
          x2="404.74"
          y2="-507.79"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="433.64"
          y1="-464.37"
          x2="433.64"
          y2="-507.78"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="518.48"
          y1="-555.89"
          x2="518.48"
          y2="-599.3"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="486.78"
          y1="-534.93"
          x2="486.78"
          y2="-578.34"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="563.24"
          y1="-544.48"
          x2="563.24"
          y2="-587.89"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <rect id="bg-2" className="cls-2" width="539.99" height="238.96" />
        <path
          id="UI_frame"
          className="cls-10"
          d="m0,0v238.96h539.99V0H0Zm464.11,133.75v-55.72h35.38v55.72h-35.38Zm35.38.89v31.33h-35.38v-31.33h35.38Zm-35.38-57.5v-31.33h35.38v31.33h-35.38Zm74.99,89.72v62.48h-74.99v-62.48h74.99Zm-38.71-.89v-74.83h0v-45.33h38.71v120.15h-38.71ZM463.22.81v228.52H.89V.81h462.33Z"
        />
        <path
          id="hartela_logo"
          className="cls-10"
          d="m510.68,184.48l-4.58,1.82v-3.24l-5.71-1.77-5.71,1.77v3.24l-4.58-1.82-6.5,2.01v20.44l5.55,4.21,4.62-2.67v6.19l6.63,5.04,6.63-5.04v-6.19l4.62,2.67,5.55-4.21v-20.44l-6.5-2.01Zm-10.3-2.34l4.02,1.25-4.02,1.42-4.02-1.42,4.02-1.25Zm-4.9,4.48v-2.69h0s0,0,0,0l4.5,1.59v3.22h0s-4.51,1.79-4.51,1.79v-3.9s0,0,0,0h0Zm-5.43-1.29l4.14,1.65-5,1.77-4.06-1.88,4.93-1.53Zm.02,11.24l1.83-.78,1.85-.79h0s0,0,0,0v3.66h0s0,0,0,0l-3.68-2.08h0s0,0,0,0Zm9.91-1.95v23.76l-5.41-4.12v-7.88l-5.81-3.82v7.28l-4.36-3.32h0v-19.1l4.36,2.01v7.32l5.81,3.28v-7.92h0s2.71,1.25,2.71,1.25l2.71,1.25h0Zm.4-.71l-5.2-2.4,5.2-2.07,5.2,2.07-5.2,2.4Zm10.81,10.15v5.9l-4.18-2.42h0v-.72h0s4.18-2.76,4.18-2.76h0Zm-.5-7.49l-3.68,2.08v-3.66h0s1.86.8,1.86.8l1.82.78h0s0,0,0,0Zm.5-7.11v6.44l-4.18-1.8h0v-2.9l-1.18-.47v-3.16h0s0,0,0,0l5.37,1.89h0Zm.38-.72l-5-1.77,4.13-1.65h0l4.93,1.53-4.06,1.88Z"
        />
        <polygon
          id="compass_arrow"
          points="148.46 147.45 130.65 160.28 135.19 163.89 117.68 185.88 118.26 186.34 135.76 164.35 146.49 150.88 139.94 167.67 140.43 168.07 148.46 147.45"
        />
        <g id="paaotsikko">
          <path
            className="cls-2"
            d="m476.03,13.22h-1.41v-2.54h-2.58v2.54h-1.41v-6.24h1.41v2.49h2.58v-2.49h1.41v6.24Z"
          />
          <path
            className="cls-2"
            d="m480.81,13.22h-1.3v-.64h-.02c-.3.5-.74.75-1.33.75-.43,0-.77-.12-1.02-.37s-.37-.57-.37-.98c0-.86.51-1.36,1.54-1.5l1.21-.16c0-.49-.26-.73-.79-.73s-1.04.16-1.51.47v-1.04c.19-.1.45-.19.79-.26s.63-.11.91-.11c1.27,0,1.91.63,1.91,1.9v2.66Zm-1.29-1.81v-.3l-.81.1c-.45.06-.67.26-.67.6,0,.16.05.29.16.39.11.1.26.15.44.15.26,0,.47-.09.63-.27.16-.18.24-.4.24-.68Z"
          />
          <path
            className="cls-2"
            d="m484.58,10.01c-.17-.09-.36-.13-.58-.13-.3,0-.53.11-.7.33-.17.22-.25.52-.25.89v2.13h-1.37v-4.46h1.37v.83h.02c.22-.6.61-.91,1.17-.91.15,0,.26.02.34.05v1.27Z"
          />
          <path
            className="cls-2"
            d="m487.69,13.17c-.2.11-.51.16-.92.16-.97,0-1.45-.5-1.45-1.51v-2.04h-.72v-1.01h.72v-.96l1.37-.39v1.35h1v1.01h-1v1.8c0,.46.18.7.55.7.14,0,.29-.04.45-.13v1.02Z"
          />
          <path
            className="cls-2"
            d="m492.25,11.39h-2.91c.05.65.45.97,1.22.97.49,0,.92-.12,1.29-.35v.99c-.41.22-.95.33-1.61.33-.72,0-1.28-.2-1.68-.6-.4-.4-.6-.96-.6-1.67s.21-1.33.64-1.76c.43-.43.96-.65,1.58-.65s1.15.19,1.51.58.53.91.53,1.57v.58Zm-1.28-.84c0-.64-.26-.96-.77-.96-.22,0-.41.09-.57.27s-.26.41-.29.68h1.64Z"
          />
          <path className="cls-2" d="m494.21,13.22h-1.37v-6.6h1.37v6.6Z" />
          <path
            className="cls-2"
            d="m498.86,13.22h-1.3v-.64h-.02c-.3.5-.74.75-1.33.75-.43,0-.77-.12-1.02-.37s-.37-.57-.37-.98c0-.86.51-1.36,1.54-1.5l1.21-.16c0-.49-.26-.73-.79-.73s-1.04.16-1.51.47v-1.04c.19-.1.45-.19.79-.26s.63-.11.91-.11c1.27,0,1.91.63,1.91,1.9v2.66Zm-1.29-1.81v-.3l-.81.1c-.45.06-.67.26-.67.6,0,.16.05.29.16.39.11.1.26.15.44.15.26,0,.47-.09.63-.27.16-.18.24-.4.24-.68Z"
          />
          <path
            className="cls-2"
            d="m473.74,16.34l-1.62,4.37h-.56l-1.58-4.37h.57l1.21,3.46c.04.11.07.24.09.39h.01c.02-.12.05-.25.1-.39l1.23-3.46h.55Z"
          />
          <path
            className="cls-2"
            d="m476.65,19.27h-2.2c0,.35.1.62.28.8.18.19.42.28.74.28.35,0,.67-.12.97-.35v.47c-.27.2-.64.3-1.09.3s-.79-.14-1.04-.42-.38-.68-.38-1.2c0-.49.14-.88.41-1.19s.62-.46,1.02-.46.72.13.95.4c.22.26.34.63.34,1.1v.26Zm-.51-.42c0-.29-.07-.51-.21-.67-.14-.16-.33-.24-.57-.24s-.44.08-.6.25c-.17.17-.27.39-.3.66h1.68Z"
          />
          <path
            className="cls-2"
            d="m478.87,18.09c-.09-.07-.21-.1-.38-.1-.21,0-.39.1-.53.3-.14.2-.21.47-.21.82v1.59h-.5v-3.12h.5v.64h.01c.07-.22.18-.39.33-.51.15-.12.31-.18.49-.18.13,0,.23.01.3.04v.52Z"
          />
          <path
            className="cls-2"
            d="m481.85,20.71h-.7l-1.38-1.5h-.01v1.5h-.5v-4.62h.5v2.93h.01l1.31-1.43h.65l-1.45,1.5,1.56,1.61Z"
          />
          <path
            className="cls-2"
            d="m484.79,20.71h-.7l-1.38-1.5h-.01v1.5h-.5v-4.62h.5v2.93h.01l1.31-1.43h.65l-1.45,1.5,1.56,1.61Z"
          />
          <path
            className="cls-2"
            d="m486.45,20.78c-.46,0-.83-.15-1.1-.44-.28-.29-.41-.68-.41-1.16,0-.52.14-.93.43-1.23.29-.29.67-.44,1.16-.44s.83.14,1.09.43c.26.29.39.68.39,1.19s-.14.9-.42,1.2-.66.45-1.13.45Zm.04-2.84c-.32,0-.57.11-.76.33-.19.22-.28.52-.28.9s.09.66.28.87c.19.21.44.32.76.32s.57-.1.75-.31c.17-.21.26-.51.26-.89s-.09-.69-.26-.9c-.17-.21-.42-.32-.75-.32Z"
          />
          <path
            className="cls-2"
            d="m488.46,20.59v-.54c.27.2.57.3.9.3.44,0,.66-.15.66-.44,0-.08-.02-.15-.06-.21-.04-.06-.09-.11-.15-.15-.06-.04-.14-.08-.22-.12-.09-.04-.18-.07-.28-.11-.14-.05-.26-.11-.36-.17-.11-.06-.19-.12-.26-.19-.07-.07-.12-.15-.16-.24-.04-.09-.05-.19-.05-.31,0-.15.03-.28.1-.39.07-.11.16-.21.27-.28s.24-.13.38-.17c.14-.04.29-.06.44-.06.27,0,.51.05.72.14v.51c-.23-.15-.49-.23-.79-.23-.09,0-.18.01-.25.03-.08.02-.14.05-.19.09-.05.04-.1.08-.12.14-.03.05-.04.11-.04.18,0,.08.01.15.04.2s.07.1.13.15c.06.04.13.08.21.12.08.04.17.07.28.11.14.05.26.11.37.16.11.06.2.12.28.19s.14.15.18.24c.04.09.06.2.06.33,0,.15-.03.29-.1.4-.07.11-.16.21-.27.28-.11.08-.24.13-.39.17-.15.04-.3.05-.47.05-.32,0-.6-.06-.83-.19Z"
          />
          <path
            className="cls-2"
            d="m493.37,20.71h-.5v-.49h-.01c-.22.37-.54.56-.96.56-.31,0-.55-.08-.73-.25-.17-.16-.26-.38-.26-.65,0-.58.34-.92,1.03-1.02l.93-.13c0-.53-.21-.79-.64-.79-.38,0-.71.13-1.02.38v-.51c.31-.2.66-.29,1.06-.29.73,0,1.1.39,1.1,1.16v2.03Zm-.5-1.58l-.75.1c-.23.03-.41.09-.52.17-.12.08-.18.23-.18.44,0,.15.05.28.16.37.11.1.25.14.43.14.25,0,.45-.09.61-.26.16-.17.24-.39.24-.66v-.31Z"
          />
          <path
            className="cls-2"
            d="m496.39,20.71h-.5v-.49h-.01c-.22.37-.54.56-.96.56-.31,0-.55-.08-.73-.25-.17-.16-.26-.38-.26-.65,0-.58.34-.92,1.03-1.02l.93-.13c0-.53-.21-.79-.64-.79-.38,0-.71.13-1.02.38v-.51c.31-.2.66-.29,1.06-.29.73,0,1.1.39,1.1,1.16v2.03Zm-.5-1.58l-.75.1c-.23.03-.41.09-.52.17-.12.08-.18.23-.18.44,0,.15.05.28.16.37.11.1.25.14.43.14.25,0,.45-.09.61-.26.16-.17.24-.39.24-.66v-.31Z"
          />
          <path
            className="cls-2"
            d="m498.8,18.09c-.09-.07-.21-.1-.38-.1-.21,0-.39.1-.53.3-.14.2-.21.47-.21.82v1.59h-.5v-3.12h.5v.64h.01c.07-.22.18-.39.33-.51.15-.12.31-.18.49-.18.13,0,.23.01.3.04v.52Z"
          />
          <path
            className="cls-2"
            d="m501.7,19.27h-2.2c0,.35.1.62.28.8.18.19.42.28.74.28.35,0,.67-.12.97-.35v.47c-.27.2-.64.3-1.09.3s-.79-.14-1.04-.42-.38-.68-.38-1.2c0-.49.14-.88.41-1.19s.62-.46,1.02-.46.72.13.95.4c.22.26.34.63.34,1.1v.26Zm-.51-.42c0-.29-.07-.51-.21-.67-.14-.16-.33-.24-.57-.24s-.44.08-.6.25c-.17.17-.27.39-.3.66h1.68Z"
          />
          <path
            className="cls-2"
            d="m504.88,20.71h-.5v-1.78c0-.66-.24-.99-.72-.99-.25,0-.46.09-.62.28-.16.19-.25.42-.25.71v1.78h-.5v-3.12h.5v.52h.01c.24-.39.58-.59,1.02-.59.34,0,.6.11.78.33s.27.54.27.96v1.91Z"
          />
          <path
            className="cls-2"
            d="m510.54,16.34l-1.62,4.37h-.56l-1.58-4.37h.57l1.21,3.46c.04.11.07.24.09.39h.01c.02-.12.05-.25.1-.39l1.23-3.46h.55Z"
          />
          <path
            className="cls-2"
            d="m513.17,20.71h-.5v-.49h-.01c-.22.37-.54.56-.96.56-.31,0-.55-.08-.73-.25-.17-.16-.26-.38-.26-.65,0-.58.34-.92,1.03-1.02l.93-.13c0-.53-.21-.79-.64-.79-.38,0-.71.13-1.02.38v-.51c.31-.2.66-.29,1.06-.29.73,0,1.1.39,1.1,1.16v2.03Zm-.5-1.58l-.75.1c-.23.03-.41.09-.52.17-.12.08-.18.23-.18.44,0,.15.05.28.16.37.11.1.25.14.43.14.25,0,.45-.09.61-.26.16-.17.24-.39.24-.66v-.31Z"
          />
          <path className="cls-2" d="m514.46,20.71h-.5v-4.62h.5v4.62Z" />
          <path
            className="cls-2"
            d="m516.62,20.78c-.46,0-.83-.15-1.1-.44-.28-.29-.41-.68-.41-1.16,0-.52.14-.93.43-1.23.29-.29.67-.44,1.16-.44s.83.14,1.09.43c.26.29.39.68.39,1.19s-.14.9-.42,1.2-.66.45-1.13.45Zm.04-2.84c-.32,0-.57.11-.76.33-.19.22-.28.52-.28.9s.09.66.28.87c.19.21.44.32.76.32s.57-.1.75-.31c.17-.21.26-.51.26-.89s-.09-.69-.26-.9c-.17-.21-.42-.32-.75-.32Z"
          />
        </g>
      </g>
      <g id="apartments">
        <polygon
          id="general_fill"
          className="cls-4"
          points="65.4 16.26 127.43 16.26 127.43 31.51 150.43 31.51 150.43 16.26 220.21 16.26 220.22 31.46 258.16 31.46 258.15 16.26 344.23 16.25 344.22 31.46 367.21 31.46 367.22 16.25 447.15 16.25 434.66 93.1 419.61 90.69 415.95 113.39 430.97 115.79 416.38 205.6 324.53 190.68 337.68 109.37 124.94 109.37 96.2 171.55 11.63 132.59 25.57 102.42 39.38 108.79 49.02 87.92 35.22 81.54 65.4 16.26"
        />
        <polygon
          id="liiketila"
          className="cls-8"
          points="123.28 33.73 107.94 33.56 99.36 52.22 106.76 55.63 95.26 80.4 109.33 80.44 109.29 70.88 125.83 70.85 125.81 35.63 123.27 35.63 123.28 33.73"
        />
        <g
          onClick={() => changeView(2, 'A01_int')}
          onMouseOver={() => setElementOnHover('A01_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A01"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_A01_int'
                ? 'active-apt'
                : elementOnHover === 'A01_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A01_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="87.41 100.69 70.57 92.86 46.35 145.32 93.05 166.93 119.16 110.62 89.22 96.8 87.41 100.69"
          />
        </g>
        <g
          onClick={() => changeView(2, 'A02_int')}
          onMouseOver={() => setElementOnHover('A02_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A02"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_A02_int'
                ? 'active-apt'
                : elementOnHover === 'A02_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A02_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="162.82 59.94 162.88 104.74 196.16 104.74 196.13 59.77 162.82 59.94"
          />
        </g>
        <g
          onClick={() => changeView(2, 'A03_int')}
          onMouseOver={() => setElementOnHover('A03_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A03"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_A03_int'
                ? 'active-apt'
                : elementOnHover === 'A03_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A03_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="197.62 59.94 197.47 104.74 253.95 104.74 253.89 59.94 197.62 59.94"
          />
        </g>
        <g
          onClick={() => changeView(2, 'B50_int')}
          onMouseOver={() => setElementOnHover('B50_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B50"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_B50_int'
                ? 'active-apt'
                : elementOnHover === 'B50_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B50_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="255.21 59.95 255.27 104.74 311.68 104.74 311.68 59.96 255.21 59.95"
          />
        </g>
        <g
          onClick={() => changeView(2, 'B52_int')}
          onMouseOver={() => setElementOnHover('B52_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B52"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_B52_int'
                ? 'active-apt'
                : elementOnHover === 'B52_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B52_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="329.67 188.62 374.38 195.77 380.59 157.55 357.35 153.77 364.51 109.88 343.09 106.04 329.67 188.62"
          />
        </g>
        <g
          onClick={() => changeView(2, 'B51_int')}
          onMouseOver={() => setElementOnHover('B51_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B51"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_B51_int'
                ? 'active-apt'
                : elementOnHover === 'B51_int'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B51_int') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="373.07 135.71 370.1 154.45 382.13 156.44 375.68 196.09 412.61 202.07 422.08 143.83 373.07 135.71"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polygon
          id="shape_50"
          className="cls-7"
          points="65.4 16.26 42.25 66.34 46.12 68.13 68.13 20.53 108.35 20.53 108.35 16.26 65.4 16.26"
        />
        <polygon
          id="shape_49"
          className="cls-7"
          points="127.45 35.63 150.43 35.63 150.43 31.51 127.43 31.51 127.45 35.63"
        />
        <polygon
          id="shape_48"
          className="cls-7"
          points="43.16 110.54 52.69 89.62 49.02 87.92 39.38 108.79 43.16 110.54"
        />
        <rect
          id="shape_47"
          className="cls-7"
          x="344.22"
          y="31.46"
          width="22.99"
          height="3.8"
        />
        <rect
          id="shape_46"
          className="cls-7"
          x="389.25"
          y="16.25"
          width="31.6"
          height="4.27"
        />
        <rect
          id="shape_45"
          className="cls-7"
          x="422"
          y="58.55"
          width="31.53"
          height="4.27"
          transform="translate(307.61 483.04) rotate(-80.77)"
        />
        <polygon
          id="shape_44"
          className="cls-7"
          points="411.83 112.72 415.95 113.39 419.61 90.69 415.5 90 411.83 112.72"
        />
        <rect
          id="shape_43"
          className="cls-7"
          x="403.45"
          y="172.65"
          width="31.53"
          height="4.27"
          transform="translate(179.42 560.52) rotate(-80.77)"
        />
        <polygon
          id="shape_42"
          className="cls-7"
          points="94.3 164.63 98.5 166.57 124.94 109.37 337.68 109.37 325.35 185.21 330.07 185.98 343.28 104.74 121.8 104.82 94.3 164.63"
        />
        <polygon
          id="shape_41"
          className="cls-12"
          points="367.21 35.26 367.22 16.25 389.25 16.25 389.25 20.52 371.39 20.52 371.38 33.21 373.12 33.2 373.13 35.26 367.21 35.26"
        />
        <rect
          id="shape_40"
          className="cls-12"
          x="293.18"
          y="104.74"
          width="7.83"
          height="4.63"
        />
        <polygon
          id="shape_39"
          className="cls-12"
          points="315.38 102.96 315.38 109.37 306.76 109.37 306.76 104.74 309.46 104.74 309.46 102.97 315.38 102.96"
        />
        <polygon
          id="shape_38"
          className="cls-12"
          points="150.43 16.26 150.43 35.63 154.61 35.63 154.61 20.53 216.04 20.53 216.04 35.63 262.34 35.63 262.34 20.52 340.04 20.52 340.04 33.2 338.31 33.2 338.31 35.26 344.23 35.26 344.23 16.25 258.15 16.26 258.16 31.46 220.22 31.46 220.21 16.26 150.43 16.26"
        />
        <polygon
          id="shape_37"
          className="cls-12"
          points="59.35 39.51 68.13 20.53 76.82 20.53 76.82 16.26 65.4 16.26 55.48 37.72 59.35 39.51"
        />
        <polygon
          id="shape_36"
          className="cls-12"
          points="127.43 16.26 127.45 70.85 125.83 70.85 125.82 35.63 123.27 35.63 123.27 20.53 108.35 20.53 108.35 16.26 127.43 16.26"
        />
        <polygon
          id="shape_35"
          className="cls-12"
          points="52.69 89.62 55.17 84.25 53.08 83.29 52.35 84.87 40.85 79.55 46.12 68.13 42.25 66.34 35.22 81.54 52.69 89.62"
        />
        <rect
          id="shape_34"
          className="cls-12"
          x="134.64"
          y="104.74"
          width="8.7"
          height="4.63"
        />
        <polygon
          id="shape_33"
          className="cls-12"
          points="340.67 131.96 338.9 131.67 337.95 137.51 339.73 137.8 340.67 131.96"
        />
        <polygon
          id="shape_32"
          className="cls-12"
          points="330.91 151.01 335.63 151.77 337.03 143.18 332.31 142.42 330.91 151.01"
        />
        <polygon
          id="shape_31"
          className="cls-12"
          points="333.23 136.75 337.95 137.51 339.35 128.91 334.63 128.16 333.23 136.75"
        />
        <polygon
          id="shape_30"
          className="cls-12"
          points="333.54 164.66 335.78 165.03 336.73 159.19 334.49 158.82 333.54 164.66"
        />
        <polygon
          id="shape_29"
          className="cls-12"
          points="326.29 179.48 331 180.3 332.39 171.71 327.69 170.89 326.29 179.48"
        />
        <polygon
          id="shape_28"
          className="cls-12"
          points="328.59 165.25 333.31 166.04 334.71 157.44 329.99 156.66 328.59 165.25"
        />
        <polygon
          id="shape_27"
          className="cls-12"
          points="325.35 185.21 324.53 190.68 329.25 191.45 330.07 185.98 325.35 185.21"
        />
        <polygon
          id="shape_26"
          className="cls-12"
          points="414.58 190.01 412.61 202.07 329.67 188.62 329.25 191.45 416.38 205.6 418.79 190.69 414.58 190.01"
        />
        <polygon
          id="shape_25"
          className="cls-12"
          points="286.47 104.74 286.47 102.96 280.56 102.96 280.56 104.74 279.16 104.74 279.16 109.36 286.48 109.36 286.47 104.74"
        />
        <polygon
          id="shape_24"
          className="cls-12"
          points="313.02 58.62 313.09 70.87 341.99 70.87 341.99 74.48 353.87 74.48 353.87 94.84 312.84 94.85 312.84 102.96 311.76 102.96 311.76 94.85 311.68 59.96 255.37 59.95 255.26 98.36 255.27 104.74 258.96 104.74 258.96 105.92 258.96 109.36 250.26 109.36 250.26 105.92 250.26 104.74 253.95 104.74 253.8 59.94 197.62 59.94 197.62 102.96 196.11 102.96 196.11 59.94 162.88 59.94 162.88 104.74 161.58 104.74 161.58 59.94 151.71 59.94 151.68 71.79 150.44 71.79 150.44 35.63 151.71 35.63 151.71 58.64 253.8 58.63 253.82 35.62 255.38 35.62 255.37 58.63 313.02 58.62"
        />
        <polygon
          id="shape_23"
          className="cls-12"
          points="86.76 98.87 69.92 91.08 45.1 144.78 46.35 145.32 70.57 92.86 87.41 100.69 89.21 96.8 89.22 96.8 119.16 110.62 114.81 120.01 119.12 121.96 124.94 109.37 128.89 109.37 128.89 105.92 128.89 104.74 127.49 104.74 127.49 102.96 121.58 102.96 105.71 102.96 119.71 109.42 89.77 95.61 96.1 81.72 109.29 81.74 109.29 94.86 151.68 94.73 151.68 71.79 150.37 71.79 139.53 71.8 139.53 70.88 109.33 70.78 109.33 80.44 95.26 80.4 86.76 98.87"
        />
        <polygon
          id="shape_22"
          className="cls-12"
          points="172.25 104.74 170.85 104.74 170.85 102.96 164.94 102.96 164.94 104.74 161.58 104.74 161.58 109.37 172.25 109.37 172.25 104.74"
        />
        <polygon
          id="shape_21"
          className="cls-12"
          points="153.63 104.74 153.63 103.29 150.34 103.29 150.34 104.74 149.09 104.74 149.09 109.31 153.64 109.31 153.63 104.74"
        />
        <polygon
          id="shape_20"
          className="cls-12"
          points="103.21 156.39 98.91 154.39 96.62 159.37 100.91 161.37 103.21 156.39"
        />
        <polygon
          id="shape_19"
          className="cls-12"
          points="116.68 127.23 112.4 125.23 108.75 133.13 113.03 135.13 116.68 127.23"
        />
        <polygon
          id="shape_18"
          className="cls-12"
          points="110.62 140.35 106.34 138.35 103.75 143.95 108.03 145.95 110.62 140.35"
        />
        <polygon
          id="shape_17"
          className="cls-12"
          points="201.15 104.74 199.76 104.74 199.76 102.96 193.84 102.96 193.84 104.74 192.45 104.74 192.45 109.37 201.15 109.37 201.15 104.74"
        />
        <polyline
          id="shape_16"
          className="cls-12"
          points="214.18 104.74 206.9 104.74 206.9 105.92 206.9 109.37 216.06 109.37 216.06 104.74 215.52 104.74"
        />
        <polyline
          id="shape_15"
          className="cls-12"
          points="226.32 35.26 226.23 35.26 226.15 35.26 225.71 35.26 225.58 35.26 225.15 35.26 225.06 35.26 224.98 35.26"
        />
        <polygon
          id="shape_14"
          className="cls-12"
          points="230.06 104.74 228.66 104.74 228.66 102.96 222.75 102.96 222.75 104.74 222.76 109.37 230.06 109.37 230.06 104.74"
        />
        <path
          id="shape_13"
          className="cls-12"
          d="m37.7,112.62l-.78,1.7,3.72,1.72,2.54-5.49-16.79-7.76-.8-.37-13.94,30.17,84.57,38.96,2.3-4.98-4.29-1.98-1.16,2.34-76.3-35.26,10.94-23.68s10,4.62,10,4.62Z"
        />
        <polygon
          id="shape_12"
          className="cls-12"
          points="343.28 104.74 324.71 104.74 324.71 109.37 337.68 109.37 335.57 122.34 340.27 123.24 343.07 106.03 353.57 107.74 352.93 111.7 365.25 113.7 366.11 108.45 343.28 104.74"
        />
        <rect
          id="shape_11"
          className="cls-12"
          x="338.31"
          y="33.2"
          width="5.91"
          height="2.06"
        />
        <polygon
          id="shape_9"
          className="cls-12"
          points="420.85 16.25 420.85 20.52 442.13 20.52 438.19 44.79 442.4 45.47 447.15 16.25 420.85 16.25"
        />
        <polygon
          id="shape_8"
          className="cls-12"
          points="437.34 76.59 434.66 93.1 416.31 90.13 367.22 82.15 367.19 63.71 341.79 63.71 341.79 70.89 340.94 70.89 340.94 35.26 341.79 35.26 341.79 62.86 367.21 62.86 367.21 35.26 368.86 35.26 368.86 80.75 416.57 88.51 416.97 86.01 431.11 88.3 433.13 75.91 437.34 76.59"
        />
        <polygon
          id="shape_7"
          className="cls-12"
          points="423.85 159.57 430.97 115.79 412.62 112.83 376.28 106.92 368.56 154.24 358.89 152.66 365.25 113.7 363.9 113.48 357.56 152.46 344.56 150.32 344.35 151.65 380.59 157.55 374.36 195.87 375.68 196.09 382.13 156.44 370.1 154.45 377.34 108.82 412.35 114.51 411.67 118.66 413.29 118.92 413.57 117.2 426.09 119.22 422.31 142.45 373.26 134.49 373.07 135.71 422.08 143.83 419.64 158.89 423.85 159.57"
        />
        <polygon
          id="shape_6"
          className="cls-12"
          points="368.64 155.61 366.32 155.23 368.1 158.91 368.64 155.61"
        />
        <polygon
          id="shape_5"
          className="cls-12"
          points="365.23 161.85 367.55 162.23 368.1 158.93 365.23 161.85"
        />
        <polygon
          id="shape_4"
          className="cls-12"
          points="344.35 151.65 343.27 158.27 365.23 161.85 366.31 155.23 344.35 151.65"
        />
        <polygon
          id="shape_3"
          className="cls-12"
          points="105.26 58.88 106.76 55.63 103.07 53.93 105.26 58.88"
        />
        <rect
          id="shape_2"
          className="cls-12"
          x="77.52"
          y="68.49"
          width="33.89"
          height="8.15"
          transform="translate(-10.98 127.98) rotate(-65.26)"
        />
        <polygon
          id="shape_1"
          className="cls-12"
          points="99.36 52.22 97.86 55.47 103.04 53.92 99.36 52.22"
        />
      </g>
      <g id="balcony">
        <g
          onClick={() => changeView(2, 'A01_bal')}
          onMouseOver={() => setElementOnHover('A01_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="a01-2"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_A01_bal'
                ? 'active-apt'
                : elementOnHover === 'A01_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A01_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="96.6 170.75 107.85 175.97 126.01 136.56 114.8 131.31 96.6 170.75"
          />
        </g>
        <g
          onClick={() => changeView(2, 'A02_bal')}
          onMouseOver={() => setElementOnHover('A02_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="a02-2"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_A02_bal'
                ? 'active-apt'
                : elementOnHover === 'A02_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A02_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="196.71 122.07 196.7 109.5 167.82 109.38 167.79 122.01 196.71 122.07"
          />
        </g>
        <g
          onClick={() => changeView(2, 'A03_bal')}
          onMouseOver={() => setElementOnHover('A03_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="a03-2"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_A03_bal'
                ? 'active-apt'
                : elementOnHover === 'A03_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A03_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="254.56 122.07 254.55 109.5 211.22 109.38 211.18 122.01 254.56 122.07"
          />
        </g>
        <g
          onClick={() => changeView(2, 'B50_bal')}
          onMouseOver={() => setElementOnHover('B50_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="b50-2"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_B50_bal'
                ? 'active-apt'
                : elementOnHover === 'B50_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B50_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="312.38 122.07 312.36 109.5 254.62 109.38 254.56 122.01 312.38 122.07"
          />
        </g>
        <g
          onClick={() => changeView(2, 'B52_bal')}
          onMouseOver={() => setElementOnHover('B52_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="b52-2"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_B52_bal'
                ? 'active-apt'
                : elementOnHover === 'B52_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B52_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="324.67 189.73 331.64 146.86 319.2 144.88 312.24 187.71 324.67 189.73"
          />
        </g>
        <g
          onClick={() => changeView(2, 'B51_bal')}
          onMouseOver={() => setElementOnHover('B51_bal')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="b51"
            className={`cls-8 ${
              sceneName === 'scene_valo_krs02_B51_bal'
                ? 'active-apt'
                : elementOnHover === 'B51_bal'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'B51_bal') === 'sold'
                ? 'bg-gray'
                : ''
            }`}
            points="429.5847 159.242 423.5701 158.3087 418.2059 192.0193 424.2446 193.0051 429.5847 159.242"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 'A01_int' && (
          <g id="A01_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone"
              className="cls-16"
              d="m77.11,135.22c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A01_bal' && (
          <g id="A01_t" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-2"
              className="cls-1"
              d="m116.36,158.43c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A02_int' && (
          <g id="A02_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-3"
              className="cls-14"
              d="m182.75,93.78c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-3"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A02_bal' && (
          <g id="A03_t" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-4"
              className="cls-17"
              d="m192.01,122.32c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A03_bal' && (
          <g id="A02_t" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-5"
              className="cls-9"
              d="m222.27,122.32c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-5"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B50_bal' && (
          <g id="B50_t" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-6"
              className="cls-3"
              d="m292.15,122.32c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-6"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'A03_int' && (
          <g id="A03_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-7"
              className="cls-11"
              d="m243.1,86.43c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-7"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B50_int' && (
          <g id="B50_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-8"
              className="cls-18"
              d="m272,86.43c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-8"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B52_int' && (
          <g id="B52_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-9"
              className="cls-13"
              d="m356.83,177.95c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-9"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B52_bal' && (
          <g id="B52_t" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-10"
              className="cls-6"
              d="m325.14,156.99c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-10"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B51_int' && (
          <g id="B51_i" style={{ pointerEvents: 'none' }}>
            <path
              id="cone-11"
              className="cls-5"
              d="m401.6,166.53c-.86.36-1.8.56-2.79.56s-1.96-.2-2.82-.58l-18.81,43.41h42.33l-17.9-43.39Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-11"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6"
            />
          </g>
        )}
        {apartmentNumber === 'B51_bal' && (
          <g id="B51_t">
            <path
              id="cone-12"
              data-name="cone"
              className="cls-5"
              d="m426.9741,179.4234c-.8603.3577-1.798.5607-2.7936.5607-1.0053,0-1.9623-.203-2.8226-.58l-18.8109,43.4121h42.3294l-17.9023-43.3927Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-12"
              data-name="location"
              className="cls-2"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6046"
            />
          </g>
        )}
      </g>
      <g id="elevator">
        <g id="down">
          <rect
            id="bg-3"
            className="cls-2"
            x="464.11"
            y="134.64"
            width="35.38"
            height="31.33"
          />
          <polygon
            id="down"
            points="472.06 145.31 491.54 145.31 481.8 155.29 472.06 145.31"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <rect
            id="bg-4"
            className="cls-2"
            x="464.11"
            y="45.81"
            width="35.38"
            height="31.33"
          />
          <polygon
            id="up"
            points="491.54 65.51 472.06 65.51 481.8 55.54 491.54 65.51"
          />
        </g>
        <path
          id="_2-2"
          className="cls-15"
          d="m476.25,114.62v-1.44l1.84-1.79c4.44-4.22,6.44-6.47,6.47-9.09,0-1.76-.86-3.39-3.45-3.39-1.58,0-2.89.8-3.69,1.47l-.75-1.66c1.2-1.02,2.91-1.77,4.92-1.77,3.74,0,5.32,2.57,5.32,5.05,0,3.21-2.33,5.8-5.99,9.33l-1.39,1.28v.05h7.81v1.95h-11.09Z"
        />
      </g>
      <g id="apartmentnr">
        <g
          id="A01"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'A01_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 'A01_int')}
          onMouseOver={() => setElementOnHover('A01_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-12"
            d="m80.53,133.75h-1.66l-.48-1.51h-2.41l-.48,1.51h-1.65l2.46-6.77h1.81l2.41,6.77Zm-2.49-2.68l-.73-2.28c-.05-.17-.09-.37-.11-.61h-.04c-.02.2-.06.4-.12.59l-.74,2.29h1.73Z"
          />
          <path
            className="cls-12"
            d="m83.1,133.86c-1.61,0-2.42-1.13-2.42-3.39,0-1.17.22-2.07.65-2.68.44-.62,1.07-.92,1.9-.92,1.57,0,2.36,1.15,2.36,3.45,0,1.15-.21,2.02-.64,2.63-.43.61-1.05.92-1.85.92Zm.07-5.86c-.65,0-.97.81-.97,2.43,0,1.53.32,2.29.95,2.29s.92-.79.92-2.36-.3-2.36-.91-2.36Z"
          />
          <path
            className="cls-12"
            d="m90.76,133.75h-4.28v-1.18h1.4v-4.21l-1.44.31v-1.21l2.93-.59v5.7h1.39v1.18Z"
          />
        </g>
        <g
          id="A02"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'A02_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 'A02_int')}
          onMouseOver={() => setElementOnHover('A02_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-12"
            d="m179.09,84.33h-1.66l-.48-1.51h-2.41l-.48,1.51h-1.65l2.46-6.77h1.81l2.41,6.77Zm-2.49-2.68l-.73-2.28c-.05-.17-.09-.37-.11-.61h-.04c-.02.2-.06.4-.12.59l-.74,2.29h1.73Z"
          />
          <path
            className="cls-12"
            d="m181.67,84.45c-1.61,0-2.42-1.13-2.42-3.39,0-1.17.22-2.07.65-2.68.44-.62,1.07-.92,1.9-.92,1.57,0,2.36,1.15,2.36,3.45,0,1.15-.21,2.02-.64,2.63-.43.61-1.05.92-1.85.92Zm.07-5.86c-.65,0-.97.81-.97,2.43,0,1.53.32,2.29.95,2.29s.92-.79.92-2.36-.3-2.36-.91-2.36Z"
          />
          <path
            className="cls-12"
            d="m186.46,83.07v.03h2.75v1.24h-4.46v-1.16l1.94-1.85c.39-.38.66-.69.82-.94.16-.25.24-.52.24-.8,0-.62-.34-.93-1.01-.93-.58,0-1.14.23-1.67.69v-1.31c.59-.38,1.25-.57,1.99-.57.69,0,1.23.17,1.62.52s.58.82.58,1.41c0,.79-.47,1.61-1.42,2.45l-1.39,1.24Z"
          />
        </g>
        <g
          id="A03"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'A03_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 'A03_int')}
          onMouseOver={() => setElementOnHover('A03_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-12"
            d="m224.57,84.33h-1.66l-.48-1.51h-2.41l-.48,1.51h-1.65l2.46-6.77h1.81l2.41,6.77Zm-2.49-2.68l-.73-2.28c-.05-.17-.09-.37-.11-.61h-.04c-.02.2-.06.4-.12.59l-.74,2.29h1.73Z"
          />
          <path
            className="cls-12"
            d="m227.15,84.45c-1.61,0-2.42-1.13-2.42-3.39,0-1.17.22-2.07.65-2.68.44-.62,1.07-.92,1.9-.92,1.57,0,2.36,1.15,2.36,3.45,0,1.15-.21,2.02-.64,2.63-.43.61-1.05.92-1.85.92Zm.07-5.86c-.65,0-.97.81-.97,2.43,0,1.53.32,2.29.95,2.29s.92-.79.92-2.36-.3-2.36-.91-2.36Z"
          />
          <path
            className="cls-12"
            d="m230.42,84.11v-1.3c.45.33.98.5,1.59.5.38,0,.68-.08.89-.25.21-.16.32-.39.32-.68s-.13-.54-.39-.7c-.26-.16-.62-.25-1.08-.25h-.61v-1.14h.56c.88,0,1.32-.29,1.32-.88,0-.55-.34-.83-1.01-.83-.45,0-.89.15-1.32.44v-1.22c.48-.24,1.03-.36,1.66-.36.69,0,1.23.16,1.62.47.39.31.58.72.58,1.21,0,.88-.45,1.44-1.34,1.66v.02c.48.06.86.23,1.13.52s.42.64.42,1.06c0,.63-.23,1.13-.69,1.5s-1.1.55-1.92.55c-.7,0-1.27-.11-1.7-.34Z"
          />
        </g>
        <g
          id="B50"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'B50_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 'B50_int')}
          onMouseOver={() => setElementOnHover('B50_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-12"
            d="m274.66,84.33v-6.77h2.46c.75,0,1.33.14,1.74.42.41.28.61.67.61,1.17,0,.37-.12.68-.37.96-.25.27-.56.46-.95.57v.02c.48.06.87.24,1.15.53.29.29.43.66.43,1.08,0,.62-.22,1.11-.67,1.48-.44.36-1.05.54-1.82.54h-2.6Zm1.52-5.65v1.61h.67c.31,0,.56-.08.74-.23.18-.15.27-.36.27-.63,0-.5-.37-.75-1.11-.75h-.57Zm0,2.74v1.78h.83c.35,0,.63-.08.83-.25.2-.16.3-.39.3-.67s-.1-.48-.29-.64c-.2-.15-.47-.23-.82-.23h-.83Z"
          />
          <path
            className="cls-12"
            d="m280.62,84.16v-1.27c.46.28.96.42,1.48.42.39,0,.7-.09.92-.28.22-.19.33-.44.33-.76,0-.67-.47-1-1.42-1-.31,0-.68.03-1.12.08v-3.78h3.75v1.22h-2.47v1.35c.2-.02.38-.03.56-.03.7,0,1.24.18,1.63.55s.59.86.59,1.47c0,.68-.23,1.24-.7,1.67s-1.1.65-1.91.65c-.65,0-1.2-.1-1.64-.29Z"
          />
          <path
            className="cls-12"
            d="m287.91,84.45c-1.61,0-2.42-1.13-2.42-3.39,0-1.17.22-2.07.65-2.68.44-.62,1.07-.92,1.89-.92,1.57,0,2.36,1.15,2.36,3.45,0,1.15-.21,2.02-.64,2.63s-1.04.92-1.85.92Zm.07-5.86c-.65,0-.97.81-.97,2.43,0,1.53.32,2.29.95,2.29s.93-.79.93-2.36-.3-2.36-.91-2.36Z"
          />
        </g>
        <g
          id="B51"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'B51_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 'B51_int')}
          onMouseOver={() => setElementOnHover('B51_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-12"
            d="m390.89,176.05v-6.77h2.46c.75,0,1.33.14,1.74.42.41.28.61.67.61,1.17,0,.37-.12.68-.37.96-.25.27-.56.46-.95.57v.02c.48.06.87.24,1.15.53.29.29.43.66.43,1.08,0,.62-.22,1.11-.67,1.48-.44.36-1.05.54-1.82.54h-2.6Zm1.52-5.65v1.61h.67c.31,0,.56-.08.74-.23.18-.15.27-.36.27-.63,0-.5-.37-.75-1.11-.75h-.57Zm0,2.74v1.78h.83c.35,0,.63-.08.83-.25.2-.16.3-.39.3-.67s-.1-.48-.29-.64c-.2-.15-.47-.23-.82-.23h-.83Z"
          />
          <path
            className="cls-12"
            d="m396.85,175.87v-1.27c.46.28.96.42,1.48.42.39,0,.7-.09.92-.28.22-.19.33-.44.33-.76,0-.67-.47-1-1.42-1-.31,0-.68.03-1.12.08v-3.78h3.75v1.22h-2.47v1.35c.2-.02.38-.03.56-.03.7,0,1.24.18,1.63.55s.59.86.59,1.47c0,.68-.23,1.24-.7,1.67s-1.1.65-1.91.65c-.65,0-1.2-.1-1.64-.29Z"
          />
          <path
            className="cls-12"
            d="m406.47,176.05h-4.28v-1.18h1.4v-4.21l-1.44.31v-1.21l2.93-.59v5.7h1.39v1.18Z"
          />
        </g>
        <g
          id="B52"
          style={{ cursor: 'pointer' }}
          className={`${
            getApartmentStatus(apartments, 'B52_int') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(2, 'B52_int')}
          onMouseOver={() => setElementOnHover('B52_int')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-12"
            d="m347.82,176.05v-6.77h2.46c.75,0,1.33.14,1.74.42.41.28.61.67.61,1.17,0,.37-.12.68-.37.96-.25.27-.56.46-.95.57v.02c.48.06.87.24,1.15.53.29.29.43.66.43,1.08,0,.62-.22,1.11-.67,1.48-.44.36-1.05.54-1.82.54h-2.6Zm1.52-5.65v1.61h.67c.31,0,.56-.08.74-.23.18-.15.27-.36.27-.63,0-.5-.37-.75-1.11-.75h-.57Zm0,2.74v1.78h.83c.35,0,.63-.08.83-.25.2-.16.3-.39.3-.67s-.1-.48-.29-.64c-.2-.15-.47-.23-.82-.23h-.83Z"
          />
          <path
            className="cls-12"
            d="m353.77,175.87v-1.27c.46.28.96.42,1.48.42.39,0,.7-.09.92-.28.22-.19.33-.44.33-.76,0-.67-.47-1-1.42-1-.31,0-.68.03-1.12.08v-3.78h3.75v1.22h-2.47v1.35c.2-.02.38-.03.56-.03.7,0,1.24.18,1.63.55s.59.86.59,1.47c0,.68-.23,1.24-.7,1.67s-1.1.65-1.91.65c-.65,0-1.2-.1-1.64-.29Z"
          />
          <path
            className="cls-12"
            d="m360.54,174.78v.03h2.75v1.24h-4.46v-1.16l1.94-1.85c.39-.38.67-.69.82-.94.16-.25.24-.52.24-.8,0-.62-.33-.93-1.01-.93-.58,0-1.14.23-1.67.69v-1.31c.59-.38,1.25-.57,1.99-.57.69,0,1.23.17,1.62.52s.58.82.58,1.41c0,.79-.47,1.61-1.42,2.45l-1.39,1.24Z"
          />
        </g>
      </g>
      <g id="apartment_description">
        {(apartmentNumber === 'A01_int' || apartmentNumber === 'A01_bal') && (
          <g id="A01_text">
            <path
              className="cls-10"
              d="m508.93,58.41h-1.17l-.34-1.06h-1.69l-.34,1.06h-1.16l1.73-4.77h1.27l1.69,4.77Zm-1.75-1.88l-.51-1.6c-.04-.12-.06-.26-.08-.43h-.03c-.01.14-.04.28-.08.42l-.52,1.61h1.22Z"
            />
            <path
              className="cls-10"
              d="m510.74,58.49c-1.13,0-1.7-.8-1.7-2.39,0-.83.15-1.46.46-1.89.31-.43.75-.65,1.33-.65,1.11,0,1.66.81,1.66,2.43,0,.81-.15,1.42-.45,1.85s-.74.64-1.3.64Zm.05-4.13c-.45,0-.68.57-.68,1.71,0,1.07.22,1.61.67,1.61s.65-.55.65-1.66-.21-1.66-.64-1.66Z"
            />
            <path
              className="cls-10"
              d="m516.13,58.41h-3.01v-.83h.99v-2.97l-1.01.22v-.85l2.06-.42v4.01h.98v.83Z"
            />
            <path
              className="cls-10"
              d="m504.56,65.26v-.5c.28.22.59.33.95.33.28,0,.51-.07.68-.21s.25-.33.25-.58c0-.53-.38-.8-1.14-.8h-.34v-.4h.32c.67,0,1.01-.25,1.01-.75,0-.46-.26-.69-.77-.69-.29,0-.56.1-.81.29v-.46c.26-.16.57-.23.93-.23s.62.09.83.27c.21.18.31.42.31.7,0,.54-.27.88-.82,1.04h0c.29.04.53.14.7.32.17.18.26.4.26.67,0,.37-.13.66-.39.89-.26.23-.61.34-1.04.34-.39,0-.7-.07-.93-.22Z"
            />
            <path
              className="cls-10"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-10"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-10"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-10"
              d="m524.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m525.37,65.25v-.56c.06.06.14.11.23.15.09.04.18.08.28.12s.2.06.3.07c.1.02.19.03.28.03.29,0,.51-.05.66-.16s.22-.27.22-.47c0-.11-.02-.21-.07-.29-.05-.08-.12-.16-.2-.22-.08-.07-.19-.13-.3-.19-.12-.06-.24-.13-.38-.2-.14-.07-.28-.14-.4-.22-.12-.07-.23-.16-.32-.25-.09-.09-.16-.19-.21-.3-.05-.11-.08-.25-.08-.4,0-.19.04-.35.12-.49.08-.14.19-.25.32-.34.13-.09.28-.16.45-.2.17-.04.34-.07.52-.07.4,0,.7.05.88.15v.54c-.24-.17-.55-.25-.93-.25-.1,0-.21.01-.31.03s-.2.06-.28.11c-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29,0,.1.02.19.06.27.04.08.1.14.17.21.08.06.17.12.28.18.11.06.23.12.38.19.15.07.29.15.42.23s.25.17.34.26.18.2.24.32c.06.12.09.25.09.4,0,.2-.04.37-.12.51-.08.14-.19.25-.32.34-.13.09-.29.15-.46.19s-.36.06-.55.06c-.06,0-.14,0-.24-.02s-.19-.03-.29-.05c-.1-.02-.19-.04-.28-.07-.09-.03-.16-.06-.21-.1Z"
            />
            <path
              className="cls-10"
              d="m507.83,77.47l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-10"
              d="m509.5,80.86v.02h1.93v.87h-3.14v-.82l1.37-1.31c.27-.26.47-.48.58-.66.11-.18.17-.37.17-.56,0-.44-.24-.66-.71-.66-.41,0-.8.16-1.18.49v-.92c.41-.27.88-.4,1.4-.4.49,0,.87.12,1.14.37.27.25.41.58.41.99,0,.56-.33,1.13-1,1.72l-.98.87Z"
            />
            <path
              className="cls-10"
              d="m513.12,80.91l-.49,1.68h-.82l.34-1.68h.97Z"
            />
            <path
              className="cls-10"
              d="m513.9,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m524.65,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-10"
              d="m527.27,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-10"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-10"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m524.09,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m526.47,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m529.04,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m531.86,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m535.02,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m504.49,103.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m507.23,100.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.08,103.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m512.68,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m513.89,103.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-10"
              d="m516.93,100.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m520.38,103.91h-.47v-1.68c0-.61-.23-.91-.68-.91-.23,0-.42.09-.58.26-.16.18-.23.4-.23.68v1.65h-.47v-4.32h.47v1.89h.01c.22-.37.54-.55.96-.55.66,0,.99.4.99,1.19v1.8Z"
            />
            <path
              className="cls-10"
              d="m523.21,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path className="cls-10" d="m524.41,103.91h-.47v-4.32h.47v4.32Z" />
            <path className="cls-10" d="m525.68,103.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-10"
              d="m528.82,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
          </g>
        )}
        {(apartmentNumber === 'A02_int' || apartmentNumber === 'A02_bal') && (
          <g id="A02_text">
            <path
              className="cls-10"
              d="m508.93,58.41h-1.17l-.34-1.06h-1.69l-.34,1.06h-1.16l1.73-4.77h1.27l1.69,4.77Zm-1.75-1.88l-.51-1.6c-.04-.12-.06-.26-.08-.43h-.03c-.01.14-.04.28-.08.42l-.52,1.61h1.22Z"
            />
            <path
              className="cls-10"
              d="m510.74,58.49c-1.13,0-1.7-.8-1.7-2.39,0-.83.15-1.46.46-1.89.31-.43.75-.65,1.33-.65,1.11,0,1.66.81,1.66,2.43,0,.81-.15,1.42-.45,1.85s-.74.64-1.3.64Zm.05-4.13c-.45,0-.68.57-.68,1.71,0,1.07.22,1.61.67,1.61s.65-.55.65-1.66-.21-1.66-.64-1.66Z"
            />
            <path
              className="cls-10"
              d="m514.12,57.52v.02h1.93v.87h-3.14v-.82l1.37-1.31c.27-.26.47-.48.58-.66.11-.18.17-.37.17-.56,0-.44-.24-.66-.71-.66-.41,0-.8.16-1.18.49v-.92c.41-.27.88-.4,1.4-.4.49,0,.87.12,1.14.37.27.25.41.58.41.99,0,.56-.33,1.13-1,1.72l-.98.87Z"
            />
            <path
              className="cls-10"
              d="m507,65.41h-2.31v-.41h.92v-3.17l-.95.28v-.44l1.41-.43v3.77h.92v.41Z"
            />
            <path
              className="cls-10"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-10"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-10"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-10"
              d="m524.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m528.72,65.41h-.53l-.43-1.15h-1.73l-.41,1.15h-.53l1.57-4.08h.5l1.57,4.08Zm-1.12-1.58l-.64-1.74c-.02-.06-.04-.15-.06-.27h-.01c-.02.12-.04.21-.07.27l-.64,1.74h1.42Z"
            />
            <path
              className="cls-10"
              d="m531.3,65.41h-2.12v-4.08h.48v3.65h1.64v.43Z"
            />
            <path
              className="cls-10"
              d="m534.62,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-10"
              d="m504.68,81.59v-.91c.32.23.69.35,1.12.35.27,0,.48-.06.63-.17s.22-.28.22-.48-.09-.38-.28-.49c-.19-.11-.44-.17-.76-.17h-.43v-.8h.4c.62,0,.93-.21.93-.62,0-.39-.24-.58-.71-.58-.32,0-.63.1-.93.31v-.86c.33-.17.72-.25,1.17-.25.49,0,.87.11,1.14.33.27.22.41.5.41.85,0,.62-.32,1.01-.95,1.17v.02c.34.04.6.16.8.37.2.2.29.45.29.75,0,.45-.16.8-.49,1.06-.33.26-.78.39-1.35.39-.49,0-.89-.08-1.2-.24Z"
            />
            <path
              className="cls-10"
              d="m508.42,81.59v-.91c.32.23.69.35,1.12.35.27,0,.48-.06.63-.17s.22-.28.22-.48-.09-.38-.28-.49c-.19-.11-.44-.17-.76-.17h-.43v-.8h.4c.62,0,.93-.21.93-.62,0-.39-.24-.58-.71-.58-.32,0-.63.1-.93.31v-.86c.33-.17.72-.25,1.17-.25.49,0,.87.11,1.14.33.27.22.41.5.41.85,0,.62-.32,1.01-.95,1.17v.02c.34.04.6.16.8.37.2.2.29.45.29.75,0,.45-.16.8-.49,1.06-.33.26-.78.39-1.35.39-.49,0-.89-.08-1.2-.24Z"
            />
            <path
              className="cls-10"
              d="m513.12,80.91l-.49,1.68h-.82l.34-1.68h.97Z"
            />
            <path
              className="cls-10"
              d="m513.9,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m524.65,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-10"
              d="m527.27,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-10"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-10"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m507.01,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-10"
              d="m508.92,103.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m511.74,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-10" d="m512.77,103.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-10"
              d="m515.66,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m518.48,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m521.64,103.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m504.49,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m507.23,107.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.08,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m512.68,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m513.89,110.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-10"
              d="m516.93,107.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m520.38,110.91h-.47v-1.68c0-.61-.23-.91-.68-.91-.23,0-.42.09-.58.26-.16.18-.23.4-.23.68v1.65h-.47v-4.32h.47v1.89h.01c.22-.37.54-.55.96-.55.66,0,.99.4.99,1.19v1.8Z"
            />
            <path
              className="cls-10"
              d="m523.21,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path className="cls-10" d="m524.41,110.91h-.47v-4.32h.47v4.32Z" />
            <path className="cls-10" d="m525.68,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-10"
              d="m528.82,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
          </g>
        )}
        {(apartmentNumber === 'A03_int' || apartmentNumber === 'A03_bal') && (
          <g id="A03_text">
            <path
              className="cls-10"
              d="m508.93,58.41h-1.17l-.34-1.06h-1.69l-.34,1.06h-1.16l1.73-4.77h1.27l1.69,4.77Zm-1.75-1.88l-.51-1.6c-.04-.12-.06-.26-.08-.43h-.03c-.01.14-.04.28-.08.42l-.52,1.61h1.22Z"
            />
            <path
              className="cls-10"
              d="m510.74,58.49c-1.13,0-1.7-.8-1.7-2.39,0-.83.15-1.46.46-1.89.31-.43.75-.65,1.33-.65,1.11,0,1.66.81,1.66,2.43,0,.81-.15,1.42-.45,1.85s-.74.64-1.3.64Zm.05-4.13c-.45,0-.68.57-.68,1.71,0,1.07.22,1.61.67,1.61s.65-.55.65-1.66-.21-1.66-.64-1.66Z"
            />
            <path
              className="cls-10"
              d="m513.04,58.25v-.91c.32.23.69.35,1.12.35.27,0,.48-.06.63-.17s.22-.28.22-.48-.09-.38-.28-.49c-.19-.11-.44-.17-.76-.17h-.43v-.8h.4c.62,0,.93-.21.93-.62,0-.39-.24-.58-.71-.58-.32,0-.63.1-.93.31v-.86c.33-.17.72-.25,1.17-.25.49,0,.87.11,1.14.33.27.22.41.5.41.85,0,.62-.32,1.01-.95,1.17v.02c.34.04.6.16.8.37.2.2.29.45.29.75,0,.45-.16.8-.49,1.06-.33.26-.78.39-1.35.39-.49,0-.89-.08-1.2-.24Z"
            />
            <path
              className="cls-10"
              d="m506.92,65.41h-2.46v-.42l1.19-1.19c.3-.3.52-.56.65-.76s.2-.42.2-.65-.07-.42-.2-.55c-.13-.13-.33-.19-.58-.19-.36,0-.71.16-1.05.47v-.49c.32-.25.69-.37,1.11-.37.37,0,.65.1.86.3.21.2.31.46.31.79,0,.26-.07.52-.22.77-.14.25-.4.56-.78.93l-.94.93h0s1.9.01,1.9.01v.42Z"
            />
            <path
              className="cls-10"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-10"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-10"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-10"
              d="m524.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m525.37,65.25v-.56c.06.06.14.11.23.15.09.04.18.08.28.12s.2.06.3.07c.1.02.19.03.28.03.29,0,.51-.05.66-.16s.22-.27.22-.47c0-.11-.02-.21-.07-.29-.05-.08-.12-.16-.2-.22-.08-.07-.19-.13-.3-.19-.12-.06-.24-.13-.38-.2-.14-.07-.28-.14-.4-.22-.12-.07-.23-.16-.32-.25-.09-.09-.16-.19-.21-.3-.05-.11-.08-.25-.08-.4,0-.19.04-.35.12-.49.08-.14.19-.25.32-.34.13-.09.28-.16.45-.2.17-.04.34-.07.52-.07.4,0,.7.05.88.15v.54c-.24-.17-.55-.25-.93-.25-.1,0-.21.01-.31.03s-.2.06-.28.11c-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29,0,.1.02.19.06.27.04.08.1.14.17.21.08.06.17.12.28.18.11.06.23.12.38.19.15.07.29.15.42.23s.25.17.34.26.18.2.24.32c.06.12.09.25.09.4,0,.2-.04.37-.12.51-.08.14-.19.25-.32.34-.13.09-.29.15-.46.19s-.36.06-.55.06c-.06,0-.14,0-.24-.02s-.19-.03-.29-.05c-.1-.02-.19-.04-.28-.07-.09-.03-.16-.06-.21-.1Z"
            />
            <path
              className="cls-10"
              d="m504.73,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m508.47,81.64v-.86c.25.16.55.25.9.25s.65-.12.85-.37c.2-.25.3-.6.3-1.06h-.02c-.21.31-.53.46-.96.46-.39,0-.71-.14-.97-.41-.26-.27-.38-.63-.38-1.06,0-.51.16-.91.47-1.22.31-.31.72-.46,1.21-.46s.92.19,1.22.58c.3.38.45.94.45,1.65,0,.85-.18,1.51-.55,1.99-.36.47-.87.71-1.53.71-.39,0-.72-.06-1.01-.19Zm1.38-3.94c-.18,0-.33.07-.45.22-.12.15-.18.34-.18.57s.06.43.17.57.27.21.47.21c.18,0,.33-.07.45-.2s.17-.3.17-.5c0-.25-.06-.46-.18-.62s-.27-.25-.46-.25Z"
            />
            <path
              className="cls-10"
              d="m519.23,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-10"
              d="m521.85,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-10"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-10"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m507.01,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-10"
              d="m508.92,103.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m511.74,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-10" d="m512.77,103.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-10"
              d="m515.66,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m518.48,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m521.64,103.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m504.49,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m507.23,107.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.08,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m512.68,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m513.89,110.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-10"
              d="m516.93,107.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m520.38,110.91h-.47v-1.68c0-.61-.23-.91-.68-.91-.23,0-.42.09-.58.26-.16.18-.23.4-.23.68v1.65h-.47v-4.32h.47v1.89h.01c.22-.37.54-.55.96-.55.66,0,.99.4.99,1.19v1.8Z"
            />
            <path
              className="cls-10"
              d="m523.21,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path className="cls-10" d="m524.41,110.91h-.47v-4.32h.47v4.32Z" />
            <path className="cls-10" d="m525.68,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-10"
              d="m528.82,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
          </g>
        )}
        {(apartmentNumber === 'B50_int' || apartmentNumber === 'B50_bal') && (
          <g id="B50_text">
            <path
              className="cls-10"
              d="m504.73,58.41v-4.77h1.73c.53,0,.94.1,1.23.29.29.19.43.47.43.82,0,.26-.09.48-.26.67-.17.19-.4.33-.67.4h0c.34.06.61.18.81.39.2.21.3.46.3.76,0,.44-.16.78-.47,1.04s-.74.38-1.28.38h-1.83Zm1.07-3.97v1.13h.47c.22,0,.4-.05.52-.16.13-.11.19-.25.19-.44,0-.35-.26-.53-.78-.53h-.4Zm0,1.93v1.26h.58c.25,0,.44-.06.58-.17s.21-.27.21-.47-.07-.34-.21-.45c-.14-.11-.33-.16-.58-.16h-.59Z"
            />
            <path
              className="cls-10"
              d="m508.93,58.29v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m514.06,58.49c-1.13,0-1.7-.8-1.7-2.39,0-.83.15-1.46.46-1.89.31-.43.75-.65,1.33-.65,1.11,0,1.66.81,1.66,2.43,0,.81-.15,1.42-.45,1.85s-.74.64-1.3.64Zm.05-4.13c-.45,0-.68.57-.68,1.71,0,1.07.22,1.61.67,1.61s.65-.55.65-1.66-.21-1.66-.64-1.66Z"
            />
            <path
              className="cls-10"
              d="m506.92,65.41h-2.46v-.42l1.19-1.19c.3-.3.52-.56.65-.76s.2-.42.2-.65-.07-.42-.2-.55c-.13-.13-.33-.19-.58-.19-.36,0-.71.16-1.05.47v-.49c.32-.25.69-.37,1.11-.37.37,0,.65.1.86.3.21.2.31.46.31.79,0,.26-.07.52-.22.77-.14.25-.4.56-.78.93l-.94.93h0s1.9.01,1.9.01v.42Z"
            />
            <path
              className="cls-10"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-10"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-10"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-10"
              d="m524.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m525.37,65.25v-.56c.06.06.14.11.23.15.09.04.18.08.28.12s.2.06.3.07c.1.02.19.03.28.03.29,0,.51-.05.66-.16s.22-.27.22-.47c0-.11-.02-.21-.07-.29-.05-.08-.12-.16-.2-.22-.08-.07-.19-.13-.3-.19-.12-.06-.24-.13-.38-.2-.14-.07-.28-.14-.4-.22-.12-.07-.23-.16-.32-.25-.09-.09-.16-.19-.21-.3-.05-.11-.08-.25-.08-.4,0-.19.04-.35.12-.49.08-.14.19-.25.32-.34.13-.09.28-.16.45-.2.17-.04.34-.07.52-.07.4,0,.7.05.88.15v.54c-.24-.17-.55-.25-.93-.25-.1,0-.21.01-.31.03s-.2.06-.28.11c-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29,0,.1.02.19.06.27.04.08.1.14.17.21.08.06.17.12.28.18.11.06.23.12.38.19.15.07.29.15.42.23s.25.17.34.26.18.2.24.32c.06.12.09.25.09.4,0,.2-.04.37-.12.51-.08.14-.19.25-.32.34-.13.09-.29.15-.46.19s-.36.06-.55.06c-.06,0-.14,0-.24-.02s-.19-.03-.29-.05c-.1-.02-.19-.04-.28-.07-.09-.03-.16-.06-.21-.1Z"
            />
            <path
              className="cls-10"
              d="m504.73,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m509.17,79.27v-.02c-.55-.22-.82-.59-.82-1.09,0-.37.15-.67.44-.91.29-.24.67-.36,1.13-.36s.85.11,1.13.33.42.51.42.86c0,.52-.3.9-.9,1.15h0c.32.11.57.27.75.48.18.22.27.46.27.74,0,.42-.16.75-.47,1-.31.24-.75.37-1.3.37-.48,0-.87-.12-1.17-.36s-.44-.55-.44-.95c0-.57.32-.99.96-1.26Zm.7.4c-.45.17-.67.44-.67.8,0,.17.07.32.2.43.14.11.3.17.5.17.21,0,.38-.05.51-.17.13-.11.19-.26.19-.44,0-.36-.25-.63-.73-.8Zm.62-1.48c0-.16-.05-.29-.16-.39-.1-.1-.24-.14-.41-.14s-.3.05-.41.15c-.11.1-.16.23-.16.39,0,.3.19.52.57.67.38-.16.57-.38.57-.68Z"
            />
            <path
              className="cls-10"
              d="m513.12,80.91l-.49,1.68h-.82l.34-1.68h.97Z"
            />
            <path
              className="cls-10"
              d="m513.9,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m524.65,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-10"
              d="m527.27,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-10"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-10"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m507.01,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-10"
              d="m508.92,103.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m511.74,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-10" d="m512.77,103.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-10"
              d="m515.66,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m518.48,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m521.64,103.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m504.49,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m507.23,107.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.08,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m512.68,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m513.89,110.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-10"
              d="m516.93,107.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m520.38,110.91h-.47v-1.68c0-.61-.23-.91-.68-.91-.23,0-.42.09-.58.26-.16.18-.23.4-.23.68v1.65h-.47v-4.32h.47v1.89h.01c.22-.37.54-.55.96-.55.66,0,.99.4.99,1.19v1.8Z"
            />
            <path
              className="cls-10"
              d="m523.21,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path className="cls-10" d="m524.41,110.91h-.47v-4.32h.47v4.32Z" />
            <path className="cls-10" d="m525.68,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-10"
              d="m528.82,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
          </g>
        )}
        {(apartmentNumber === 'B51_int' || apartmentNumber === 'B51_bal') && (
          <g id="B51_text">
            <path
              className="cls-10"
              d="m504.73,58.41v-4.77h1.73c.53,0,.94.1,1.23.29.29.19.43.47.43.82,0,.26-.09.48-.26.67-.17.19-.4.33-.67.4h0c.34.06.61.18.81.39.2.21.3.46.3.76,0,.44-.16.78-.47,1.04s-.74.38-1.28.38h-1.83Zm1.07-3.97v1.13h.47c.22,0,.4-.05.52-.16.13-.11.19-.25.19-.44,0-.35-.26-.53-.78-.53h-.4Zm0,1.93v1.26h.58c.25,0,.44-.06.58-.17s.21-.27.21-.47-.07-.34-.21-.45c-.14-.11-.33-.16-.58-.16h-.59Z"
            />
            <path
              className="cls-10"
              d="m508.93,58.29v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m515.71,58.41h-3.01v-.83h.99v-2.97l-1.01.22v-.85l2.06-.42v4.01h.98v.83Z"
            />
            <path
              className="cls-10"
              d="m506.92,65.41h-2.46v-.42l1.19-1.19c.3-.3.52-.56.65-.76s.2-.42.2-.65-.07-.42-.2-.55c-.13-.13-.33-.19-.58-.19-.36,0-.71.16-1.05.47v-.49c.32-.25.69-.37,1.11-.37.37,0,.65.1.86.3.21.2.31.46.31.79,0,.26-.07.52-.22.77-.14.25-.4.56-.78.93l-.94.93h0s1.9.01,1.9.01v.42Z"
            />
            <path
              className="cls-10"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-10"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-10"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-10"
              d="m504.73,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m511.28,77.03v.88c-.24-.14-.5-.21-.79-.21-.35,0-.64.13-.85.4-.22.27-.33.63-.34,1.08h.02c.23-.34.56-.51,1-.51.4,0,.71.14.95.41.23.27.35.63.35,1.07,0,.48-.15.88-.46,1.19-.31.32-.7.48-1.18.48-.54,0-.95-.19-1.26-.58-.3-.39-.45-.93-.45-1.63,0-.82.19-1.48.58-1.98.39-.49.91-.74,1.58-.74.37,0,.65.04.86.13Zm-1.32,2.43c-.19,0-.35.07-.46.2-.11.14-.17.31-.17.52,0,.23.06.43.17.6s.27.25.46.25.34-.07.45-.22c.11-.15.17-.34.17-.58,0-.51-.21-.77-.63-.77Z"
            />
            <path
              className="cls-10"
              d="m519.23,81.75h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-10"
              d="m521.85,79.81h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-10"
              d="m504.9,93.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.35,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m511.1,96.91h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m513.8,96.91h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m517.01,96.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m519.84,96.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-10"
              d="m521.92,96.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m507.01,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-10"
              d="m508.92,103.89c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m511.74,102.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path className="cls-10" d="m512.77,103.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-10"
              d="m515.66,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m518.48,103.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m521.64,103.91h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m504.49,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m507.23,107.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.08,110.81v-.5c.25.19.54.28.84.28.41,0,.62-.14.62-.41,0-.08-.02-.14-.05-.2-.04-.05-.08-.1-.14-.14-.06-.04-.13-.08-.21-.11-.08-.03-.17-.07-.26-.1-.13-.05-.24-.1-.34-.16-.1-.05-.18-.11-.25-.18s-.12-.14-.15-.22-.05-.18-.05-.29c0-.14.03-.26.09-.36.06-.11.15-.19.25-.27s.22-.12.36-.16c.13-.04.27-.05.42-.05.25,0,.48.04.68.13v.47c-.21-.14-.46-.21-.74-.21-.09,0-.17,0-.24.03-.07.02-.13.05-.18.08-.05.04-.09.08-.12.13-.03.05-.04.11-.04.17,0,.08.01.14.04.19.03.05.07.1.12.14.05.04.12.08.19.11s.16.07.26.11c.13.05.25.1.35.15.1.05.19.11.26.18.07.07.13.14.17.23.04.08.06.19.06.3,0,.14-.03.27-.1.38s-.15.19-.25.26-.23.12-.37.16c-.14.03-.28.05-.44.05-.3,0-.56-.06-.78-.17Z"
            />
            <path
              className="cls-10"
              d="m512.68,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m513.89,110.49h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-10"
              d="m516.93,107.26c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m520.38,110.91h-.47v-1.68c0-.61-.23-.91-.68-.91-.23,0-.42.09-.58.26-.16.18-.23.4-.23.68v1.65h-.47v-4.32h.47v1.89h.01c.22-.37.54-.55.96-.55.66,0,.99.4.99,1.19v1.8Z"
            />
            <path
              className="cls-10"
              d="m523.21,110.91h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path className="cls-10" d="m524.41,110.91h-.47v-4.32h.47v4.32Z" />
            <path className="cls-10" d="m525.68,110.91h-.47v-4.32h.47v4.32Z" />
            <path
              className="cls-10"
              d="m528.82,109.57h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
          </g>
        )}
        {(apartmentNumber === 'B52_int' || apartmentNumber === 'B52_bal') && (
          <g id="B52_text">
            <path
              className="cls-10"
              d="m504.73,58.41v-4.77h1.73c.53,0,.94.1,1.23.29.29.19.43.47.43.82,0,.26-.09.48-.26.67-.17.19-.4.33-.67.4h0c.34.06.61.18.81.39.2.21.3.46.3.76,0,.44-.16.78-.47,1.04s-.74.38-1.28.38h-1.83Zm1.07-3.97v1.13h.47c.22,0,.4-.05.52-.16.13-.11.19-.25.19-.44,0-.35-.26-.53-.78-.53h-.4Zm0,1.93v1.26h.58c.25,0,.44-.06.58-.17s.21-.27.21-.47-.07-.34-.21-.45c-.14-.11-.33-.16-.58-.16h-.59Z"
            />
            <path
              className="cls-10"
              d="m508.93,58.29v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m513.69,57.52v.02h1.93v.87h-3.14v-.82l1.37-1.31c.27-.26.47-.48.58-.66.11-.18.17-.37.17-.56,0-.44-.24-.66-.71-.66-.41,0-.8.16-1.18.49v-.92c.41-.27.88-.4,1.4-.4.49,0,.87.12,1.14.37.27.25.41.58.41.99,0,.56-.33,1.13-1,1.72l-.98.87Z"
            />
            <path
              className="cls-10"
              d="m504.66,65.31v-.5c.28.18.56.27.85.27s.52-.08.7-.24c.18-.16.26-.38.26-.65s-.09-.48-.27-.63c-.18-.15-.44-.23-.77-.23-.11,0-.33.01-.64.03v-2.05h1.98v.42h-1.54v1.2c.12,0,.24-.01.35-.01.44,0,.77.11,1.01.33.24.22.36.52.36.9s-.13.71-.39.96c-.26.24-.61.36-1.06.36-.38,0-.66-.06-.85-.17Z"
            />
            <path
              className="cls-10"
              d="m510.8,65.41h-.48v-1.86h-2.11v1.86h-.48v-4.08h.48v1.79h2.11v-1.79h.48v4.08Z"
            />
            <path
              className="cls-10"
              d="m514.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m518.41,65.41h-.67l-1.58-1.87c-.06-.07-.09-.12-.11-.14h-.01v2.01h-.48v-4.08h.48v1.92h.01s.06-.09.11-.14l1.53-1.78h.6l-1.75,1.96,1.87,2.12Z"
            />
            <path
              className="cls-10"
              d="m521.22,61.76h-1.18v3.65h-.48v-3.65h-1.18v-.43h2.83v.43Z"
            />
            <path
              className="cls-10"
              d="m524.52,63.9h-1.15v1.15h-.38v-1.15h-1.15v-.38h1.15v-1.15h.38v1.15h1.15v.38Z"
            />
            <path
              className="cls-10"
              d="m525.37,65.25v-.56c.06.06.14.11.23.15.09.04.18.08.28.12s.2.06.3.07c.1.02.19.03.28.03.29,0,.51-.05.66-.16s.22-.27.22-.47c0-.11-.02-.21-.07-.29-.05-.08-.12-.16-.2-.22-.08-.07-.19-.13-.3-.19-.12-.06-.24-.13-.38-.2-.14-.07-.28-.14-.4-.22-.12-.07-.23-.16-.32-.25-.09-.09-.16-.19-.21-.3-.05-.11-.08-.25-.08-.4,0-.19.04-.35.12-.49.08-.14.19-.25.32-.34.13-.09.28-.16.45-.2.17-.04.34-.07.52-.07.4,0,.7.05.88.15v.54c-.24-.17-.55-.25-.93-.25-.1,0-.21.01-.31.03s-.2.06-.28.11c-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29,0,.1.02.19.06.27.04.08.1.14.17.21.08.06.17.12.28.18.11.06.23.12.38.19.15.07.29.15.42.23s.25.17.34.26.18.2.24.32c.06.12.09.25.09.4,0,.2-.04.37-.12.51-.08.14-.19.25-.32.34-.13.09-.29.15-.46.19s-.36.06-.55.06c-.06,0-.14,0-.24-.02s-.19-.03-.29-.05c-.1-.02-.19-.04-.28-.07-.09-.03-.16-.06-.21-.1Z"
            />
            <path
              className="cls-10"
              d="m504.73,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m508.47,81.64v-.86c.25.16.55.25.9.25s.65-.12.85-.37c.2-.25.3-.6.3-1.06h-.02c-.21.31-.53.46-.96.46-.39,0-.71-.14-.97-.41-.26-.27-.38-.63-.38-1.06,0-.51.16-.91.47-1.22.31-.31.72-.46,1.21-.46s.92.19,1.22.58c.3.38.45.94.45,1.65,0,.85-.18,1.51-.55,1.99-.36.47-.87.71-1.53.71-.39,0-.72-.06-1.01-.19Zm1.38-3.94c-.18,0-.33.07-.45.22-.12.15-.18.34-.18.57s.06.43.17.57.27.21.47.21c.18,0,.33-.07.45-.2s.17-.3.17-.5c0-.25-.06-.46-.18-.62s-.27-.25-.46-.25Z"
            />
            <path
              className="cls-10"
              d="m513.12,80.91l-.49,1.68h-.82l.34-1.68h.97Z"
            />
            <path
              className="cls-10"
              d="m513.9,81.62v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m521.13,80.13h-1.28v1.28h-.67v-1.28h-1.29v-.67h1.29v-1.29h.67v1.29h1.28v.67Z"
            />
            <path
              className="cls-10"
              d="m507.83,85.63l-1.68,4.28h-1.11l1.7-3.91h-2.22v-.86h3.32v.49Z"
            />
            <path
              className="cls-10"
              d="m509.5,89.02v.02h1.93v.87h-3.14v-.82l1.37-1.31c.27-.26.47-.48.58-.66.11-.18.17-.37.17-.56,0-.44-.24-.66-.71-.66-.41,0-.8.16-1.18.49v-.92c.41-.27.88-.4,1.4-.4.49,0,.87.12,1.14.37.27.25.41.58.41.99,0,.56-.33,1.13-1,1.72l-.98.87Z"
            />
            <path
              className="cls-10"
              d="m513.12,89.07l-.49,1.68h-.82l.34-1.68h.97Z"
            />
            <path
              className="cls-10"
              d="m513.9,89.79v-.9c.33.2.67.3,1.04.3.28,0,.49-.07.65-.2.16-.13.23-.31.23-.54,0-.47-.33-.71-1-.71-.22,0-.48.02-.79.06v-2.66h2.64v.86h-1.74v.95c.14-.01.27-.02.4-.02.49,0,.87.13,1.15.39.28.26.42.6.42,1.04,0,.48-.17.87-.5,1.18s-.78.46-1.34.46c-.46,0-.84-.07-1.16-.21Z"
            />
            <path
              className="cls-10"
              d="m522.94,89.91h-1.05v-1.94c0-.49-.18-.74-.54-.74-.17,0-.31.07-.42.22s-.16.33-.16.55v1.9h-1.05v-1.96c0-.48-.18-.72-.54-.72-.18,0-.32.07-.43.21-.11.14-.16.33-.16.58v1.89h-1.05v-3.4h1.05v.53h.01c.11-.18.26-.33.46-.44.2-.11.41-.17.64-.17.48,0,.81.21.99.63.26-.42.64-.63,1.14-.63.74,0,1.11.46,1.11,1.37v2.12Z"
            />
            <path
              className="cls-10"
              d="m525.56,87.97h-2.01v-.52l.82-.78c.17-.16.28-.28.32-.37s.06-.17.06-.27c0-.09-.03-.17-.09-.23-.06-.06-.15-.09-.25-.09-.22,0-.45.11-.7.34v-.71c.26-.17.54-.26.83-.26s.54.08.72.23c.18.15.26.36.26.63,0,.16-.04.31-.12.47s-.28.38-.6.65l-.35.31h0s1.11.01,1.11.01v.59Z"
            />
            <path
              className="cls-10"
              d="m504.9,101.42c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m508.35,105.08h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m511.1,105.08h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m513.8,105.08h-.47v-.46h-.01c-.19.35-.49.53-.9.53-.7,0-1.04-.41-1.04-1.24v-1.74h.46v1.67c0,.62.24.92.71.92.23,0,.42-.08.56-.25s.22-.39.22-.66v-1.68h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m517.01,105.08h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
            <path
              className="cls-10"
              d="m519.84,105.08h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-10"
              d="m521.92,105.05c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m524.32,104.96c0,.48-.1.85-.29,1.11-.19.26-.47.39-.83.39-.13,0-.26-.03-.39-.09v-.44c.14.09.27.13.4.13.42,0,.64-.34.64-1.02v-2.87h.47v2.79Zm-.23-3.54c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Z"
            />
            <path
              className="cls-10"
              d="m527.21,105.08h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-10"
              d="m505.14,111.66h-.01v1.76h-.47v-4.26h.47v.51h.01c.23-.39.57-.58,1.01-.58.38,0,.67.13.88.39.21.26.32.61.32,1.05,0,.49-.12.88-.36,1.17-.24.29-.56.44-.97.44-.38,0-.67-.16-.88-.49Zm-.01-1.18v.41c0,.24.08.45.23.61.16.17.36.25.6.25.28,0,.5-.11.67-.32.16-.22.24-.52.24-.9,0-.32-.08-.58-.23-.76-.15-.18-.35-.28-.61-.28-.27,0-.49.09-.66.28-.17.19-.25.43-.25.71Z"
            />
            <path
              className="cls-10"
              d="m510.03,112.08h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-.47-1.48l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Z"
            />
            <path
              className="cls-10"
              d="m512.29,109.64c-.08-.06-.2-.09-.35-.09-.2,0-.37.09-.5.28-.13.19-.2.44-.2.77v1.49h-.47v-2.92h.47v.6h.01c.07-.21.17-.37.3-.48s.29-.17.46-.17c.12,0,.21.01.28.04v.48Z"
            />
            <path
              className="cls-10"
              d="m514.94,109.16l-1.16,2.92h-.46l-1.11-2.92h.51l.74,2.12c.06.16.09.29.1.41h.01c.02-.15.05-.28.09-.4l.78-2.13h.49Z"
            />
            <path
              className="cls-10"
              d="m517.64,110.74h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-10"
              d="m520.63,112.08h-.66l-1.29-1.4h-.01v1.4h-.47v-4.32h.47v2.74h.01l1.23-1.34h.61l-1.35,1.41,1.46,1.51Z"
            />
            <path
              className="cls-10"
              d="m523.3,110.74h-2.06c0,.33.09.58.26.75s.4.26.69.26c.33,0,.63-.11.91-.32v.44c-.26.19-.6.28-1.02.28s-.74-.13-.97-.4-.35-.64-.35-1.12c0-.45.13-.82.39-1.11.26-.29.58-.43.96-.43s.68.12.89.37c.21.25.31.59.31,1.03v.25Zm-.48-.4c0-.27-.07-.48-.2-.63s-.31-.22-.53-.22-.41.08-.56.24c-.15.16-.25.36-.28.62h1.58Z"
            />
            <path
              className="cls-10"
              d="m525.55,108.42c-.08,0-.15-.03-.21-.09-.06-.06-.09-.13-.09-.22s.03-.16.09-.22c.06-.06.13-.09.21-.09s.16.03.22.09c.06.06.09.13.09.22,0,.08-.03.15-.09.21-.06.06-.13.09-.22.09Zm.23,3.66h-.47v-2.92h.47v2.92Z"
            />
            <path
              className="cls-10"
              d="m527.93,112.05c-.11.06-.26.09-.44.09-.51,0-.77-.29-.77-.85v-1.73h-.5v-.4h.5v-.71l.47-.15v.86h.74v.4h-.74v1.64c0,.2.03.33.1.42.07.08.18.13.33.13.12,0,.22-.03.3-.1v.4Z"
            />
            <path
              className="cls-10"
              d="m530.5,112.08h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m533.32,112.08h-.47v-.46h-.01c-.2.35-.5.52-.9.52-.29,0-.52-.08-.68-.23-.16-.15-.25-.36-.25-.61,0-.54.32-.86.96-.95l.88-.12c0-.5-.2-.74-.6-.74-.35,0-.67.12-.95.36v-.48c.29-.18.62-.27.99-.27.69,0,1.03.36,1.03,1.09v1.9Zm-1.64-3.47c-.08,0-.15-.03-.2-.08-.06-.06-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.2-.08s.15.03.2.08c.06.05.09.12.09.2s-.03.14-.08.2-.12.08-.2.08Zm1.17,1.99l-.7.1c-.22.03-.38.08-.49.16-.11.08-.17.21-.17.41,0,.14.05.26.15.35s.24.13.41.13c.23,0,.42-.08.57-.24s.23-.37.23-.62v-.29Zm-.08-1.99c-.08,0-.14-.03-.2-.08-.06-.05-.08-.12-.08-.2s.03-.15.08-.2c.05-.05.12-.08.19-.08.08,0,.15.03.2.08.06.05.08.12.08.2s-.03.14-.08.2c-.05.05-.12.08-.2.08Z"
            />
            <path
              className="cls-10"
              d="m536.48,112.08h-.47v-1.66c0-.62-.23-.93-.68-.93-.23,0-.43.09-.58.26-.15.18-.23.4-.23.67v1.66h-.47v-2.92h.47v.48h.01c.22-.37.54-.55.96-.55.32,0,.56.1.73.31s.25.5.25.89v1.78Z"
            />
          </g>
        )}
      </g>
    </SVG>
  )
}

const pivots = {
  A01_int: [74.4, 130.8],
  A01_bal: [113.68, 153.99],
  A02_int: [180.1, 89.3],
  A03_bal: [219.59, 117.88],
  A03_int: [240.42, 81.99],
  A02_bal: [189.33, 117.88],
  B50_int: [269.3, 82],
  B50_bal: [289.47, 117.88],
  B51_int: [398.9, 162.1],
  B51_bal: [424.2965, 174.9768],
  B52_int: [354.16, 173.5],
  B52_bal: [322.46, 152.54],
}

const SVG = styled.svg`
  .cls-1 {
    fill: url(#linear-gradient-2);
  }
  .cls-2 {
    fill: #fff;
  }
  .cls-3 {
    fill: url(#linear-gradient-6);
  }
  .cls-4 {
    fill: #b4b4b4;
  }
  .cls-5 {
    fill: url(#linear-gradient-11);
  }
  .cls-6 {
    fill: url(#linear-gradient-10);
  }
  .cls-7 {
    fill: #f0f0e1;
    stroke: #141414;
    stroke-width: 0.1px;
  }
  .cls-7,
  .cls-8 {
    stroke-miterlimit: 10;
  }
  .cls-9 {
    fill: url(#linear-gradient-5);
  }
  .cls-10 {
    fill: #141414;
  }
  .cls-11 {
    fill: url(#linear-gradient-7);
  }
  .cls-12 {
    fill: #505050;
    opacity: 0.99;
  }
  .cls-13 {
    fill: url(#linear-gradient-9);
  }
  .cls-14 {
    fill: url(#linear-gradient-3);
  }
  .cls-15 {
    fill: #1a1a1a;
  }
  .cls-16 {
    fill: url(#linear-gradient);
  }
  .cls-8 {
    fill: #cdcdb4;
    stroke: #141414;
    stroke-width: 0.5px;
  }
  .cls-17 {
    fill: url(#linear-gradient-4);
  }
  .cls-18 {
    fill: url(#linear-gradient-8);
  }
  #up,
  #down {
    rect {
      transition: all 0.2s ease;
    }
  }

  #up:hover {
    cursor: pointer;
    padding: 20px;

    rect.cls-2 {
      fill: var(--secondary-color);
    }
  }

  #down {
    cursor: not-allowed;

    rect.cls-2 {
      fill: var(--grey);
    }
  }

  .disable-lift-btn {
    cursor: not-allowed;

    .st17 {
      fill: var(--grey);
    }

    .cls-6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text .cls-2 {
    fill: var(--light);
    cursor: pointer;
  }

  .green-text .cls-2 {
    fill: var(--green-color);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }
`
