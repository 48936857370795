import styled from 'styled-components/macro'

import CollapseIcon from 'icons/collapse-arrow'

export default function Collapsible({ children, collapse, setCollapse }) {
  return (
    <CollapsibleWrapper>
      <input
        id="collapsible"
        className="toggle"
        type="checkbox"
        checked={collapse}
        onChange={() => setCollapse(!collapse)}
      />
      <Label htmlFor="collapsible">
        <div
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            padding: '0 10px',
            borderRadius: '5px 5px 0 0',
            transform: 'translateX(-50%)',
            backgroundColor: 'white',
          }}
        >
          <CollapseIcon collapse={collapse} />
        </div>
      </Label>
      <CollapsibleContent collapse={collapse}>{children}</CollapsibleContent>
    </CollapsibleWrapper>
  )
}

const CollapsibleWrapper = styled.div`
  margin: 0 auto;

  input[type='checkbox'] {
    display: none;
  }
`

const Label = styled.label`
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
`

const CollapsibleContent = styled.div`
  max-height: ${p => (p.collapse ? 'auto' : '100%')};
  overflow: hidden;
  transition: max-height 0.2s ease;
  text-align: center;
`
