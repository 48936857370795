import * as React from 'react'

import Krpano from 'components/Krpano'
// import Logo from 'components/Logo'
import useCollection from 'hooks/useCollection'

const getProject = documents => {
  const project =
    Object.values(documents).find(
      doc => doc.id === 'hartela-verkkosaari-valo',
    ) || {}
  const floors = Object.values(project?.floors) || []
  const apartments = Object.values(project?.apartments) || []

  return { floors, apartments }
}

function App() {
  const { documents, error } = useCollection('projects')

  return documents ? (
    <div className="App">
      {/* <Logo /> */}
      {error ? <div>{error}</div> : null}
      <Krpano
        apartments={getProject(documents).apartments}
        floors={getProject(documents).floors}
      />
    </div>
  ) : (
    <div />
  )
}

export default App
