export const FLOOR_6_DATA = [
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A22_bal',
    sceneName: 'scene_valo_krs06_A22_bal',
    sceneName_future: 'scene_valo_krs06_A22_bal_future',
    name: 'A22_bal',
    label: 'A22',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_bal',
      4: 'A11_bal',
      5: 'A15_bal',
      7: 'A26_bal',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A22_int',
    sceneName: 'scene_valo_krs06_A22_int',
    sceneName_future: 'scene_valo_krs06_A22_int_future',
    name: 'A22_int',
    label: 'A22',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A04_int',
      4: 'A11_int',
      5: 'A15_int',
      7: 'A26_int',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A23_bal',
    sceneName: 'scene_valo_krs06_A23_bal',
    sceneName_future: 'scene_valo_krs06_A23_bal_future',
    name: 'A23_bal',
    label: 'A23',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_bal',
      4: 'A12_bal',
      5: 'A16_bal',
      7: 'A27_bal',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A23_int',
    sceneName: 'scene_valo_krs06_A23_int',
    sceneName_future: 'scene_valo_krs06_A23_int_future',
    name: 'A23_int',
    label: 'A23',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A05_int',
      4: 'A12_int',
      5: 'A16_int',
      7: 'A27_int',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A24_bal',
    sceneName: 'scene_valo_krs06_A24_bal',
    sceneName_future: 'scene_valo_krs06_A24_bal_future',
    name: 'A24_bal',
    label: 'A24',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_bal',
      4: 'A13_bal',
      5: 'A17_bal',
      7: 'A28_bal',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A24_int',
    sceneName: 'scene_valo_krs06_A24_int',
    sceneName_future: 'scene_valo_krs06_A24_int_future',
    name: 'A24_int',
    label: 'A24',
    lookAt: '-96, 3, 120',
    face: 'west',

    relatedApartments: {
      2: 'A01_int',
      3: 'A06_int',
      4: 'A13_int',
      5: 'A17_int',
      7: 'A28_int',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A25_bal',
    sceneName: 'scene_valo_krs06_A25_bal',
    sceneName_future: 'scene_valo_krs06_A25_bal_future',
    name: 'A25_bal',
    label: 'A25',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_bal',
      4: 'A14_bal',
      5: 'A18_bal',
      7: 'A29_bal',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A25_int',
    sceneName: 'scene_valo_krs06_A25_int',
    sceneName_future: 'scene_valo_krs06_A25_int_future',
    name: 'A25_int',
    label: 'A25',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A07_int',
      4: 'A14_int',
      5: 'A18_int',
      7: 'A29_int',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A19_bal',
    sceneName: 'scene_valo_krs05_A19_bal',
    sceneName_future: 'scene_valo_krs05_A19_bal_future',
    name: 'A19_bal',
    label: 'A19',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_bal',
      4: 'A08_bal',
      5: 'A19_bal',
      7: 'A29_bal',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A19_int',
    sceneName: 'scene_valo_krs06_A19_int',
    sceneName_future: 'scene_valo_krs06_A19_int_future',
    name: 'A19_int',
    label: 'A19',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A02_int',
      3: 'A08_int',
      4: 'A08_int',
      5: 'A19_int',
      7: 'A29_int',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A20_bal',
    sceneName: 'scene_valo_krs05_A20_bal',
    sceneName_future: 'scene_valo_krs05_A20_bal_future',
    name: 'A20_bal',
    label: 'A20',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A09_bal',
      4: 'A09_bal',
      5: 'A20_bal',
      7: 'A30_bal',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A20_int',
    sceneName: 'scene_valo_krs06_A20_int',
    sceneName_future: 'scene_valo_krs06_A20_int_future',
    name: 'A20_int',
    label: 'A20',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A09_int',
      4: 'A09_int',
      5: 'A20_int',
      7: 'A30_int',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A21_bal',
    sceneName: 'scene_valo_krs05_A21_bal',
    sceneName_future: 'scene_valo_krs05_A21_bal_future',
    name: 'A21_bal',
    label: 'A21',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A10_bal',
      4: 'A10_bal',
      5: 'A21_bal',
      7: 'A30_bal',
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 'A21_int',
    sceneName: 'scene_valo_krs06_A21_int',
    sceneName_future: 'scene_valo_krs06_A21_int_future',
    name: 'A21_int',
    label: 'A21',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'A03_int',
      3: 'A10_int',
      4: 'A10_int',
      5: 'A21_int',
      7: 'A30_int',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B62_bal',
    sceneName: 'scene_valo_krs05_B62_bal',
    sceneName_future: 'scene_valo_krs05_B62_bal_future',
    name: 'B62_bal',
    label: 'B62',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_bal',
      4: 'B53_bal',
      5: 'B62_bal',
      7: 'B72_bal',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B62_int',
    sceneName: 'scene_valo_krs06_B62_int',
    sceneName_future: 'scene_valo_krs06_B62_int_future',
    name: 'B62_int',
    label: 'B62',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B53_int',
      4: 'B53_int',
      5: 'B62_int',
      7: 'B72_int',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B63_bal',
    sceneName: 'scene_valo_krs05_B63_bal',
    sceneName_future: 'scene_valo_krs05_B63_bal_future',
    name: 'B63_bal',
    label: 'B63',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B54_bal',
      4: 'B54_bal',
      5: 'B63_bal',
      7: 'B72_bal',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B63_int',
    sceneName: 'scene_valo_krs06_B63_int',
    sceneName_future: 'scene_valo_krs06_B63_int_future',
    name: 'B63_int',
    label: 'B63',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B54_int',
      4: 'B54_int',
      5: 'B63_int',
      7: 'B72_int',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B68_bal',
    sceneName: 'scene_valo_krs06_B68_bal',
    sceneName_future: 'scene_valo_krs06_B68_bal_future',
    name: 'B68_bal',
    label: 'B68',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B55_bal',
      4: 'B58_bal',
      5: 'B64_bal',
      7: 'B73_bal',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B68_int',
    sceneName: 'scene_valo_krs06_B68_int',
    sceneName_future: 'scene_valo_krs06_B68_int_future',
    name: 'B68_int',
    label: 'B68',
    lookAt: '-32, 3, 120',
    face: 'north',

    relatedApartments: {
      2: 'B50_int',
      3: 'B55_int',
      4: 'B58_int',
      5: 'B64_int',
      7: 'B73_int',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B69_bal',
    sceneName: 'scene_valo_krs06_B69_bal',
    sceneName_future: 'scene_valo_krs06_B69_bal_future',
    name: 'B69_bal',
    label: 'B69',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      3: 'B56_bal',
      4: 'B59_bal',
      5: 'B65_bal',
      7: 'B74_bal',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B69_int',
    sceneName: 'scene_valo_krs06_B69_int',
    sceneName_future: 'scene_valo_krs06_B69_int_future',
    name: 'B69_int',
    label: 'B69',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B50_int',
      3: 'B56_int',
      4: 'B59_int',
      5: 'B65_int',
      7: 'B74_int',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B70_bal',
    sceneName: 'scene_valo_krs06_B70_bal',
    sceneName_future: 'scene_valo_krs06_B70_bal_future',
    name: 'B70_bal',
    label: 'B70',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_int',
      3: 'B57_bal',
      4: 'B60_bal',
      5: 'B66_bal',
      7: 'B75_bal',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B70_int',
    sceneName: 'scene_valo_krs06_B70_int',
    sceneName_future: 'scene_valo_krs06_B70_int_future',
    name: 'B70_int',
    label: 'B70',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B51_int',
      3: 'B57_int',
      4: 'B60_int',
      5: 'B66_int',
      7: 'B75_int',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B71_bal',
    sceneName: 'scene_valo_krs06_B71_bal',
    sceneName_future: 'scene_valo_krs06_B71_bal_future',
    name: 'B71_bal',
    label: 'B71',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B52_int',
      3: 'B52_bal',
      4: 'B61_bal',
      5: 'B67_bal',
      7: 'B76_bal',
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 'B71_int',
    sceneName: 'scene_valo_krs06_B71_int',
    sceneName_future: 'scene_valo_krs06_B71_int_future',
    name: 'B71_int',
    label: 'B71',
    lookAt: '68, 3, 120',
    face: 'east',

    relatedApartments: {
      2: 'B52_int',
      3: 'B57_int',
      4: 'B61_int',
      5: 'B67_int',
      7: 'B76_int',
    },
  },
]
